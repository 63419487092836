import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Form,
  Button,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import CustomInput from "../../Unit/Input";
import { useRef } from "react";
import Select from "react-select";
import AuthUser from "../../../helpers/Authuser";
import ShiftAdd from "../../Shift Slots/ShiftAdd";
import CityAdd from "../../City/CityAdd";
import RoleCreateModal from "../Roles/RoleCreateModal";
import Flatpickr from "react-flatpickr";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
const UserCreateModal = (props) => {
  const [modal, setModal] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const { https, http, user } = AuthUser();
  console.log(user);

  const [modalStatess1, setModalStatess1] = useState(false);
  const [modalStatess, setModalStatess] = useState(false);
  const [counts, Setcounts] = useState(1);
  const [ActiveShift, setActiveShift] = useState(false);
  const [activeRole, setActiveRole] = useState(false);
  const [EmployeeShift, setEmployeeShift] = useState();
  const [EmployeeRole, setEmployeeRole] = useState([]);
  const [ManageShift, setManageShift] = useState(0);
  const [ManageRole, setManageRole] = useState(0);

  const handleCallback = (data) => {
    Setcounts(counts + 1);
    setManageRole(1);
    setManageShift(1);
    toast.success(data);
    setModalStatess(false);
    setModalStatess1(false);
  };
  const [EmployeeAdhar, SetEmployeeAdhar] = useState("");
  const [EmployeeProfile, SetEmployeeProfile] = useState("");
  const [EmployeePan, SetEmployeePan] = useState("");
  const [EmployeeQrCode, SetEmployeeQrCode] = useState("");
  const [EmployeeDoc, SetEmployeeDoc] = useState("");
  const [EmployeeAudio, SetEmployeeAudio] = useState("");
  const [EmployeeVideo, SetEmployeeVideo] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [employeeData, setemployeeData] = useState({

    u_type: user && user.user.u_type === 3 ? 4 : 2,
    user_user_id: user && user.user.u_type === 4 || user.user.u_type === 2 ? user.user.user_user_id : user.user.uid,
    name: "",
    address: "",
    user_role_id: user && user.user.user_behave === 1 ? user.user.user_role_id : '',
    temp_role_id: user && user.user.user_behave === 1 ? user.user.user_role_id : '',
    date_of_joining: "",
    last_experience: "",
    last_working_company: "",
    last_company_salary: "",
    shift_id: 1,
    salary: "",
    mobile_no: "",
    emergency_contact: "",
    email: "",
    password: "",
    aadhar_no: "",
    pan_no: "",
    user_upi: "",
    pan_photo: "",
    bank_passbook_photo: "",
    aadhar_photo: "",
    profile_photo: "",
    document: "",
    audio: "",
    video: "",
    user_behave: 1,
    leaves: [],

  });

  useEffect(() => {
    http
      .get("/role/list")
      .then(function (response) {
        console.log(response.data);

        // Check if response.data is an array and has elements
        if (Array.isArray(response.data) && response.data.length === 0) {
          setActiveRole(false);
        } else {
          const filteredData = user && user.user.u_type === 1 || user.user.u_type === 2
            ? response.data.filter(
              (role) => parseInt(role.role_created_user_id) === user.user.user_user_id || parseInt(role.role_created_user_id) === user.user.uid
            ) :

            response.data.filter(
              (role) => parseInt(role.role_created_user_id) === user.user.uid
            );
          // Set filtered roles
          setEmployeeRole(filteredData);

          // Update active role status
          setActiveRole(filteredData.length > 0);

          // Update employee data based on ManageRole
          setemployeeData(prevState => ({
            ...prevState,
            user_role_id: filteredData.length > 0
              ? (ManageRole === 0
                ? filteredData[0].role_id
                : filteredData[filteredData.length - 1].role_id)
              : null // Handle the case when filteredData is empty
          }));
        }
      })
      .catch(function (error) {
        console.error("Error fetching role list:", error);
      });




    http
      .get("/shift/list")
      .then(function (response) {
        if (response.data.length == 0) {
          setActiveShift(false);
        } else {
          setEmployeeShift(response.data);
          setActiveShift(true);
          setemployeeData(() => ({
            ...employeeData,
            shift_id:
              ManageShift == 0
                ? response.data[0].shift_id
                : response.data[response.data.length - 1].shift_id,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [counts + 1]);

  const getSelectedCityValue = (e) => {
    setemployeeData({ ...employeeData, shift_id: e.value });
  };
  const getSelectedRoleValue = (e) => {
    setemployeeData({ ...employeeData, user_role_id: e.value });
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStatess1]);
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStatess]);

  // console.log(employeeData);
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  // GETTING ROLES LIST
  const getRolesList = async () => {
    try {
      const apiResponse = await https.get("/role/list");
      setRolesList(apiResponse.data);
    } catch (error) {
      console.log(error);
    }
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});
  const [checkEmailStatus, setEmailCheckStatus] = useState({});
  const [checkMobileStatus, setMobileCheckStatus] = useState({});
  const [checkPasswordStatus, setPasswordCheckStatus] = useState({});
  const [checkSalaryStatus, setSalaryCheckStatus] = useState({});
  const [leaveType, setLeaveType] = useState([]);
  const [selectedleaveType, setSelectedleaveType] = useState([]);
  const [showInputForLeaveType, setShowInputForLeaveType] = useState(null);
  const [daysInput, setDaysInput] = useState({}); // State to track which leave type has the input box open

  const handleLeaveTypeChange = (e, leavetype_id) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Show the input box for this leave type
      setSelectedleaveType([...selectedleaveType, leavetype_id]);
      setShowInputForLeaveType(leavetype_id);
    } else {
      // Remove the leave type from the selected list and hide the input box
      setSelectedleaveType(selectedleaveType.filter((id) => id !== leavetype_id));
      setShowInputForLeaveType(null);

      // Remove from leaves array
      setemployeeData((prevData) => ({
        ...prevData,
        leaves: prevData.leaves.filter((leave) => leave.leavetype_id !== leavetype_id),
      }));
    }
  };

  // Handle days input change
  const handleDaysInputChange = (e, leavetype_id) => {
    const { value } = e.target;
    setDaysInput({ ...daysInput, [leavetype_id]: value });

    // Update the leaves array in employeeData
    setemployeeData((prevData) => ({
      ...prevData,
      leaves: [
        ...prevData.leaves.filter((leave) => leave.leavetype_id !== leavetype_id),
        { leavetype_id, days: value, user_user_id: employeeData.user_user_id },
      ],
    }));

    // Log the employeeData to the console
    console.log({
      leaveType_id: leavetype_id,
      days: value,
      user_user_id: employeeData.user_user_id,
    });
  };

  const fetchLeaveType = () => {
    http
      .get(`leavetype/list`)
      .then((response) => {
        setLeaveType(response.data);
      })
      .catch((error) => {
        console.error("Error Fetching Table Data:", error);
      });
  };

  useEffect(() => {
    fetchLeaveType();
  }, []);

  const [msg, setMsg] = useState("");
  const [msg1, setMsg1] = useState("");
  const [EmailMsg, setEmailMsg] = useState("");
  const [PasswordMsg, setPasswordMsg] = useState("");
  const [MobileMsg, setMobileMsg] = useState("");
  const [SalaryMsg, setSalaryMsg] = useState("");
  const validateField = (
    field,
    value,
    setStateFunction,
    setMsgFunction,
    errorMessage
  ) => {
    if (value === "") {
      setStateFunction({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsgFunction(errorMessage);
      return false; // Validation failed
    }
    return true; // Validation passed
  };

  const [loading, setLoading] = useState(false);
  const SubmitData = () => {
    if (loading) return;
    setLoading(true);
    const isNameValid = validateField(
      "name",
      employeeData.name,
      setCheckStatus,
      setMsg,
      "Name cannot be empty!"
    );

    const isEmailValid = validateField(
      "email",
      employeeData.email,
      setEmailCheckStatus,
      setEmailMsg,
      "Email cannot be empty!"
    );

    const isPasswordValid = validateField(
      "password",
      employeeData.password,
      setPasswordCheckStatus,
      setPasswordMsg,
      "Password cannot be empty!"
    );
    const isMobaileValid = validateField(
      "mob",
      employeeData.mobile_no,
      setMobileCheckStatus,
      setMobileMsg,
      "Mobile No cannot be empty!"
    );
    const isSalaryValid = validateField(
      "salary",
      employeeData.salary,
      setSalaryCheckStatus,
      setSalaryMsg,
      "Salary No cannot be empty!"
    );
    if (isNameValid && isEmailValid && isPasswordValid && isMobaileValid && isSalaryValid) {
      console.log("employeeData", employeeData);

      https
        .post("/user/store", employeeData)
        .then(function (response) {
          // console.log(response.data.status);
          // toast.success(response.data.message);
          props.checkchang(response.data.message, response.data.status);
          setModal(false);
        })
        .catch(function (error) {
          console.log("Error", error);
        })
        .finally(() => {
          // Reset loading state to allow further submissions if necessary
          setLoading(false);
        });
    }
    else {
      // If validation fails, reset loading state
      setLoading(false);
    }
  };

  const handleEmployee = (e) => {
    setCheckStatus({});
    setMsg("");
    setemployeeData({ ...employeeData, [e.target.name]: e.target.value });
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
    };




    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);



  const { transcript, browserSupportsSpeechRecognition, resetTranscript } = useSpeechRecognition();

  const numberAndSymbolMapping = {
    zero: "0", one: "1", two: "2", three: "3", four: "4", five: "5",
    six: "6", seven: "7", eight: "8", nine: "9", ten: "10",
    dot: ".", comma: ",", dash: "-", plus: "+", at: "@", hashtag: "#",
    dollar: "$", percent: "%", ampersand: "&", asterisk: "*",
    exclamation: "!", question: "?", slash: "/", backslash: "\\",
    parenthesis: "()", brackets: "[]", brace: "{}", semicolon: ";",
    colon: ":", quote: '"', "single quote": "'", equal: "=",
    "greater than": ">", "less than": "<"
  };

  const convertSpokenNumbersToDigits = (text) => {
    return text.split(" ").map((word) => numberAndSymbolMapping[word.toLowerCase()] || word).join(" ");
  };

  const [isAddressListening, setIsAddressListening] = useState(false);


  // Updating the address field based on transcript
  useEffect(() => {
    if (isAddressListening && transcript) {
      const convertedTranscript = convertSpokenNumbersToDigits(transcript);
      setemployeeData((prevData) => ({
        ...prevData,
        address: prevData.address + " " + convertedTranscript,  // Append new transcript
      }));
    }
  }, [transcript, isAddressListening]);

  // Handle speech recognition activation
  useEffect(() => {
    if (isAddressListening) {
      SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
    } else {
      SpeechRecognition.stopListening();
      resetTranscript();
    }
  }, [isAddressListening, resetTranscript]);

  // Check if browser supports speech recognition
  if (!browserSupportsSpeechRecognition) {
    return <div>Your browser does not support speech recognition.</div>;
  }

  const handleAddressMicClick = () => {
    setIsAddressListening(!isAddressListening);  // Toggle microphone
  };

  return (
    <div>
      <Modal
        id="showModal"
        size="xl"
        isOpen={modal}
        toggle={toggle}
        style={{ marginTop: "5px", paddingTop: "10px" }}
        centered
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Add Team Member
        </ModalHeader>
        <div className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <div className="mb-3">
                  <Row>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>
                          Team Member Name <span className="text-danger">*</span>{" "}
                        </div>
                        <div style={{ color: "red" }}>{msg}</div>
                      </Label>
                      <CustomInput
                        checkNameStatus={checkNameStatus}
                        id="role-name-field"
                        className="form-control fw-bold"
                        placeholder="Employee Name"
                        type="text"
                        name="name"
                        onChange={handleEmployee}
                      />
                    </Col>

                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>Mobile Number<span className="text-danger fw-bold"> *</span></div>
                        <div className="text-danger fw-bold">{MobileMsg}</div>
                      </Label>

                      <Input

                        type="text"
                        value={employeeData ? employeeData.mobile_no : ""}
                        onChange={(e) => {
                          // Remove non-numeric characters
                          const input = e.target.value.replace(/\D/g, "");

                          // Apply validation for 10-digit number
                          if (input.length <= 10) {
                            setemployeeData({
                              ...employeeData,
                              mobile_no: input,
                            });
                          }

                          // Clear input on backspace
                          if (
                            e.nativeEvent.inputType ===
                            "deleteContentBackward" &&
                            input.length === 0
                          ) {
                            setemployeeData({
                              ...employeeData,
                              mobile_no: "",
                            });
                          }
                        }}
                        className="fw-bold"
                        placeholder="Mobile Number"
                        name="mob"
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>Emergency Mob no</div>
                      </Label>
                      {/* <Input
                        name="emergency_contact"
                        id="emergency_contact"
                        onChange={(e) => handleEmployee(e)}
                        className="form-control fw-bold"
                        placeholder="Emergency "
                        type="text"
                      /> */}
                      <Input
                        type="text"
                        value={employeeData ? employeeData.emergency_contact : ""}
                        onChange={(e) => {
                          // Remove non-numeric characters
                          const input = e.target.value.replace(/\D/g, "");

                          // Apply validation for 10-digit number
                          if (input.length <= 10) {
                            setemployeeData({
                              ...employeeData,
                              emergency_contact: input,
                            });
                          }

                          // Clear input on backspace
                          if (
                            e.nativeEvent.inputType ===
                            "deleteContentBackward" &&
                            input.length === 0
                          ) {
                            setemployeeData({
                              ...employeeData,
                              emergency_contact: "",
                            });
                          }
                        }}
                        className="fw-bold"
                        placeholder="Emergency Mobile Number"
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>
                          Email Address<span className="text-danger"> *</span>{" "}
                        </div>
                        <div style={{ color: "red" }}>{EmailMsg}</div>
                      </Label>
                      <Input
                        checkEmailStatus={checkEmailStatus}
                        name="email"
                        id="email"
                        onChange={(e) => handleEmployee(e)}
                        className="form-control fw-bold"
                        placeholder="Email address"
                        type="email"
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>
                          Password
                          <span className="text-danger"> *</span>{" "}
                        </div>
                        <div style={{ color: "red" }}>{PasswordMsg}</div>
                      </Label>
                      <Input
                        checkPasswordStatus={checkPasswordStatus}
                        name="password"
                        id="password"
                        onChange={(e) => handleEmployee(e)}
                        className="form-control fw-bold"
                        placeholder="Password"
                        type={showPassword ? "text" : "password"} // Toggle between text and password

                      />
                      <i
                        className={`ri-eye${showPassword ? "-off" : ""}-fill`}
                        style={{
                          position: "absolute",
                          top: "80%",
                          right: "20px",
                          transform: "translateY(-50%)",
                          cursor: "pointer",
                        }}
                        onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                      ></i>
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>Salary<span className="text-danger fw-bold"> *</span></div>
                        <div className="text-danger fw-bold">{SalaryMsg}</div>
                      </Label>
                      <Input
                        name="salary"
                        id="salary"
                        onChange={(e) => handleEmployee(e)}
                        className="form-control fw-bold"
                        placeholder="Salary"
                        type="text"
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div> Date of Joining</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "d/m/Y",
                          defaultDate: "today",
                        }}
                        name="payment_date"
                        placeholder="Select Joining Date"
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];

                          const year = selectedDate.getFullYear();
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const formattedDate = `${year}-${month}-${day} 00:00:00`;

                          setemployeeData({
                            ...employeeData,
                            date_of_joining: formattedDate,
                          });
                        }}
                      />

                    </Col>

                    <Col lg={3}>
                      <Label
                        htmlFor="customername-field"
                        className="form-label mt-2 fw-bold d-flex justify-content-between"
                      >
                        <div>Shift<span className="text-danger fw-bold"></span></div>
                        <a
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => setModalStatess1(!false)}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </a>
                      </Label>
                      {ActiveShift ? (
                        <Select
                          onChange={getSelectedCityValue}
                          options={(EmployeeShift || []).map((group) => ({
                            value: group.shift_id,
                            label: group.shift_name,
                          }))}
                          name="shift_id"
                          id="shift_id"
                          className="fw-bold"
                          placeholder={
                            ManageShift == 0
                              ? (EmployeeShift && EmployeeShift.length > 0 ? EmployeeShift[0].shift_name : "Select Shift")
                              : (EmployeeShift && EmployeeShift.length > 0 ? EmployeeShift[EmployeeShift.length - 1].shift_name : "Select Shift")
                          }
                        />
                      ) : (
                        <Input
                          type="text"
                          readOnly
                          className="form-control fw-bold "
                          style={{ color: "red" }}
                          value="First Fill the Employee Shift *"
                          placeholder=""
                        />
                      )}
                    </Col>
                    {
                      user.user.user_behave !== 1 ?

                        <Col lg={3}>
                          <Label
                            htmlFor="customername-field"
                            className="form-label mt-3 fw-bold d-flex justify-content-between"
                          >
                            <div>
                              Department<span className="text-danger fw-bold"> </span>
                            </div>
                            <a
                              className="btn btn-success btn-sm px-1"
                              style={{ padding: "0px" }}
                              onClick={() => setModalStatess(!false)}
                            >
                              <i className="ri-add-line align-bottom"></i>
                            </a>
                          </Label>
                          {ActiveShift ? (
                            <Select
                              onChange={(e) => {

                                setemployeeData({
                                  ...employeeData,
                                  temp_role_id: e.value,
                                  user_role_id: e.value
                                })
                              }}
                              options={(EmployeeRole || []).filter(item => item.role_created_for == null).map((group) => ({
                                value: group.role_id,
                                label: group.role_name,
                              }))}
                              name="user_role_id"
                              id="user_role_id"
                              className="fw-bold"
                              placeholder={
                                ManageRole == 0
                                  ? (EmployeeRole && EmployeeRole.length > 0 ? EmployeeRole[0].role_name : "Select Role")
                                  : (EmployeeRole && EmployeeRole.length > 0 ? EmployeeRole[EmployeeRole.length - 1].role_name : "Select Role")
                              }
                            />
                          ) : (
                            <Input
                              type="text"
                              readOnly
                              className="form-control fw-bold "
                              style={{ color: "red" }}
                              value="First Fill the  Employee Role *"
                              placeholder=""
                            />
                          )}
                        </Col>
                        : <Col lg={3}>
                          <Label
                            htmlFor="categoryname-field"
                            className="form-label fw-bold d-flex justify-content-between  mt-3"
                          >
                            <div>Department </div>
                            {/* <div style={{ color: "red" }}>{msg}</div> */}
                          </Label>
                          <Input className="fw-bold" disabled value={user.user.role_name} />
                        </Col>}
                    {user.user.u_type == 1 || user.user.u_type == 3 || user.user.u_type == 5 || (user.user.u_type == 2 && user.user.user_behave == 1) || (user.user.u_type == 4 && user.user.user_behave == 1) ?
                      <Col lg={3}>
                        <Label
                          htmlFor="categoryname-field"
                          className="form-label fw-bold d-flex justify-content-between  mt-3"
                        >
                          <div>Role </div>
                          {/* <div style={{ color: "red" }}>{msg}</div> */}
                        </Label>
                        <Select
                          className="fw-bold"
                          placeholder={"Employee"}
                          onChange={(e) => {
                            setemployeeData({
                              ...employeeData,
                              user_role_id: e.value,
                              user_behave: 0
                            });
                          }}
                          // options={[
                          //   { label: "Employee", value: 0 },
                          //   { label: "Manager", value: 1 }
                          // ]}
                          options={(EmployeeRole || []).filter(item => item.role_created_for == employeeData.temp_role_id).map((group) => ({
                            value: group.role_id,
                            label: group.role_name,
                          }))}
                        />
                      </Col>
                      : <></>}
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>Last Experience</div>
                        {/* <div style={{ color: "red" }}>{msg}</div> */}
                      </Label>
                      <Input
                        id="role-name-field"
                        className="form-control fw-bold"
                        placeholder="Last Experience"
                        type="text"
                        name="last_experience"
                        onChange={handleEmployee}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>Last Working Company</div>
                        {/* <div style={{ color: "red" }}>{msg}</div> */}
                      </Label>
                      <Input
                        checkNameStatus={checkNameStatus}
                        id="role-name-field"
                        className="form-control fw-bold"
                        placeholder="Last Working Company"
                        type="text"
                        name="last_working_company"
                        onChange={handleEmployee}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>Last Company Salary</div>
                        {/* <div style={{ color: "red" }}>{msg}</div> */}
                      </Label>
                      <Input
                        id="role-name-field"
                        className="form-control fw-bold"
                        placeholder=" Last Company Salary"
                        type="text"
                        name="last_company_salary"
                        onChange={handleEmployee}
                      />
                    </Col>

                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>Aadhar Number</div>
                        {/* <div style={{ color: "red" }}>{msg}</div> */}
                      </Label>
                      <Input
                        id="role-name-field"
                        className="form-control fw-bold"
                        placeholder="Aadhar Number"
                        type="text"
                        name="aadhar_no"
                        onChange={handleEmployee}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>Pan Number</div>
                        {/* <div style={{ color: "red" }}>{msg}</div> */}
                      </Label>
                      <Input
                        id="role-name-field"
                        className="form-control fw-bold"
                        placeholder="Pan Number"
                        type="text"
                        name="pan_no"
                        onChange={handleEmployee}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        <div>User Upi</div>
                        {/* <div style={{ color: "red" }}>{msg}</div> */}
                      </Label>
                      <Input
                        checkNameStatus={checkNameStatus}
                        id="role-name-field"
                        className="form-control fw-bold"
                        placeholder="User Upi"
                        type="text"
                        name="user_upi"
                        onChange={handleEmployee}
                      />
                    </Col>
                    <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3"
                      >
                        Address
                      </Label>
                      <textarea
                        name="address"
                        id="address"
                        placeholder="Address"
                        value={employeeData.address}  // Controlled value
                        className="form-control fw-bold"
                        onChange={handleEmployee}
                      ></textarea>
                      {/* Microphone button positioned inside the input */}
                      <Button
                        type="button"
                        color="none"
                        style={{
                          position: "absolute",
                          top: "50%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          width: "50px",
                          height: "50px",
                          padding: "0",
                          borderRadius: "50%",
                          zIndex: "10",
                          background: "none",  // No background
                          border: "none",  // No border
                          boxShadow: "none",  // No shadow
                        }}
                        onClick={handleAddressMicClick}  // Toggle microphone
                      >
                        <i
                          className={
                            isAddressListening
                              ? "ri-stop-circle-fill align-bottom"  // Icon when listening
                              : "ri-mic-fill align-bottom"  // Icon when not listening
                          }
                        ></i>
                      </Button>
                    </Col>
                    <Col lg={6}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between mt-3"
                      >
                        <div>Leave-Type</div>
                      </Label>

                          <div className="row">
                      {(leaveType || []).map((leavetype) => (
                        <div key={leavetype.leavetype_id} className="col-6 form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id={`leaveType-${leavetype.leavetype_id}`}
                            value={leavetype.leavetype_id}
                            checked={selectedleaveType.includes(leavetype.leavetype_id)}
                            onChange={(e) => handleLeaveTypeChange(e, leavetype.leavetype_id)}
                          />
                          <label className="form-check-label" htmlFor={`leaveType-${leavetype.leavetype_id}`}>
                            {leavetype.leavetype_name}
                          </label>

                          {/* Conditionally render the input box if this checkbox is checked */}
                          {showInputForLeaveType === leavetype.leavetype_id && (
                            <input
                              type="number"
                              className="form-control mt-2"
                              placeholder="Add Day's"
                              value={daysInput[leavetype.leavetype_id] || ""}
                              onChange={(e) => handleDaysInputChange(e, leavetype.leavetype_id)}
                            />
                          )}
                        </div>
                      ))}
                      </div>

                    </Col>


                    <Col lg={3}>
                      <div className="mb-4 mt-3">
                        <h5 className="fs-15 mb-1">Adhar Image</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="employeeAdhar"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title=""
                                data-bs-original-title="Select Image"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i className="ri-image-fill" style={{ color: "#009CA4", fontSize: "20px" }}></i>

                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeAdhar"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    aadhar_photo: e.target.files[0],
                                  });
                                  SetEmployeeAdhar(e.target.files[0]);
                                }}
                              />
                            </div>
                            <div className="avatar-lg">
                              <div className="avatar-title bg-light rounded">
                                {EmployeeAdhar ? (
                                  <img
                                    src={URL.createObjectURL(EmployeeAdhar)}
                                    id="product-img"
                                    alt="product-img"
                                    height={"100px"}
                                    width={"100px"}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-4 mt-3">
                        <h5 className="fs-15 mb-1">Pan Image</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="employeePan"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title=""
                                data-bs-original-title="Select Image"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i className="ri-image-fill" style={{ color: "#009CA4", fontSize: "20px" }}></i>

                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeePan"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    pan_photo: e.target.files[0],
                                  });
                                  SetEmployeePan(e.target.files[0]);
                                }}
                              />
                            </div>
                            <div className="avatar-lg">
                              <div className="avatar-title bg-light rounded">
                                {EmployeePan ? (
                                  <img
                                    src={URL.createObjectURL(EmployeePan)}
                                    id="product-img"
                                    alt="product-img"
                                    height={"100px"}
                                    width={"100px"}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-4 mt-3">
                        <h5 className="fs-15 mb-1">
                          Profile
                          <div style={{ color: "red" }}>{msg1}</div>
                        </h5>

                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="empoyeeProfile"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title=""
                                data-bs-original-title="Select Image"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i className="ri-image-fill" style={{ color: "#009CA4", fontSize: "20px" }}></i>

                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="empoyeeProfile"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    profile_photo: e.target.files[0],
                                  });
                                  SetEmployeeProfile(e.target.files[0]);
                                }}
                              />
                            </div>
                            <div className="avatar-lg">
                              <div className="avatar-title bg-light rounded">
                                {EmployeeProfile ? (
                                  <img
                                    src={URL.createObjectURL(EmployeeProfile)}
                                    id="product-img"
                                    alt="product-img"
                                    height={"100px"}
                                    width={"100px"}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="mb-4 mt-3">
                        <h5 className="fs-15 mb-1">Qr Code</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="employeeQrCode"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title=""
                                data-bs-original-title="Select Image"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i className="ri-image-fill" style={{ color: "#009CA4", fontSize: "20px" }}></i>

                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeQrCode"
                                type="file"
                                accept="image/png, image/gif, image/jpeg"
                                onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    bank_passbook_photo: e.target.files[0],
                                  });
                                  SetEmployeeQrCode(e.target.files[0]);
                                }}
                              />
                            </div>
                            <div className="avatar-lg">
                              <div className="avatar-title bg-light rounded">
                                {EmployeeQrCode ? (
                                  <img
                                    src={URL.createObjectURL(EmployeeQrCode)}
                                    id="product-img"
                                    alt="product-img"
                                    height={"100px"}
                                    width={"100px"}
                                  />
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                    {/* <Col lg={3}>
                      <div className="mb-4 mt-3">
                        <h5 className="fs-15 mb-1">
                          Document
                          <div style={{ color: "red" }}>{msg1}</div>
                        </h5>

                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="employeeDocument"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Select Document"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i
                                      className="ri-file-upload-fill"
                                      style={{
                                        color: "#009CA4",
                                        fontSize: "20px",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeDocument"
                                type="file"
                                accept=".pdf,.doc,.docx,.txt,.xlsx"
                                onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    document: e.target.files[0],
                                  });
                                  SetEmployeeDoc(e.target.files[0]);
                                }}
                              />
                            </div>
                            <div className="avatar-lg">
                          
                              {EmployeeDoc ? (
                                EmployeeDoc.type.startsWith("image/") ? (
                                  <img
                                    src={URL.createObjectURL(EmployeeDoc)}
                                    alt="Selected file preview"
                                    height="50px"
                                    width="50px"
                                  />
                                ) : (
                                  <div className="d-flex flex-column align-items-center">
                                    <div>
                                      {EmployeeDoc.type.includes("pdf") && (
                                        <i
                                          className="ri-file-pdf-fill"
                                          style={{
                                            fontSize: "50px",
                                            color: "#FF0000",
                                          }}
                                        ></i>
                                      )}
                                      {EmployeeDoc.type.includes("word") && (
                                        <i
                                          className="ri-file-word-fill"
                                          style={{
                                            fontSize: "50px",
                                            color: "#1E90FF",
                                          }}
                                        ></i>
                                      )}
                                      {EmployeeDoc.type.includes("excel") && (
                                        <i
                                          className="ri-file-excel-fill"
                                          style={{
                                            fontSize: "50px",
                                            color: "#28A745",
                                          }}
                                        ></i>
                                      )}
                                      {!EmployeeDoc.type.includes("pdf") &&
                                        !EmployeeDoc.type.includes("word") &&
                                        !EmployeeDoc.type.includes(
                                          "excel"
                                        ) && (
                                          <i
                                            className="ri-file-fill"
                                            style={{
                                              fontSize: "50px",
                                              color: "#6c757d",
                                            }}
                                          ></i>
                                        )}
                                    </div>
                                    <p style={{ color: "#007bff" }}>
                                      {EmployeeDoc.name
                                        ? `${EmployeeDoc.name.substring(0, 5)}.${EmployeeDoc.name.split('.').pop()}`
                                        : ''}
                                    </p>
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                       
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col> */}

                    {/* <Col lg={3}>
                      <div className="mb-4 mt-3">
                        <h5 className="fs-15 mb-1">
                          Audio
                          <div style={{ color: "red" }}>{msg1}</div>
                        </h5>

                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="employeeAudio"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Select Audio"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i
                                      className="ri-music-fill"
                                      style={{
                                        color: "#009CA4",
                                        fontSize: "20px",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeAudio"
                                type="file"
                                accept=".mp3,.aac,.wav,.flac,.mpeg"
                                onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    audio: e.target.files[0],
                                  });
                                  SetEmployeeAudio(e.target.files[0]);
                                }}
                              />
                            </div>
                            <div className="avatar-lg">
                      
                              {EmployeeAudio ? (
                                <div className="d-flex flex-column align-items-center">
                                
                                  <i
                                    className="ri-file-music-fill"
                                    style={{
                                      fontSize: "50px",
                                      color: "#007bff",

                                    }}
                                  ></i>
                             
                                  <p style={{ color: "#007bff" }}>
                                    {EmployeeAudio.name
                                      ? `${EmployeeAudio.name.substring(0, 5)}.${EmployeeAudio.name.split('.').pop()}`
                                      : ''}
                                  </p>

                                </div>
                              ) : (
                                ""
                              )}
                  
                            </div>
                          </div>
                        </div>
                      </div>
                    </Col> */}

                    {/* <Col lg={3}>
                      <div className="mb-4 mt-3">
                        <h5 className="fs-15 mb-1">
                          Video
                          <div style={{ color: "red" }}>{msg1}</div>
                        </h5>

                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="position-absolute top-100 start-100 translate-middle">
                              <label
                                htmlFor="employeeVideo"
                                className="mb-0"
                                data-bs-toggle="tooltip"
                                data-bs-placement="right"
                                title="Select Video"
                              >
                                <div className="avatar-xs">
                                  <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                    <i
                                      className="ri-video-fill"
                                      style={{
                                        color: "#009CA4",
                                        fontSize: "20px",
                                      }}
                                    ></i>
                                  </div>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeVideo"
                                type="file"
                                accept="video/mp4, video/avi, video/mkv"
                                onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    video: e.target.files[0],
                                  });
                                  SetEmployeeVideo(e.target.files[0]);
                                }}
                              />
                            </div>
                            <div className="avatar-lg">
                           
                              {EmployeeVideo ? (
                                <div className="d-flex flex-column align-items-center">
                      
                                  <i
                                    className="ri-video-fill"
                                    style={{
                                      fontSize: "50px",
                                      color: "#007bff",

                                    }}
                                  ></i>
                
                                  <p style={{ color: "#007bff" }}>
                                    {EmployeeVideo.name
                                      ? `${EmployeeVideo.name.substring(0, 5)}.${EmployeeVideo.name.split('.').pop()}`
                                      : ''}
                                  </p>

                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                     
                          </div>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </div>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <button
                ref={submitButtonRef}
                name="submit"
                id="submit"
                type="submit"
                className="btn btn-primary"
                // onClick={() => SubmitData()}
                disabled={loading} // Disable button if loading
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                {loading ? "Saving..." : "Save"}
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>
            </div>
          </Form>
        </div>
      </Modal>

      {modalStatess1 === true ? (
        <ShiftAdd
          modalStates={modalStatess1}
          setModalStates={() => {
            setModalStatess1(false);
          }}
          checkchang={handleCallback}
        />
      ) : modalStatess === true ? (
        <RoleCreateModal
          modalStates={modalStatess}
          setModalStates={() => {
            setModalStatess(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default UserCreateModal;
