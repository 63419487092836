import React, { useState } from "react";
import {
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Row,
    Col,
    Button,
    Form,
    Input,
    FormGroup,
} from "reactstrap";
import CustomInput from "../Unit/Input";
import Select from "react-select";
import AuthUser from "../../helpers/Authuser";

const FormFiledAdd = (props) => {
    const { http } = AuthUser();
    const [isToggled, setIsToggled] = useState(false);
    const [isToggledTable, setIsToggledTable] = useState(false);
    const [formData, setFormData] = useState({
        formdynamic_name: "",
        formdynamic_type: "",
        formdynamic_category: props.status || null,
        formdynamic_status: 1,
    });
    const [errors, setErrors] = useState({});

    const commonInputTypes = [
        { id: 1, typeame: "Text" },
        { id: 2, typeame: "Date" },
        { id: 3, typeame: "Description" },
    ];

    const updateFormDynamicStatus = () => {
        if (isToggled && isToggledTable) {
            setFormData((prev) => ({ ...prev, formdynamic_status: 4 }));
        } else if (isToggled && !isToggledTable) {
            setFormData((prev) => ({ ...prev, formdynamic_status: 2 }));
        } else if (!isToggled && isToggledTable) {
            setFormData((prev) => ({ ...prev, formdynamic_status: 3 }));
        } else {
            setFormData((prev) => ({ ...prev, formdynamic_status: 1 }));
        }
    };

    const handleToggle = () => {
        setIsToggled((prev) => !prev);
    };

    const handleToggleTable = () => {
        setIsToggledTable((prev) => !prev);
    };

    React.useEffect(() => {
        updateFormDynamicStatus();
    }, [isToggled, isToggledTable]);

    const validateForm = () => {
        const newErrors = {};
        if (!formData.formdynamic_name.trim()) {
            newErrors.formdynamic_name = "Field Name is required.";
        }
        if (!formData.formdynamic_type) {
            newErrors.formdynamic_type = "Field Type is required.";
        }
        return newErrors;
    };

    const SaveHandel = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        http.post("/formdynamic/store", formData)
            .then((res) => {
                props.checkchang(res.data.message, res.data.status);
                setErrors({});
                props.setModalStates();
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const Close = () => {
        props.setModalStates();
    };

    return (
        <div>
            <Modal id="showModal" isOpen={props.modalStates} centered>
                <ModalHeader className="bg-light p-3" toggle={props.setModalStates}>
                    Create Field
                </ModalHeader>
                <span className="tablelist-form">
                    <Form onSubmit={SaveHandel}>
                        <ModalBody>
                            <Card className="border card-border-success p-3 shadow-lg">
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="field-name" className="form-label fw-bold d-flex justify-content-between">
                                                <div>
                                                    Field Name<span style={{ color: "red" }}> *</span>
                                                </div>
                                                <div>
                                                    {errors.formdynamic_name && (
                                                        <small id="field-name-error" className="text-danger d-block">
                                                            {errors.formdynamic_name}
                                                        </small>
                                                    )}
                                                </div>
                                            </Label>
                                            <CustomInput
                                                id="field-name"
                                                className="form-control fw-bold"
                                                placeholder="Field Name"
                                                type="text"
                                                value={formData.formdynamic_name}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        formdynamic_name: e.target.value,
                                                    })
                                                }
                                                aria-describedby="field-name-error"
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="field-type" className="form-label fw-bold d-flex justify-content-between">
                                                <div>
                                                    Field Type<span style={{ color: "red" }}> *</span>
                                                </div>
                                                <div>
                                                    {errors.formdynamic_type && (
                                                        <small id="field-type-error" className="text-danger d-block">
                                                            {errors.formdynamic_type}
                                                        </small>
                                                    )}
                                                </div>
                                            </Label>
                                            <Select
                                                id="field-type"
                                                options={commonInputTypes.map((e) => ({
                                                    label: e.typeame,
                                                    value: e.id,
                                                }))}
                                                onChange={(e) =>
                                                    setFormData({
                                                        ...formData,
                                                        formdynamic_type: e.value,
                                                    })
                                                }
                                                aria-describedby="field-type-error"
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={6} className="d-flex flex-column justify-content-center align-items-center">
                                        <Label className="form-label fw-bold mt-2">
                                            Field Required
                                        </Label>
                                        <FormGroup switch className="mb-3">
                                            <Input
                                                type="switch"
                                                checked={isToggled}
                                                onChange={handleToggle}
                                                style={{
                                                    width: "60px",
                                                    height: "25px",
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg={6} className="d-flex flex-column justify-content-center align-items-center">
                                        <Label className="form-label fw-bold mt-2">
                                            Show Table
                                        </Label>
                                        <FormGroup switch className="mb-3">
                                            <Input
                                                type="switch"
                                                checked={isToggledTable}
                                                onChange={handleToggleTable}
                                                style={{
                                                    width: "60px",
                                                    height: "25px",
                                                }}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Card>
                        </ModalBody>
                        <div className="modal-footer">
                            <Button type="submit" color="primary">
                                <i className="ri-save-3-line align-bottom me-1"></i>
                                Save
                            </Button>
                            <Button color="danger" onClick={Close}>
                                <i className="ri-close-line me-1 align-middle" />
                                Close
                            </Button>
                        </div>
                    </Form>
                </span>
            </Modal>
        </div>
    );
};

export default FormFiledAdd;
