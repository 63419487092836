import React, { useEffect, useState, useCallback, useRef } from "react";
import {
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Row,
    Col,
    Button,
    Form,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import CustomInput from "../Unit/Input";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";

const PipeLineCreate = (props) => {
    const { https, user } = AuthUser();
    const [modalstage, setModalstage] = useState(false);
    const [FindData, SetFind] = useState({});
    const [modal, setModal] = useState(false);
    const [valid, setvalid] = useState(0);
    const [pipelinestage, Setpipelinestage] = useState([]);
    const [pipeline, Setpipeline] = useState({
        pipeline_name: "",
        pipeline_create_user: user.user.uid,
        children: [],
    });
    const [stageName, setStageName] = useState("");
    const [isEditing, setIsEditing] = useState(false);
    const [editIndex, setEditIndex] = useState(null);

    useEffect(() => {
        Setpipeline((prev) => ({
            ...prev,
            children: pipelinestage,
        }));
    }, [pipelinestage]);

    const handleStageNameChange = (e) => {
        setvalid(0);
        setStageName(e.target.value);
    };

    const addStage = () => {
        if (stageName.trim() === "") {
            setvalid(1);
            return;
        }

        if (isEditing) {
            Setpipelinestage((prev) =>
                prev.map((stage, index) =>
                    index === editIndex ? { pipeline_stages: stageName.trim() } : stage
                )
            );
            toast.success("Stage Updated Successfully");
        } else {
            Setpipelinestage((prev) => [
                ...prev,
                { pipeline_stages: stageName.trim() },
            ]);
            // toast.success("Stage Created Successfully");
        }

        setModalstage(false);
        setStageName("");
        setIsEditing(false);
        setEditIndex(null);
    };

    const editStage = (e,index) => {
        e.preventDefault();
        setStageName(pipelinestage[index].pipeline_stages);
        setIsEditing(true);
        setEditIndex(index);
        setModalstage(true);
    };
    const [deleteModal, setDeleteModal] = useState(false);
    const [ID, SetID] = useState();
    const deleteStage = (e,index) => {
        e.preventDefault();
        SetID(index);
        setDeleteModal(true);

    };

    const SubmitData = (e) => {
        e.preventDefault();
        if (pipeline.pipeline_name.trim() === "") {
            setvalid(2);
            return;
        }
        setLoading(true);
        https
            .post("/pipeline/store", pipeline)
            .then((response) => {
                props.checkchang(response.data.message, response.data.status);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const Close = () => {
        setModal(false);
        props.setModalStates();
    };

    useEffect(() => {
        setModal(false);
        toggle();
    }, [props.modalStates]);

    const toggle = useCallback(() => {
        setModal(!modal);
    }, [modal]);

    const togglemodalStage = useCallback(() => {
        setModalstage(!modalstage);
        setStageName("");
        setIsEditing(false);
        setEditIndex(null);
    }, [modalstage]);

    const [loading, setLoading] = useState(false);
    const submitButtonRef = useRef();

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.altKey && event.key === "Escape") {
                event.preventDefault();
                props.setModalStates(false);
            }
            if (event.altKey && (event.key === "s" || event.key === "S")) {
                event.preventDefault();
                submitButtonRef.current.click();
            }
            if (event.altKey && (event.key === "c" || event.key === "C")) {
                event.preventDefault();
                props.setModalStates(false);
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);
    const handleDeleteOrder = () => {
        Setpipelinestage((prev) => prev.filter((_, i) => i !== ID));
        setDeleteModal(false);
        // toast.success("Stage Deleted Successfully");
    }
    return (
        <div>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <ToastContainer limit={1} />
            <Modal  id="showModal" isOpen={modal} toggle={toggle} className="modal-lg" centered>
                <ModalHeader className="bg-light p-3" toggle={toggle}>
                    Create Pipe Line
                </ModalHeader>
                <span className="tablelist-form">
                    <Form

                        action="#"
                    >
                        <ModalBody>
                            <Card className="border card-border-success p-3 shadow-lg">
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label
                                                htmlFor="pipeline-field"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                    Pipe Line Name<span style={{ color: "red" }}> *</span>
                                                </div>
                                                {valid === 2 && (
                                                    <div style={{ color: "red" }}>
                                                        Pipe line name is required !
                                                    </div>
                                                )}
                                            </Label>
                                            <CustomInput
                                                name="pipeline_name"
                                                id="pipeline-field"
                                                className="form-control fw-bold"
                                                placeholder="Pipe Line Name"
                                                type="text"
                                                onChange={(e) => {
                                                    Setpipeline({
                                                        ...pipeline,
                                                        pipeline_name: e.target.value,
                                                    });
                                                    setvalid(0);
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12} className="mt-2">
                                        <div className="form-label fw-bold d-flex justify-content-between">
                                            <div>Open Stages</div>
                                            <Button
                                                className="btn btn-success"
                                                onClick={togglemodalStage}
                                            >
                                                +
                                            </Button>
                                        </div>
                                        <table
                                            role="table"
                                            className="align-middle table-nowrap table table-hover text-center"
                                        >
                                            <thead className="table-light text-muted text-uppercase">
                                                <tr>
                                                    <th>#</th>
                                                    <th>Stage Name</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pipelinestage.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index+1}</td>
                                                        <td>{item.pipeline_stages}</td>
                                                        <td className="text-center">
                                                            
                                                                    <button
                                                                        className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                                                        onClick={(e) => editStage(e,index)}
                                                                    >
                                                                        <i className="ri-pencil-fill fs-16" />
                                                                    </button>
                                                             
                                                                    <button
                                                                        onClick={(e) => deleteStage(e,index)}
                                                                        className="text-danger d-inline-block remove-item-btn border-0 bg-transparent"
                                                                    >
                                                                        <i className="ri-delete-bin-5-fill fs-16" />
                                                                    </button>
                                                              
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Col>
                                </Row>
                            </Card>
                        </ModalBody>
                        <div className="modal-footer">
                            <button
                                ref={submitButtonRef}
                                name="close"
                                id="close"
                                type="submit"
                                className="btn btn-primary"
                                onClick={(e) => SubmitData(e)}
                            >
                                <i className="ri-save-3-line align-bottom me-1"></i>
                                Save
                            </button>
                            <button
                                name="close"
                                id="close"
                                type="button"
                                className="btn btn-danger"
                                onClick={Close}
                            >
                                <i className="ri-close-line me-1 align-middle" />
                                Close
                            </button>
                        </div>
                    </Form>
                </span>
            </Modal>
            <Modal id="showModal" isOpen={modalstage} toggle={togglemodalStage} centered>
                <ModalHeader className="bg-light p-3" toggle={togglemodalStage}>
                    {isEditing ? "Edit Stage" : "Create Stage"}
                </ModalHeader>
                <span className="tablelist-form">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            addStage();
                        }}
                        action="#"
                    >
                        <ModalBody>
                            <Card className="border card-border-success p-3 shadow-lg">
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label
                                                htmlFor="stage-name-field"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                    Stage Name<span style={{ color: "red" }}> *</span>
                                                </div>
                                                {valid === 1 && (
                                                    <span className="text-danger">Stage name is required!</span>
                                                )}
                                            </Label>
                                            <CustomInput
                                                name="stage_name"
                                                id="stage-name-field"
                                                className="form-control fw-bold"
                                                placeholder="Enter Stage Name"
                                                type="text"
                                                value={stageName}
                                                onChange={handleStageNameChange}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </ModalBody>
                        <div className="modal-footer">
                            <Button type="submit" className="btn btn-primary">
                                Save
                            </Button>
                            <Button
                                type="button"
                                className="btn btn-danger"
                                onClick={togglemodalStage}
                            >
                                Close
                            </Button>
                        </div>
                    </Form>
                </span>
            </Modal>
        </div>
    );
};

export default PipeLineCreate;
