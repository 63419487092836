import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Form,
  Row,
  Col,
  Button,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import CustomInput from "../Unit/Input";
import CustomerGroupAdd from "../CustomerGroup/CustomerGroupAdd";
import CityAdd from "../City/CityAdd";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Flatpickr from "react-flatpickr";
import FormFiledAdd from "../Product/FormFiledAdd";
import FormFiledEdit from "../Product/FormFiledEdit";
const CustomerAdd = (props) => {
  const { http, https, user } = AuthUser();
  const [CustomerGroup, setCustomerGroup] = useState();
  const [activeGroup, setActiveGroup] = useState(false);
  const [CustomerCity, setCustomerCity] = useState();
  const [activeCity, setActiveCity] = useState(false);
  const [modalStatess, setModalStatess] = useState(false);
  const [modalStatess1, setModalStatess1] = useState(false);
  const [counts, Setcounts] = useState(1);
  const [manageGroup, setManageGroup] = useState(0);
  const [manageCity, setManageCity] = useState(0);
  const [filedList, setFiledList] = useState([]);
  const [filedListvalid, setFiledListvalid] = useState([]);
  const [valid, setValid] = useState(null);
  const [fieldValues, setFieldValues] = useState({});
  const [formmodalStates, setformModalStates] = useState(false);
  const [formData, setFormData] = useState(() => {
    const safeFiledList = Array.isArray(filedList) ? filedList : [];
    return safeFiledList.reduce((acc, temp) => {
      acc[temp.formdynamic_path] = "";
      return acc;
    }, {});
  });
  useEffect(() => {
    if (Array.isArray(filedList)) {
      const initialData = filedList.reduce((acc, temp) => {
        acc[temp.formdynamic_path] = "";
        return acc;
      }, {});
      setFormData(initialData);
    }
  }, [filedList]);
  const formfiledList = () => {
    http
      .get("/formdynamic/list")
      .then((res) => {
        const filterData = res.data.filter(
          (temp) => temp.formdynamic_category == 2
        );
        setFiledList(filterData);
        const filterDataValid = res.data.filter(
          (temp) => temp.formdynamic_category == 2 && temp.formdynamic_status == 2 || temp.formdynamic_status == 4
        );
        setFiledListvalid(filterDataValid)
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const handleCallback = (data) => {
    Setcounts(counts + 1);
    setManageGroup(1);
    setManageCity(1);
    toast.success(data);
    setModalStatess(false);
    setModalStatess1(false);
  };
  const [validStatus, setValidStatus] = useState(0);
  useEffect(() => {
    formfiledList();
    http
      .get("/all_customer_groups")
      .then(function (response) {
        if (response.data.length == 0) {
          setActiveGroup(false);
        } else {
          setCustomerGroup(response.data);
          setActiveGroup(true);
          setCustomersData(() => ({
            ...CustomersData,
            customer_group:
              manageGroup == 0
                ? response.data[0].customer_group_id
                : response.data[response.data.length - 1].customer_group_id,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    http
      .get("/city/list")
      .then(function (response) {
        if (response.data.length == 0) {
          setActiveCity(false);
        } else {
          setCustomerCity(response.data);
          setActiveCity(true);
          setCustomersData(() => ({
            ...CustomersData,
            customer_city:
              manageCity == 0
                ? response.data[0].city_id
                : response.data[response.data.length - 1].city_id,
          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [counts + 1]);

  const [CustomersData, setCustomersData] = useState({
    customer_name: "",
    customer_whatsapp_no: "",
    customer_alternative_no: "",
    customer_email: "",
    customer_city: 1,
    customer_group: 1,
    customer_object: "",
    customer_designation: "",
    customer_address: "",
    customer_compnay_name: "",
    customer_fields: "",
  });

  const getCustomerData = (e) => {
    setCustomersData({ ...CustomersData, [e.target.name]: e.target.value });
    if (CustomersData.customer_name != "") {
      setCheckStatus({});
      setMsg("");
    }
  };
  const getSelectedGroupValue = (e) => {
    setCustomersData({ ...CustomersData, customer_group: e.value });
  };
  const getSelectedCityValue = (e) => {
    setValidStatus(0)
    setCustomersData({ ...CustomersData, customer_city: e.value });
  };
  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");

  const [loading, setLoading] = useState(false);
  const handleFieldChange = (e, fieldId) => {
    const { value } = e.target;
    setFieldValues((prevState) => ({
      ...prevState,
      [fieldId]: value,
    }));
  };

  const OnSubmited = async () => {
   
    if (CustomersData.customer_name === "") {
      setValidStatus(1);

      return;
    }
   
    const missingFields = filedList
      .filter(
        (field) =>
          field.formdynamic_status === 2 && !fieldValues[field.formdynamic_id]
      )
      .map((field) => field.formdynamic_name);

    if (missingFields.length > 0) {
      console.error("Missing required fields:", missingFields.join(", "));

      return;
    }

  
    const structuredData = filedList.reduce((acc, field) => {
      let value = fieldValues[field.formdynamic_id] || "";

      
      if (field.formdynamic_type === "2") {
        try {
          value = new Date(value).toISOString();
        } catch (error) {
          console.error(`Invalid date for ${field.formdynamic_path}:`, value);
          return acc;
        }
      }

      const keys = field.formdynamic_path.split(".");
      let currentLevel = acc;

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          currentLevel[key] = value;
        } else {
          currentLevel[key] = currentLevel[key] || {};
          currentLevel = currentLevel[key];
        }
      });

      return acc;
    }, {});

  
    let isValidSet = false;
    Object.entries(structuredData).forEach(([key, value]) => {
      for (const temp of filedListvalid) {
        if (temp.formdynamic_path === key) {
          if (value == null || value === "") {
            if (!isValidSet) {
              setValid(key);
              isValidSet = true;
            }
            return;
          }
        }
      }
    });

    Object.entries(structuredData).forEach(([key, value], index) => {
      if (value == null || value === "") {

        value = null

      }

    });
    if (!isValidSet) {

      try {
        Object.entries(structuredData).forEach(([key, value], index) => {
          if (value == null || value === "") {
            value = null
          }
        });
        const response = await https.post(
          "/customer/store",
          {
            customer_fields: structuredData,
            customer_name: CustomersData.customer_name || "",
            customer_whatsapp_no: CustomersData.customer_whatsapp_no || "",
            customer_alternative_no: CustomersData.customer_alternative_no || "",
            customer_email: CustomersData.customer_email || "",
            customer_city: CustomersData.customer_city || "",
            customer_group: CustomersData.customer_group || "",
            customer_designation: CustomersData.customer_designation || "",
            customer_object: CustomersData.customer_object || "",
            customer_address: CustomersData.customer_address || "",
            customer_compnay_name: CustomersData.customer_compnay_name || "",
          },
          { headers: { "Content-Type": "application/json" } }
        );
       

        props.setModalStates(false);
        props.checkchang(response.data.message, 1);
      } catch (error) {
        console.error("API Call Error:", error.response?.data || error.message);
        toast.error("Failed to submit data. Please try again.");
      }
    } else {
      console.error("There are invalid fields.");

    }
   


  };

  const [FindData, SetFind] = useState([]);

  const [formmodalEdit, setformModalEdit] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const confirmDelete = async () => {
    try {
      const response = await https.delete(`/formdynamic/delete/${deleteId}`);
      toast.success("Field  deleted successfully.");
      setConfirmDeleteModal(false);
      formfiledList();
    } catch (error) {
      console.error("Error deleting Field :", error);
      toast.error("Failed to delete Field .");
      setConfirmDeleteModal(false);
    }
  };

  const EditUpdate = (ID) => {
    const FindArray = filedList.find((item) => item.formdynamic_id === ID);
    SetFind(FindArray);
    setformModalEdit(!formmodalEdit);
  };
  const HandleDelete = (formdynamic_id) => {
    setDeleteId(formdynamic_id);
    setConfirmDeleteModal(true);
  };

  const [modal, setModal] = useState(false);

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const { transcript, browserSupportsSpeechRecognition, resetTranscript } =
    useSpeechRecognition();
  const numberAndSymbolMapping = {
    zero: "0",
    one: "1",
    two: "2",
    three: "3",
    four: "4",
    five: "5",
    six: "6",
    seven: "7",
    eight: "8",
    nine: "9",
    ten: "10",
    dot: ".",
    comma: ",",
    dash: "-",
    plus: "+",
    at: "@",
    hashtag: "#",
    dollar: "$",
    percent: "%",
    ampersand: "&",
    asterisk: "*",
    exclamation: "!",
    question: "?",
    slash: "/",
    backslash: "\\",
    parenthesis: "()",
    brackets: "[]",
    brace: "{}",
    semicolon: ";",
    colon: ":",
    quote: '"',
    "single quote": "'",
    equal: "=",
    "greater than": ">",
    "less than": "<",
  };
  const convertSpokenNumbersToDigits = (text) => {
    return text
      .split(" ")
      .map((word) => numberAndSymbolMapping[word.toLowerCase()] || word)
      .join(" ");
  };
  const [isAddressListening, setIsAddressListening] = useState(false);
  useEffect(() => {
    if (isAddressListening && transcript) {
      const convertedTranscript = convertSpokenNumbersToDigits(transcript);
      setCustomersData((prevData) => ({
        ...prevData,
        customer_address: prevData.customer_address + " " + convertedTranscript,
      }));
    }
  }, [transcript, isAddressListening]);

  useEffect(() => {
    if (isAddressListening) {
      SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
    } else {
      SpeechRecognition.stopListening();
      resetTranscript();
    }
  }, [isAddressListening, resetTranscript]);

  if (!browserSupportsSpeechRecognition) {
    return <div>Your browser does not support speech recognition.</div>;
  }

  const handleAddressMicClick = () => {
    setIsAddressListening(!isAddressListening);
  };

  return (
    <div>
      <Modal id="showModal" size="xl" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Create Customer
        </ModalHeader>
        <div className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              OnSubmited();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success  p-3 shadow-lg">
                <Row className="3 row">
                  <Col lg={4}>
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      <div>
                        Full Name<span style={{ color: "red" }}> *</span>
                      </div>
                      {validStatus == 1 && <div style={{ color: "red" }}>Customer name is required</div>}
                    </Label>
                    <CustomInput
                      checkNameStatus={checkNameStatus}
                      onChange={getCustomerData}
                      name="customer_name"
                      id="customer_name"
                      className="form-control fw-bold"
                      placeholder="Full Name"
                      type="text"
                    />
                  </Col>
                  <Col lg={4}>
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      <div>
                        {" "}
                        WhatsApp Number
                        <span className="text-danger fw-bold"> *</span>
                      </div>
                      <div className="text-danger fw-bold">
                        {validStatus == 2 && <div style={{ color: "red" }}>Customer  WhatsApp Number  is required</div>}
                      </div>
                    </Label>

                    <Input
                      type="number"
                      value={
                        CustomersData ? CustomersData.customer_whatsapp_no : ""
                      }
                      onChange={(e) => {
                        setValidStatus(0);
                        const input = e.target.value.replace(/\D/g, "");

                        if (input.length <= 10) {
                          setCustomersData({
                            ...CustomersData,
                            customer_whatsapp_no: input,
                          });
                        }

                        if (
                          e.nativeEvent.inputType === "deleteContentBackward" &&
                          input.length === 0
                        ) {
                          setCustomersData({
                            ...CustomersData,
                            customer_whatsapp_no: "",
                          });
                        }
                      }}
                      className="fw-bold"
                      placeholder="WhatsApp Number"
                    />
                  </Col>
                  <Col lg={4}>
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold"
                    >
                      Alternative Mobile Number
                    </Label>
                    <Input
                      type="text"
                      value={
                        CustomersData
                          ? CustomersData.customer_alternative_no
                          : ""
                      }
                      onChange={(e) => {
                        const input = e.target.value.replace(/\D/g, "");

                        if (input.length <= 10) {
                          setCustomersData({
                            ...CustomersData,
                            customer_alternative_no: input,
                          });
                        }

                        if (
                          e.nativeEvent.inputType === "deleteContentBackward" &&
                          input.length === 0
                        ) {
                          setCustomersData({
                            ...CustomersData,
                            customer_alternative_no: "",
                          });
                        }
                      }}
                      className="fw-bold"
                      placeholder="Alternative Mobile Number"
                    />
                  </Col>
                  <Col lg={4}>
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold"
                    >
                      Email
                    </Label>
                    <Input
                      onChange={getCustomerData}
                      name="customer_email"
                      id="customer_email"
                      className="form-control fw-bold"
                      placeholder="Email"
                      type="text"
                    />
                  </Col>
                  <Col lg={4}>
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      City
                      {validStatus == 3 && <div style={{ color: "red" }}>Customer City is required</div>}
                      <a
                        className="btn btn-success btn-sm px-1"
                        style={{ padding: "0px" }}
                        onClick={() => setModalStatess1(!false)}
                      >
                        <i className="ri-add-line align-bottom"></i>
                      </a>
                    </Label>
                    {activeCity ? (
                      <Select
                        onChange={(selected) =>
                          setCustomersData((prev) => ({
                            ...prev,
                            customer_city: selected ? selected.value : 0,
                          }))
                        }
                        options={CustomerCity.map((group) => ({
                          value: group.city_id,
                          label: group.city_name,
                        }))}
                        name="customer_city"
                        id="customer_city"
                        className="fw-bold"
                        placeholder={manageCity === 0
                          ? CustomerCity[0].city_name
                          : CustomerCity[CustomerCity.length - 1].city_name}
                      />
                    ) : (
                      <Input
                        type="text"
                        readOnly
                        className="form-control fw-bold"
                        style={{ color: "red" }}
                        value="First Fill the City *"
                        placeholder=""
                      />
                    )}
                  </Col>

                  <Col lg={4}>
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      Group Type
                      <a
                        className="btn btn-success btn-sm px-1"
                        style={{ padding: "0px" }}
                        onClick={() => setModalStatess(!false)}
                      >
                        <i className="ri-add-line align-bottom"></i>
                      </a>
                    </Label>
                    {activeGroup ? (
                      <Select
                        onChange={getSelectedGroupValue}
                        options={CustomerGroup.map((group) => ({
                          value: group.customer_group_id,
                          label: group.customer_group_name,
                        }))}
                        name="customer_group"
                        id="customer_group"
                        className=" fw-bold"
                        placeholder={
                          manageGroup == 0
                            ? CustomerGroup[0].customer_group_name
                            : CustomerGroup[CustomerGroup.length - 1]
                              .customer_group_name
                        }
                      />
                    ) : (
                      <Input
                        type="text"
                        readOnly
                        className="form-control fw-bold "
                        style={{ color: "red" }}
                        value="First Fill the Customer Group *"
                        placeholder=""
                      />
                    )}
                  </Col>

                  {filedList
                    .filter((item) => item.formdynamic_type != 3)
                    .map((item, index) => (
                      <Col className=" mt-2  " lg={4} key={index}>
                        <div className="mb-3">
                          <Label
                            htmlFor={`Field-${item.formdynamic_id}`}
                            className="form-label fw-bold d-flex justify-content-between"
                          >
                            <div>
                              {item.formdynamic_name}

                              {(item.formdynamic_status == 2 || item.formdynamic_status == 4) && (
                                <span style={{ color: "red" }}> *</span>
                              )}
                            </div>
                            {valid == item.formdynamic_path ? (
                              <span className="text-danger">
                                {item.formdynamic_name} is required
                              </span>
                            ) : (
                              <div>
                                <button
                                  className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                  onClick={() => EditUpdate(item.formdynamic_id)}
                                >
                                  <i className="ri-pencil-fill fs-16" />
                                </button>
                                <button
                                  onClick={() => HandleDelete(item.formdynamic_id)}
                                  className="text-danger d-inline-block remove-item-btn border-0 bg-transparent"
                                >
                                  <i className="ri-delete-bin-5-fill fs-16" />
                                </button>
                              </div>
                            )}
                          </Label>


                          {item.formdynamic_type == 1 && (
                            <Input
                              placeholder="Enter Data"
                              className="form-control"
                              value={fieldValues[item.formdynamic_id] || ""}
                              onChange={(e) => {
                                handleFieldChange(e, item.formdynamic_id)
                                setValid(null)
                              }}
                            />
                          )}

                          {item.formdynamic_type == 2 && (
                            <Flatpickr
                              placeholder="Select a date"
                              className="form-control fw-bold"
                              value={fieldValues[item.formdynamic_id] || ""}
                              onChange={(date) => {
                                setValid(null)
                                setFieldValues((prevState) => ({
                                  ...prevState,
                                  [item.formdynamic_id]: date[0],

                                }));
                              }}
                            />
                          )}
                        </div>
                      </Col>
                    ))}


                  {filedList
                    .filter((item) => item.formdynamic_type == 3)
                    .map((item, index) => (
                      <Col className="mt-2" lg={12} key={index}>
                        <div className="">
                          <Label
                            htmlFor={`Field-${item.formdynamic_id}`}
                            className="form-label fw-bold d-flex justify-content-between"
                          >
                            <div>
                              {item.formdynamic_name}
                              {(item.formdynamic_status == 2 || item.formdynamic_status == 4) && (
                                <span style={{ color: "red" }}> *</span>
                              )}
                            </div>
                            <div>
                              {valid == item.formdynamic_path ? (
                                <span className="text-danger">
                                  {item.formdynamic_name} is required
                                </span>
                              ) : (
                                <div>
                                  <button
                                    className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                    onClick={() => EditUpdate(item.formdynamic_id)}
                                  >
                                    <i className="ri-pencil-fill fs-16" />
                                  </button>
                                  <button
                                    onClick={() => HandleDelete(item.formdynamic_id)}
                                    className="text-danger d-inline-block remove-item-btn border-0 bg-transparent"
                                  >
                                    <i className="ri-delete-bin-5-fill fs-16" />
                                  </button>
                                </div>
                              )}
                            </div>

                          </Label>
                          <CKEditor

                            editor={ClassicEditor}
                            data={fieldValues[item.formdynamic_id] || ""}
                            onChange={(event, editor) => {
                              setValid(null)
                              setFieldValues((prevState) => ({
                                ...prevState,
                                [item.formdynamic_id]: editor.getData(),
                              }));
                            }}
                          />
                        </div>
                      </Col>
                    ))}


                  <Col lg={12}>
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-3 fw-bold"
                    >
                      Address
                    </Label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={CustomersData.customer_address || ""}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        getCustomerData({
                          target: { name: "customer_address", value: data },
                        });
                      }}
                      config={{
                        placeholder: "Enter address",
                        toolbar: ["bold", "italic", "bulletedList", "numberedList", "undo", "redo"],
                      }}
                    />


                    <Button
                      type="button"
                      color="none"
                      style={{
                        position: "absolute",
                        top: "70%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        width: "50px",
                        height: "50px",
                        padding: "0",
                        borderRadius: "50%",
                        zIndex: "10",
                        background: "none",
                        border: "none",
                        boxShadow: "none",
                      }}
                      onClick={handleAddressMicClick}
                    >
                      <i
                        className={
                          isAddressListening
                            ? "ri-stop-circle-fill align-bottom"
                            : "ri-mic-fill align-bottom"
                        }
                      ></i>
                    </Button>
                  </Col>

                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <div className="hstack gap-2 justify-content-end">
                <div>
                  <button
                    type="button"
                    className="btn fw-bold btn-success add-btn -3"
                    id="create-btn"
                    onClick={() => setformModalStates(!formmodalStates)}
                  >
                    <i className="ri-add-line align-bottom me-1"></i> Add filed
                  </button>
                </div>
                {activeGroup ? (
                  <button
                    type="submit"
                    name="sumbit"
                    id="submit"
                    className="btn btn-primary"
                    ref={submitButtonRef}
                    disabled={loading}
                  >
                    <i className="ri-save-3-line align-bottom me-1"></i>
                    {loading ? "Saving..." : "Save"}
                  </button>
                ) : (
                  <span style={{ color: "red" }}>
                    First fill basic information then save.
                  </span>
                )}
                <Button
                  name="close"
                  id="close"
                  type="button"
                  className="btn btn-danger"
                  onClick={() => Close()}
                >
                  <i className="ri-close-line me-1 align-middle" />
                  Close
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      {formmodalStates === true ? (
        <FormFiledAdd
          modalStates={formmodalStates}
          setModalStates={() => {
            setformModalStates(false);
          }}
          status={2}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {modalStatess === true ? (
        <CustomerGroupAdd
          modalStates={modalStatess}
          setModalStates={() => {
            setModalStatess(false);
          }}
          checkchang={handleCallback}
        />
      ) : modalStatess1 === true ? (
        <CityAdd
          modalStates={modalStatess}
          setModalStates={() => {
            setModalStatess1(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {formmodalEdit === true ? (
        <FormFiledEdit
          modalStates={formmodalEdit}
          formmodalEdit={() => {
            setformModalEdit(!formmodalEdit);
          }}
          status={2}
          checkchang={handleCallback}
          edit_data={FindData}
        />
      ) : (
        ""
      )}
      <Modal
        isOpen={confirmDeleteModal}
        toggle={() => setConfirmDeleteModal(!confirmDeleteModal)}
        centered
      >
        <ModalBody className="text-center mt-4">
          <h4 className="fw-bold">Are you sure?</h4>
          <h5>
            Deleting this field will result in a permanent loss of associated data.
            Are you sure you want to proceed with deleting this filed?
          </h5>
        </ModalBody>

        <ModalFooter className="d-flex justify-content-center">
          <Button color="danger" onClick={confirmDelete}>
            Yes, Delete It!
          </Button>
          <Button
            color="secondary"
            onClick={() => setConfirmDeleteModal(!confirmDeleteModal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CustomerAdd;
