import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Flatpickr from "react-flatpickr";

import { toast } from "react-toastify";
import CustomInput from "../../Unit/Input";
import { useRef } from "react";
import Select from "react-select";
import AuthUser from "../../../helpers/Authuser";
import ShiftAdd from "../../Shift Slots/ShiftAdd";
import CityAdd from "../../City/CityAdd";
import RoleCreateModal from "../Roles/RoleCreateModal";
import { IMG_API_URL } from "../../../helpers/url_helper";

const UserCreateModal = (props) => {
  const [modal, setModal] = useState(false);
  const [rolesList, setRolesList] = useState([]);
  const { https, http } = AuthUser();
  const [modalStatess1, setModalStatess1] = useState(false);
  const [modalStatess, setModalStatess] = useState(false);
  const [counts, Setcounts] = useState(1);
  const [ActiveShift, setActiveShift] = useState(false);
  const [activeRole, setActiveRole] = useState(false);
  const [EmployeeShift, setEmployeeShift] = useState([]);
  const [EmployeeRole, setEmployeeRole] = useState([]);
  const [ManageShift, setManageShift] = useState(0);
  const [ManageRole, setManageRole] = useState(0);
  const [employeeData, setemployeeData] = useState(props.edit_data || {});
  // console.log("employee data", employeeData);



  const [leaveTypes, setLeaveType] = useState([]);
  const [selectedLeaveType, setSelectedLeaveType] = useState([]);
  const [daysInput, setDaysInput] = useState({}); // Initialize days input state


  useEffect(() => {
    const fetchLeaveTypes = async () => {
      try {
        const response = await http.get("/user/lavetypelist");
        const data = response.data;

        console.log(" leave type data from API:", data);
        console.log("Employee Data:", employeeData);

        const filteredLeaveTypes = data.filter(
          (item) => item.employee_id === employeeData.uid
        );

        const assignedLeaveTypes = filteredLeaveTypes.map(
          (item) => item.leavetype_id
        );
        const assignedDays = {};
        filteredLeaveTypes.forEach((item) => {
          assignedDays[item.leavetype_id] = item.days_assigned;
        });

        setSelectedLeaveType(assignedLeaveTypes);
        setDaysInput(assignedDays);



        setLeaveType(filteredLeaveTypes);
      } catch (error) {
        console.error("Error fetching leave types:", error);
      }
    };

    if (employeeData.uid) {
      fetchLeaveTypes();
    }
  }, [employeeData.uid]);
  const fetchLeaveType = () => {
    http
      .get(`leavetype/list`)
      .then((response) => {
        if (Array.isArray(response.data)) {
          setLeaveType(response.data);
        } else {
          console.error("Invalid leave type data received from the server.");
          setLeaveType([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching leave types:", error);
        setLeaveType([]);
      });
  };

  useEffect(() => {
    fetchLeaveType();
  }, []);


  const handleLeaveTypeChange = (e, leavetype_id) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setSelectedLeaveType((prev) => [...prev, leavetype_id]);
    } else {
      setSelectedLeaveType((prev) =>
        prev.filter((id) => id !== leavetype_id)
      ); // Remove leave type ID if unchecked
      setDaysInput((prev) => {
        const updatedDays = { ...prev };
        delete updatedDays[leavetype_id];
        return updatedDays;
      });
    }
  };

  const handleDaysInputChange = (e, leavetype_id) => {
    const value = e.target.value;
    setDaysInput((prev) => ({
      ...prev,
      [leavetype_id]: value,
    }));
  };

  // console.log(EmployeeShift);
  const handleCallback = (data) => {
    Setcounts(counts);
    setManageRole(1);
    setManageShift(1);
    toast.success(data);
    setModalStatess(false);
    setModalStatess1(false);
  };
  const [EmployeeAdhar, SetEmployeeAdhar] = useState("");
  const [EmployeeProfile, SetEmployeeProfile] = useState("");
  const [EmployeeDoc, SetEmployeeDoc] = useState("");
  const [EmployeeAudio, SetEmployeeAudio] = useState("");
  const [EmployeeVideo, SetEmployeeVideo] = useState("");
  const [EmployeePan, SetEmployeePan] = useState("");
  const [EmployeeQrCode, SetEmployeeQrCode] = useState("");



  // console.log(employeeData);
  // console.log(ManageShift);

  useEffect(() => {

    http
      .get("/role/list")
      .then(function (response) {
        if (response.data.length == 0) {
          setActiveRole(false);
        } else {
          setEmployeeRole(response.data);
          // setActiveRole(true);
          setemployeeData(() => ({


            ...employeeData,
            user_role_id: ManageRole != 0 ? response.data[response.data.length - 1].role_id : employeeData.role_name

          }));
        }
      })
      .catch(function (error) {
        console.log(error);
      });


    http
      .get("/shift/list")
      .then(function (response) {
        // console.log(response.data);
        setEmployeeShift(response.data);
        // setActiveShift(true);
        setemployeeData(() => ({
          ...employeeData,
          shift_id: ManageShift != 0 ? response.data[response.data.length - 1].shift_id : employeeData.shift_name

        }));

      })
      .catch(function (error) {
        console.log(error);
      });
  }, [counts]);

  const getSelectedCityValue = (e) => {
    setemployeeData({ ...employeeData, shift_id: e.value });
  };
  const getSelectedRoleValue = (e) => {
    setemployeeData({ ...employeeData, user_role_id: e.value });
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStatess1]);
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStatess]);

  // console.log(employeeData);
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };


  //   http
  //     .get("/city/list")
  //     .then(function (response) {
  //       if (response.data.length == 0) {
  //         setActiveShift(false);
  //       } else {
  //         setEmployeeShift(response.data);
  //         setActiveShift(true);
  //         setCustomersData(() => ({
  //           ...CustomersData,
  //           customer_city:
  //             ManageShift == 0
  //               ? response.data[0].city_id
  //               : response.data[response.data.length - 1].city_id,
  //         }));
  //       }
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }, [counts + 1]);
  // useEffect(() => {
  //   setModal(false);
  //   // getRolesList();
  //   toggle();
  // }, [props.modalStates]);

  // GETTING ROLES LIST
  const getRolesList = async () => {
    try {
      const apiResponse = await https.get("/role/list");
      setRolesList(apiResponse.data);
    } catch (error) {
      console.log(error);
    }
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [checkNameStatus, setCheckStatus] = useState({});


  const [msg, setMsg] = useState("");


  const SubmitData = () => {
    if (employeeData.employee_name === "") {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Employee cannot be empty!");
    } else {
      // console.log(employeeData);
      https
        .put("/user/update", employeeData)
        .then(function (response) {
          // console.log(response.data);
          if (response.data.status === 1) {
            toast.error(response.data.message);
          } else {
            props.checkchang(response.data.message);
          }
        })
        .catch(function (error) {
          console.log("Error", error);
        });
    }
  };


  const handleEmployee = (e) => {
    setCheckStatus({});
    setMsg("");
    setemployeeData({ ...employeeData, [e.target.name]: e.target.value });
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <Modal id="showModal" size="xl" isOpen={modal} toggle={toggle} style={{ marginTop: '5px', paddingTop: '10px' }} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Employee
        </ModalHeader>
        <span className="tablelist-form">
          <ModalBody>
            <Card className="border card-border-success p-3 shadow-lg">
              <div className="mb-3">
                <Row>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Employee Name <span className="text-danger">*</span>{" "}
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      checkNameStatus={checkNameStatus}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Employee Name"
                      type="text"
                      name="name"
                      value={employeeData.name}
                      onChange={handleEmployee}
                      readOnly
                    />
                  </Col>

                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      Employee Mobile
                    </Label>
                    <Input
                      value={employeeData.mobile_no}
                      id="mobile_no"
                      name="mobile_no"
                      className="form-control fw-bold"
                      placeholder="Mobile Number"
                      type="number"
                      onChange={handleEmployee}
                      readOnly
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>Employee Emergency Mob no</div>
                    </Label>
                    <Input
                      value={employeeData.emergency_contact}
                      name="emergency_contact"
                      id="emergency_contact"
                      onChange={(e) => handleEmployee(e)}
                      className="form-control fw-bold"
                      placeholder="Emergency "
                      type="text"
                      readOnly
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>Employee Email</div>
                    </Label>
                    <Input
                      value={employeeData.email}
                      name="email"
                      id="email"
                      onChange={(e) => handleEmployee(e)}
                      className="form-control fw-bold"
                      placeholder="Email address"
                      type="text"
                      readOnly
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>Employee Salary</div>
                    </Label>
                    <Input
                      value={employeeData.salary}
                      name="salary"
                      id="salary"
                      onChange={(e) => handleEmployee(e)}
                      className="form-control fw-bold"
                      placeholder="Salary"
                      type="text"
                      readOnly
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>Employee Date of Joining</div>
                    </Label>
                    <Flatpickr
                      className="form-control fw-bold"
                      options={{
                        dateFormat: "d/m/Y",
                        clickOpens: false, // Prevents Flatpickr from opening on click
                        defaultDate: employeeData.date_of_joining,
                      }}
                      name="payment_date"
                      placeholder="Date of joining"
                     
                    />

                  </Col>

                  <Col lg={3}>
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      Employee Shift
                      {/* <button
                        className="btn btn-success btn-sm px-1"
                        style={{ padding: "0px" }}
                        onClick={() => setModalStatess1(!false)}
                      >
                        <i className="ri-add-line align-bottom"></i>
                      </button> */}
                    </Label>
                    <Select
                      placeholder={
                        ManageShift == 0
                          ? employeeData.shift_name
                          : EmployeeRole[EmployeeRole.length - 1].shift_name
                      }
                      options={EmployeeShift.map((item) => ({
                        value: item.shift_id,
                        label: item.shift_name,
                      }))}
                      name="shift_id"
                      id="shift_id"
                      className="fw-bold"
                      isDisabled // Disables the select box completely
                    />

                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      Employee Role
                      {/* <button
                        className="btn btn-success btn-sm px-1"
                        style={{ padding: "0px" }}
                        onClick={() => setModalStatess(!false)}
                      >
                        <i className="ri-add-line align-bottom"></i>
                      </button> */}
                    </Label>
                    <Select
                      placeholder={ManageRole == 0 ? employeeData.role_name : EmployeeRole[EmployeeRole.length - 1].role_name}
                      // value={employeeData.role_id}
                      onChange={getSelectedRoleValue}
                      options={EmployeeRole.map((item) => ({ value: item.role_id, label: item.role_name }))}
                      name="user_role_id"
                      id="user_role_id"
                      className="fw-bold"
                      isDisabled // Disables the select box completely
                      
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Last Experience
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      value={employeeData.last_experience}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Employee last_experience"
                      type="text"
                      name="last_experience"
                      onChange={handleEmployee}
                      readOnly
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Last Working Company
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      value={employeeData.last_working_company}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Employee last_working_company"
                      type="text"
                      name="last_working_company"
                      onChange={handleEmployee}
                      readOnly
                    />
                  </Col>
                  <Col lg={3}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Last Company Salary
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      value={employeeData.last_company_salary}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Employee last_company_salary"
                      type="text"
                      name="last_company_salary"
                      onChange={handleEmployee}
                      readOnly
                    />
                  </Col>
                  <Col lg={4}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Aadhar No
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      value={employeeData.aadhar_no}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Employee Name"
                      type="text"
                      name="aadhar_no"
                      onChange={handleEmployee}
                      readOnly
                    />
                  </Col>
                  <Col lg={4}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        Pan No
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      value={employeeData.pan_no}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Employee Name"
                      type="text"
                      name="pan_no"
                      onChange={handleEmployee}
                      readOnly
                    />
                  </Col>
                  <Col lg={4}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      <div>
                        User Upi
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <Input
                      value={employeeData.user_upi}
                      id="role-name-field"
                      className="form-control fw-bold"
                      placeholder="Employee Name"
                      type="text"
                      name="user_upi"
                      onChange={handleEmployee}
                      readOnly
                    />
                  </Col>
                  <Col lg={6}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between  mt-3"
                    >
                      Employee Address
                    </Label>
                    <textarea
                      value={employeeData.address}
                      name="address"
                      id="address"
                      placeholder="Employee address"
                      className="form-control"
                      onChange={handleEmployee}
                      readOnly
                    ></textarea>
                  </Col>
                  <Col lg={6}>
                    <Label
                      htmlFor="categoryname-field"
                      className="form-label fw-bold d-flex justify-content-between mt-3"
                    >
                      <div>Leave-Type</div>
                    </Label>
                        <div className="row">
                    {selectedLeaveType.length > 0 ? (
                      selectedLeaveType.map((leaveTypeId) => {
                        const leaveType = leaveTypes.find((item) => item.leavetype_id === leaveTypeId);
                        return leaveType ? (
                          <div key={leaveType.leavetype_id} className="col-6 form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`leaveType-${leaveType.leavetype_id}`}
                              value={leaveType.leavetype_id}
                              checked={true}
                              readOnly
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`leaveType-${leaveType.leavetype_id}`}
                            >
                              {leaveType.leavetype_name}
                            </label>

                            <input
                              type="number"
                              className="form-control mt-2"
                              placeholder="Add Day's"
                              value={leaveType.days}
                              onChange={(e) => handleDaysInputChange(e, leaveType.leavetype_id)}
                              readOnly
                            />
                          </div>
                        ) : null;
                      })
                    ) : (
                      <p>No leave types assigned to this employee.</p>
                    )}
                    </div>
                  </Col>




                  <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Adhar Image</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="employeeAdhar"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Image"
                            >
                            
                            </label>
                           
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeAdhar ? (
                                <img
                                  src={URL.createObjectURL(EmployeeAdhar)}
                                  id="product-img"
                                  alt="product-img"
                                  className="h-auto"
                                  width={"100px"}
                                />
                              ) : employeeData.adhaar_photo !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.aadhar_photo}
                                  id="product-img"
                                  alt="product-img"
                                  className="h-auto"
                                  width={"100px"}
                                />
                              ) : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Pan Image</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="employeePan"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Image"
                            >
                              
                            </label>
                            
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeePan ? (
                                <img
                                  src={URL.createObjectURL(EmployeePan)}
                                  id="product-img"
                                  alt="product-img"
                                  className="h-auto"
                                  width={"100px"}
                                />
                              ) : employeeData.pan_photo !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.pan_photo}
                                  id="product-img"
                                  alt="product-img"
                                  className="h-auto"
                                  width={"100px"}
                                />
                              ) : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Profile</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="empoyeeProfile"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Image"
                            >
                             
                            </label>
                            
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeProfile ? (
                                <img
                                  src={URL.createObjectURL(EmployeeProfile)}
                                  id="product-img"
                                  alt="product-img"
                                  className="h-auto"
                                  width={"100px"}
                                />
                              ) : employeeData.profile_photo !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.profile_photo}
                                  id="product-img"
                                  alt="product-img"
                                  className="h-auto"
                                  width={"100px"}
                                />
                              ) : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Qr Code</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="employeeQrCode"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Image"
                            >
                            </label>
                            
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeQrCode ? (
                                <img
                                  src={URL.createObjectURL(EmployeeQrCode)}
                                  id="product-img"
                                  alt="product-img"
                                  className="h-auto"
                                  width={"100px"}
                                />
                              ) : employeeData.bank_passbook_photo !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.bank_passbook_photo}
                                  id="product-img"
                                  alt="product-img"
                                  className="h-auto"
                                  width={"100px"}
                                />
                              ) : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  {/* <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Document</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="empoyeeProfile"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Image"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-image-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              className="form-control d-none"
                              id="empoyeeProfile"
                              type="file"
                              accept=".pdf,.doc,.docx,.txt,.xlsx"
                              onChange={(e) => {
                                setemployeeData({
                                  ...employeeData,
                                  document: e.target.files[0],
                                });
                                SetEmployeeDoc(e.target.files[0]);
                              }}
                              readOnly
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeDoc ? (
                                <img
                                  src={URL.createObjectURL(EmployeeDoc)}
                                  id="product-img"
                                  alt="product-img"
                                  className="h-auto"
                                  width={"100px"}
                                />
                              ) : employeeData.document !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.document}
                                  id="product-img"
                                  alt="product-img"
                                  className="h-auto"
                                  width={"100px"}
                                />
                              ) : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col> */}
                  {/* <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Audio</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="empoyeeAudio"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Image"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-image-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              className="form-control d-none"
                              id="empoyeeAudio"
                              type="file"
                              accept=".mp3,.aac,.wav,.flac,.mpeg"
                              onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    audio: e.target.files[0],
                                  });
                                  SetEmployeeAudio(e.target.files[0]);
                                }}
                              readOnly
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeAudio ? (
                                <img
                                  src={URL.createObjectURL(EmployeeAudio)}
                                  id="product-img"
                                  alt="product-img"
                                  className="h-auto"
                                  width={"100px"}
                                />
                              ) : employeeData.audio !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.audio}
                                  id="product-img"
                                  alt="product-img"
                                  className="h-auto"
                                  width={"100px"}
                                />
                              ) : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col> */}
                  {/* <Col lg={3}>
                    <div className="mb-4 mt-3">
                      <h5 className="fs-15 mb-1">Video</h5>
                      <div className="text-center">
                        <div className="position-relative d-inline-block">
                          <div className="position-absolute top-100 start-100 translate-middle">
                            <label
                              htmlFor="empoyeeVideo"
                              className="mb-0"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title=""
                              data-bs-original-title="Select Image"
                            >
                              <div className="avatar-xs">
                                <div className="avatar-title bg-light border rounded-circle text-muted cursor-pointer">
                                  <i className="ri-image-fill"></i>
                                </div>
                              </div>
                            </label>
                            <input
                              className="form-control d-none"
                              id="empoyeeVideo"
                              type="file"
                              accept="video/mp4, video/avi, video/mkv"
                                onChange={(e) => {
                                  setemployeeData({
                                    ...employeeData,
                                    video: e.target.files[0],
                                  });
                                  SetEmployeeVideo(e.target.files[0]);
                                }}
                              readOnly
                            />
                          </div>
                          <div className="avatar-lg">
                            <div className="avatar-title bg-light rounded">
                              {EmployeeVideo ? (
                                <img
                                  src={URL.createObjectURL(EmployeeVideo)}
                                  id="product-img"
                                  alt="product-img"
                                  className="h-auto"
                                  width={"100px"}
                                />
                              ) : employeeData.video !== "/user/null" ? (
                                <img
                                  src={IMG_API_URL + employeeData.video}
                                  id="product-img"
                                  alt="product-img"
                                  className="h-auto"
                                  width={"100px"}
                                />
                              ) : ("")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col> */}
                </Row>
              </div>
            </Card>
          </ModalBody>
          <div className="modal-footer">
            <button
              name="close"
              id="close"
              type="button"
              className="btn btn-danger"
              onClick={() => Close()}
            >
              <i className="ri-close-line me-1 align-middle" />
              Close
            </button>

          </div>
        </span>
      </Modal>

      {modalStatess1 === true ? (
        <ShiftAdd
          modalStates={modalStatess1}
          setModalStates={() => {
            setModalStatess1(false);
          }}
          checkchang={handleCallback}
        />
      ) : modalStatess === true ? (
        <RoleCreateModal
          modalStates={modalStatess}
          setModalStates={() => {
            setModalStatess(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default UserCreateModal;
