import React, { useEffect, useState } from "react";
import {
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    Row,
    Col,
    Button,
    Form,
    Input,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_blue.css";
import AuthUser from "../../helpers/Authuser";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast, ToastContainer } from "react-toastify";
import FormFiledAdd from "./FormFiledAdd";
import CustomInput from "../Unit/Input";
import FormFiledEdit from "./FormFiledEdit";

const CreateProduct = (props) => {
    const [formmodalStates, setformModalStates] = useState(false);
    const [formmodalEdit, setformModalEdit] = useState(false);
    const { http, https } = AuthUser();
    const [valid, setValid] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [filedList, setFiledList] = useState([]);
    const [filedListvalid, setFiledListvalid] = useState([]);
    const [checkNameStatus, setCheckStatus] = useState({});
    const [msg, setMsg] = useState("");
    const [Product, SetProduct] = useState("");
    const [deleteId, setDeleteId] = useState(null);
    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const [fieldValues, setFieldValues] = useState({});
    const handleFieldChange = (e, fieldId) => {
        setValid(null)
        const { value } = e.target;
        setFieldValues((prevState) => ({
            ...prevState,
            [fieldId]: value,
        }));
    };
    const Close = () => {
        props.setModalStates();
    };
    const SaveHandel = async (e) => {
        e.preventDefault();
        setSubmitted(true);


        setValid(null);


        const missingFields = filedList
            .filter(
                (field) =>
                    field.formdynamic_status === 1 && !fieldValues[field.formdynamic_id]
            )
            .map((field) => field.formdynamic_path);

        if (missingFields.length > 0) {
            console.error("Missing required fields:", missingFields.join(", "));
            setValid(missingFields[0]);
            return;
        }


        const structuredData = filedList.reduce((acc, field) => {
            let value = fieldValues[field.formdynamic_id] || "";
            if (!field.formdynamic_path || typeof field.formdynamic_path !== "string") {
                console.error(
                    `Invalid formdynamic_path for field: ${JSON.stringify(field)}`
                );
                return acc;
            }
            const keys = field.formdynamic_path.split(".");
            let currentLevel = acc;
            keys.forEach((key, index) => {
                if (index === keys.length - 1) {
                    currentLevel[key] = value;
                } else {
                    currentLevel[key] = currentLevel[key] || {};
                    currentLevel = currentLevel[key];
                }
            });

            return acc;
        }, {});

        if (Product.trim() === "") {
            setCheckStatus({
                borderColor: "red",
                borderStyle: "groove",
            });
            setMsg("Product name cannot be empty!");
            return;
        }

        let isValidSet = false;


        Object.entries(structuredData).forEach(([key, value], index) => {
            for (const temp of filedListvalid) {
                if (temp.formdynamic_path === key) {
                    if (value == null || value === "") {
                        if (!isValidSet) {
                            setValid(key);
                            isValidSet = true;
                        }
                        return;
                    }
                }
            }
        });

        Object.entries(structuredData).forEach(([key, value], index) => {
            if (value == null || value === "") {

                value = null

            }

        });


        if (!isValidSet) {

            try {
                Object.entries(structuredData).forEach(([key, value], index) => {
                    if (value == null || value === "") {
                        value = null
                    }
                });
                const response = await https.post(
                    "/product/store",
                    { product_fields: structuredData, product_name: Product },
                    { headers: { "Content-Type": "application/json" } }
                );
                props.setModalStates(false);
                props.checkchang(response.data.message, 1);
            } catch (error) {
                console.error("API Call Error:", error.response?.data || error.message);
                toast.error(
                    error.response?.data?.message || "Failed to save the product."
                );
            }
        } else {
            console.error("There are invalid fields.");

        }
    };






    const handleProduct = (e) => {
        setCheckStatus({});
        setMsg("");
        SetProduct(e.target.value);
    };

    const handleCallback = (data, status) => {
        if (status == 1) {
            toast.success(data);
            formfiledList();
        } else {
            toast.warn(data);
        }
    };
    const [formData, setFormData] = useState(() => {
        const safeFiledList = Array.isArray(filedList) ? filedList : [];
        return safeFiledList.reduce((acc, temp) => {
            acc[temp.formdynamic_path] = "";
            return acc;
        }, {});
    });
    useEffect(() => {
        if (Array.isArray(filedList)) {
            const initialData = filedList.reduce((acc, temp) => {
                acc[temp.formdynamic_path] = "";
                return acc;
            }, {});
            setFormData(initialData);
        }
    }, [filedList]);
    const formfiledList = () => {
        http
            .get("/formdynamic/list")
            .then((res) => {
                const filterData = res.data.filter(
                    (temp) => temp.formdynamic_category == 1
                );
                setFiledList(filterData);
                const filterDatavalid = res.data.filter(
                    (temp) => temp.formdynamic_category == 1 && temp.formdynamic_status == 2 || temp.formdynamic_status == 4
                );
                setFiledListvalid(filterDatavalid);
            })
            .catch((e) => {
                console.log(e);
            });
    };

    useEffect(() => {
        formfiledList();
    }, []);
    const [FindData, SetFind] = useState([]);

    const EditUpdate = (ID) => {
        const FindArray = filedList.find((item) => item.formdynamic_id === ID);
        SetFind(FindArray);
        setformModalEdit(!formmodalEdit);
    };
    const HandleDelete = (formdynamic_id) => {
        setDeleteId(formdynamic_id);
        setConfirmDeleteModal(true);
    };
    const confirmDelete = async () => {
        try {
            const response = await https.delete(`/formdynamic/delete/${deleteId}`);
            toast.success("Field  deleted successfully.");
            setConfirmDeleteModal(false);
            formfiledList();
        } catch (error) {
            console.error("Error deleting Field :", error);
            toast.error("Failed to delete Field .");
            setConfirmDeleteModal(false);
        }
    };
    return (
        <div>
            <Modal
                isOpen={props.modalStates}
                toggle={() => props.setModalStates(false)}
                centered
            >
                <ToastContainer limit={1} />
                <ModalHeader
                    className="bg-light p-3 d-flex justify-content-between"
                    toggle={() => props.setModalStates(false)}
                >
                    <div>Create Product</div>
                </ModalHeader>
                <Form action="#" onSubmit={SaveHandel}>
                    <ModalBody>
                        <Card className="border card-border-success p-3 shadow-lg">
                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label
                                            htmlFor="Productname-field"
                                            className="form-label fw-bold d-flex justify-content-between"
                                        >
                                            <div>
                                                Product Name<span style={{ color: "red" }}> *</span>
                                            </div>
                                            <div style={{ color: "red" }}>{msg}</div>
                                        </Label>
                                        <CustomInput
                                            checkNameStatus={checkNameStatus}
                                            name="product_name"
                                            id="Product-field"
                                            className="form-control fw-bold"
                                            placeholder="Product Name"
                                            type="text"
                                            value={Product}
                                            onChange={handleProduct}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                {filedList.map((item, index) => {
                                    const inputFieldMap = {
                                        1: (
                                            <Input
                                                placeholder="Enter product name"
                                                className="form-control"
                                                value={fieldValues[item.formdynamic_id] || ""}
                                                onChange={(e) => {
                                                    if (valid === index && item.formdynamic_status == 2 || item.formdynamic_status == 4) {
                                                        setValid(null);
                                                    }
                                                    handleFieldChange(e, item.formdynamic_id)
                                                }}
                                            />
                                        ),
                                        2: (
                                            <Flatpickr
                                                placeholder="Select a date"
                                                className="form-control fw-bold"
                                                value={fieldValues[item.formdynamic_id] || ""}
                                                onChange={(date) => {
                                                    if (valid === index && item.formdynamic_status == 2 || item.formdynamic_status == 4) {
                                                        setValid(null);
                                                    }
                                                    setFieldValues((prevState) => ({
                                                        ...prevState,
                                                        [item.formdynamic_id]: date[0],
                                                    }));
                                                }}
                                            />
                                        ),
                                        3: (
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={fieldValues[item.formdynamic_id] || ""}
                                                onChange={(event, editor) => {
                                                    if (valid === index && item.formdynamic_status == 2 || item.formdynamic_status == 4) {
                                                        setValid(null);
                                                    }
                                                    setFieldValues((prevState) => ({
                                                        ...prevState,
                                                        [item.formdynamic_id]: editor.getData(),
                                                    }));
                                                }}
                                            />
                                        ),
                                    };

                                    const inputField = inputFieldMap[item.formdynamic_type] || null;

                                    return (
                                        <Col lg={12} key={index}>
                                            <div className="mb-3">
                                                <Label
                                                    htmlFor={`${item.formdynamic_type}-field-${item.formdynamic_id}`}
                                                    className="form-label fw-bold d-flex justify-content-between"
                                                >
                                                    <div>
                                                        {item.formdynamic_name}
                                                        {(item.formdynamic_status == 2 || item.formdynamic_status == 4) && (
                                                            <span style={{ color: "red" }}> *</span>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {valid == item.formdynamic_path && (
                                                            <span className="text-danger">
                                                                {item.formdynamic_name} is required
                                                            </span>
                                                        )}
                                                    </div>
                                                    {valid == item.formdynamic_path ? "" : <div>
                                                        <button
                                                            className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                                            onClick={() => EditUpdate(item.formdynamic_id)}
                                                        >
                                                            <i className="ri-pencil-fill fs-16" />
                                                        </button>
                                                        <button
                                                            onClick={() => HandleDelete(item.formdynamic_id)}
                                                            className="text-danger d-inline-block remove-item-btn border-0 bg-transparent"
                                                        >
                                                            <i className="ri-delete-bin-5-fill fs-16" />
                                                        </button>
                                                    </div>}
                                                </Label>
                                                {inputField}
                                            </div>
                                        </Col>
                                    );
                                })}
                            </Row>

                        </Card>
                    </ModalBody>

                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn fw-bold btn-success"
                            onClick={() => setformModalStates(!formmodalStates)}
                        >
                            <i className="ri-add-line align-bottom me-1"></i> Add Field
                        </button>
                        <button
                            name="save"
                            id="save"
                            type="submit"
                            className="btn btn-primary"
                            onClick={SaveHandel}
                        >
                            <i className="ri-save-3-line align-bottom me-1"></i> Save
                        </button>
                        <button
                            name="close"
                            id="close"
                            type="button"
                            className="btn btn-danger"
                            onClick={Close}
                        >
                            <i className="ri-close-line me-1 align-middle" />
                            Close
                        </button>
                    </div>
                </Form>
            </Modal>
            {formmodalStates === true ? (
                <FormFiledAdd
                    modalStates={formmodalStates}
                    setModalStates={() => {
                        setformModalStates(false);
                    }}
                    status={1}
                    checkchang={handleCallback}
                />
            ) : (
                ""
            )}
            {formmodalEdit === true ? (
                <FormFiledEdit
                    modalStates={formmodalEdit}
                    formmodalEdit={() => {
                        setformModalEdit(false);
                    }}
                    status={1}
                    checkchang={handleCallback}
                    edit_data={FindData}
                />
            ) : (
                ""
            )}
            <Modal
                isOpen={confirmDeleteModal}
                toggle={() => setConfirmDeleteModal(!confirmDeleteModal)}
                centered
            >
                <ModalBody className="text-center mt-4">
                    <h4 className="fw-bold">Are you sure?</h4>
                    <h5>
                        Deleting this field will result in a permanent loss of associated data.
                        Are you sure you want to proceed with deleting this filed?
                    </h5>
                </ModalBody>
                <ModalFooter className="d-flex justify-content-center">
                    <Button color="danger" onClick={confirmDelete}>
                        Yes, Delete It!
                    </Button>
                    <Button
                        color="secondary"
                        onClick={() => setConfirmDeleteModal(!confirmDeleteModal)}
                    >
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default CreateProduct;
