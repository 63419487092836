import React, { useEffect, useState, useCallback, useRef } from "react";
import {
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Row,
    Col,
    Button,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../../helpers/Authuser";
import Select from "react-select";
import { toast } from "react-toastify";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import D_img from "../../D_img";
import { IMG_API_URL } from "../../../helpers/url_helper";
import Flatpickr from "react-flatpickr";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
const StatusHandle = (props) => {

    const { http, https, user } = AuthUser();
    const [modal, setModal] = useState(false);
    const [count, setCount] = useState(0);
    const [audioBlob, setAudioBlob] = useState(null);
    const [ReApplyData, setReApplyData] = useState(props.edit_data)
    const [taskData, setTask] = useState({
        description: "",
        status_change_by: user.user.uid,
        status_task_id: props.edit_data.task_id,
        status: props.status,
        image: [],
        status_date: new Date(),
        next_follow_up: props.edit_data.next_follow_up
    });
    const [ReApply, setReApply] = useState({
        description: "",
        status_change_by: user.user.uid,
        status_task_id: props.edit_data.task_id,
        image: [],
        next_follow_up: props.edit_data.next_follow_up
    });
    const addAudioElement = (blob) => {
        const uniqueFileName = `recording_${Date.now()}_${Math.floor(Math.random() * 10000)}.mp3`;

        // Create a new File object from the blob with the random file name
        const mp3File = new File([blob], uniqueFileName, { type: 'audio/mp3' });

        // Update the audio blob state and leadsData with the mp3 file
        setAudioBlob(mp3File);
        setTask({
            ...taskData,
            audio_file: mp3File // Add the mp3 file to leadsData
        });
    };

    const recorderControls = useAudioRecorder();
    // State for audio blob

    useEffect(() => {
        // console.log(leadsData);
        setModal(false);
        toggle();
    }, [props.modalStates]);

    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            props.setModalStates();
        } else {
            setModal(true);
        }
    }, [modal]);


    // shortcuts to save and close modal
    const submitButtonRef = useRef();
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.altKey && event.key === "Escape") {
                event.preventDefault();
                props.setModalStates(true);
            }
            if (
                (event.altKey && event.key === "c") ||
                (event.altKey && event.key === "C")
            ) {
                event.preventDefault();
                props.setModalStates();
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, [count]);





    const [manageStatus, setmanageStatus] = useState(0);
    const SubmitStatus = (e) => {
        console.log("Test Log ");

        e.preventDefault()
        if (taskData.description === "") {
            setmanageStatus(3);
        } else {
            https
                .post("/task_status/store", taskData)
                .then((res) => {
                    if (res.data.status === 1) {
                        setCount(count + 1);
                        props.checkchang(res.data.message, res.data.status, "status");
                        props.setModalStates(!modal);

                    } else {
                        toast.warn(res.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    const ReApplyStatus = (e) => {
        console.log("Test Log ");
        e.preventDefault()
        setReApplyData((prevData) => {
            const PendingStatus = StatusData.length > 0 ? StatusData[0].lead_status_id : 0;
            const updatedData = {
                ...prevData,
                task_redo_status: 1,
                status: PendingStatus,
            };
            https.put("/task/update", updatedData)
                .then((res) => {
                    if (res.data.status === 1) {
                        setCount(count + 1);

                        props.checkchang("Task Re-apply Successfully!", res.data.status, "status");
                        props.setModalStates(!modal);
                    } else {
                        toast.warn(res.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            return updatedData;
        });

        if (ReApply.description === "") {
            setmanageStatus(3);
        } else {
            https
                .post("/task_status/store", ReApply)
                .then((res) => {
                    if (res.data.status === 1) {
                        setCount(count + 1);
                        props.setModalStates(!modal);

                    } else {
                        toast.warn(res.data.message);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };
    useEffect(() => {

    }, [ReApplyData]);

    const [selectedImageNames, setSelectedImageNames] = useState([]); // State for storing selected image names
    const [selectedDocName, setSelectedDocName] = useState('');
    const [selectedVideoName, setSelectedVideoName] = useState('');

    const [selectedVideo, setSelectedVideo] = useState(null);

    const [selectedImages, setSelectedImages] = useState([]);

    const handleImageChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setSelectedImages(selectedFiles);
        setSelectedImageNames(selectedFiles.map(file => file.name));
        setTask({
            ...taskData,
            image: [...taskData.image, ...selectedFiles],
        });
    };


    const [StatusData, setStatusData] = useState([]);
    const statusData = async () => {
        http
            .get("/lead/list")
            .then((response) => {
                setStatusData(response.data);

            }).catch((err) => {
                console.log(err);

            })
    }
    const statusOptions = StatusData.filter((_, index) => index != 3 && index != 4).map((data, index) => {
        return {
            value: data.lead_status_id,
            label: data.lead_status_name
        }
    });

    const [isDescriptionListening, setIsDescriptionListening] = useState(false);


    const {
        transcript,
        resetTranscript,
        browserSupportsSpeechRecognition,
    } = useSpeechRecognition();

    // Mapping spoken numbers and symbols to digits and symbols
    const numberAndSymbolMapping = {
        zero: "0",
        one: "1",
        two: "2",
        three: "3",
        four: "4",
        five: "5",
        six: "6",
        seven: "7",
        eight: "8",
        nine: "9",
        dot: ".",
        comma: ",",
        dash: "-",
        plus: "+",
        at: "@",
        hashtag: "#",
        dollar: "$",
        percent: "%",
        ampersand: "&",
        asterisk: "*",
        exclamation: "!",
        question: "?",
        slash: "/",
        backslash: "\\",
    };

    // Convert spoken numbers and symbols to digits/symbols
    const convertSpokenNumbersToDigits = (text) => {
        return text
            .split(" ")
            .map((word) => numberAndSymbolMapping[word.toLowerCase()] || word)
            .join(" ");
    };

    // Update description field based on voice input
    useEffect(() => {
        if (isDescriptionListening && transcript) {
            const convertedTranscript = convertSpokenNumbersToDigits(transcript);
            setTask((prevData) => ({
                ...prevData,
                description: prevData.description + " " + convertedTranscript, // Append voice input to description
            }));
            resetTranscript(); // Clear transcript after appending
        }
    }, [transcript, isDescriptionListening]);

    // Toggle microphone button
    useEffect(() => {
        statusData();
        if (isDescriptionListening) {
            SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
        } else {
            SpeechRecognition.stopListening();
        }
    }, [isDescriptionListening]);

    // Handle microphone button click
    const handleDescriptionMicClick = () => {
        setIsDescriptionListening(!isDescriptionListening); // Toggle mic on/off
    };

    if (!browserSupportsSpeechRecognition) {
        return <div>Your browser does not support speech recognition.</div>;
    }

    console.log(taskData.status);
    console.log(statusOptions.filter((item) => item.value == taskData.status)[0]);


    return (
        <div>
            <Modal
                className="modal-xl"
                id="fullscreeexampleModal"
                isOpen={modal}
                size={"xl"}
                toggle={toggle}
                centered
            >
                <ModalHeader className="bg-light  p-3" toggle={toggle}>

                    <div className="fw-bold">
                        &nbsp;{props.edit_data.task_title}
                    </div>
                </ModalHeader>
                <span className="tablelist-form">
                    <ModalBody style={{ maxHeight: "100vh", overflowY: "auto" }}>
                        <Card className="border card-border-success p-3 shadow-lg">
                            <Row className="">
                                {props.follow == true ?
                                    <Col lg={12}>
                                        <Label
                                            htmlFor="categoryname-field"
                                            className="form-label fw-bold d-flex justify-content-between mt-3"
                                        >
                                            <div>
                                                Next Follow Up
                                            </div>
                                            {/* <div className="text-danger">
                                                {manageStatus == 3 ? "Description cannot be empty!" : ""}
                                            </div> */}
                                        </Label>
                                        <div>
                                            <Flatpickr
                                                className="form-control fw-bold"

                                                options={{
                                                    dateFormat: "d/m/Y h:i K",
                                                    enableTime: true,
                                                    minDate: new Date(),
                                                    disableMobile: true,
                                                    defaultDate: (() => {
                                                        const currentDate = new Date();
                                                        const utcOffset = currentDate.getTimezoneOffset() * 60 * 1000;
                                                        const ISTOffset = 5.5 * 60 * 60 * 1000;
                                                        const istDate = new Date(
                                                            currentDate.getTime() + ISTOffset + utcOffset
                                                        );

                                                        return istDate;
                                                    })(),
                                                }}
                                                onChange={(selectedDates) => {
                                                    const selectedDate = selectedDates[0];


                                                    const day = selectedDate.getDate().toString().padStart(2, "0");
                                                    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
                                                    const year = selectedDate.getFullYear();


                                                    const hours = selectedDate.getHours() % 12 || 12; // 12-hour format
                                                    const minutes = selectedDate.getMinutes().toString().padStart(2, "0");
                                                    const ampm = selectedDate.getHours() >= 12 ? "PM" : "AM"; // AM/PM indicator

                                                    // Format the date and time as "YYYY-MM-DD h:i AM/PM"
                                                    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

                                                    // Set the task data with both date and time
                                                    setTask({
                                                        ...taskData,
                                                        next_follow_up: formattedDateTime,
                                                    });
                                                }}
                                                name="date"
                                            />
                                        </div>
                                    </Col>
                                    : ""}
                                <Col lg={12}>
                                    <Label
                                        htmlFor="categoryname-field"
                                        className="form-label fw-bold d-flex justify-content-between mt-3"
                                    >
                                        <div>
                                            Description
                                        </div>
                                        <div className="text-danger">
                                            {manageStatus == 3 ? "Description cannot be empty!" : ""}
                                        </div>
                                    </Label>

                                    {/* Microphone Button */}
                                    <Button
                                        type="button"
                                        color="none"
                                        style={{
                                            position: "absolute",
                                            top: "60%",
                                            right: "10px",
                                            transform: "translateY(-50%)",
                                            width: "50px",
                                            height: "50px",
                                            padding: "0",
                                            borderRadius: "50%",
                                            zIndex: "10",
                                            background: "none", // No background
                                            border: "none", // No border
                                            boxShadow: "none", // No shadow
                                        }}
                                        onClick={handleDescriptionMicClick} // Toggle microphone on click
                                    >
                                        <i
                                            className={
                                                isDescriptionListening
                                                    ? "ri-stop-circle-fill align-bottom" // Icon when listening
                                                    : "ri-mic-fill align-bottom" // Icon when not listening
                                            }
                                        ></i>
                                    </Button>

                                    {/* Simple Textarea for description */}
                                    <textarea
                                        id="description"
                                        name="description"
                                        className="form-control"
                                        placeholder="Short description of the task"
                                        value={taskData.status !== 0 ? taskData.description : ReApply.description}

                                        onChange={(e) => {
                                            if (taskData.status !== 0) {

                                                setmanageStatus(0);
                                                setTask({
                                                    ...taskData,
                                                    description: e.target.value,
                                                });
                                            } else {

                                                setmanageStatus(0);
                                                setReApply({
                                                    ...ReApply,
                                                    description: e.target.value,
                                                });
                                            }
                                        }}

                                        style={{ height: "130px", resize: "none" }} // Adjust height and disable resize
                                    />
                                </Col>


                                <Col lg={12}>
                                    <div className="mt-5 text-center">
                                        <div className="d-flex justify-content-center align-items-center">
                                            {/* Image Upload */}
                                            <div className="position-relative mx-2">
                                                {/* Display selected images */}
                                                {selectedImages.length > 0 && (
                                                    <div className="image-preview d-flex mb-2">
                                                        {selectedImages.map((image, index) => (
                                                            <div key={index} className="me-2">
                                                                <img
                                                                    src={URL.createObjectURL(image)}
                                                                    alt={`Selected ${index}`}
                                                                    style={{
                                                                        width: '100px',
                                                                        height: '100px',
                                                                        objectFit: 'cover',
                                                                        borderRadius: '5px',
                                                                    }}
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}

                                                <label
                                                    htmlFor="taskImages"
                                                    className="mb-0 cursor-pointer"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="left"
                                                    title="Select Images"
                                                >
                                                    <div className="avatar-xs">
                                                        <div
                                                            className="avatar-title border rounded-circle text-light"
                                                            style={{ width: '40px', height: '40px', backgroundColor: 'transparent' }}
                                                        >
                                                            <i className="ri-image-fill" style={{ fontSize: '20px', color: '#000' }}></i>
                                                        </div>
                                                    </div>
                                                </label>

                                                <input
                                                    className="form-control d-none"
                                                    id="taskImages"
                                                    type="file"
                                                    accept="image/png, image/gif, image/jpeg"
                                                    multiple
                                                    onChange={handleImageChange}
                                                />
                                            </div>



                                            {/* Video Upload */}
                                            <div className="position-relative mx-2">
                                                {selectedVideo && (
                                                    <div className="mb-2">
                                                        <video
                                                            src={URL.createObjectURL(selectedVideo)}
                                                            id="employee-video"
                                                            height={"100px"}
                                                            width={"100px"}
                                                            controls
                                                        />
                                                    </div>
                                                )}
                                                <label
                                                    htmlFor="employeeVideo"
                                                    className="mb-0 cursor-pointer"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="right"
                                                    title="Select Video"
                                                >
                                                    <div className="avatar-xs">
                                                        <div
                                                            className="avatar-title border rounded-circle text-light"
                                                            style={{ width: "40px", height: "40px", backgroundColor: "transparent" }}
                                                        >
                                                            <i
                                                                className="ri-video-fill"
                                                                style={{ fontSize: "20px", color: "#000" }}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                </label>
                                                <input
                                                    className="form-control d-none"
                                                    id="employeeVideo"
                                                    type="file"
                                                    accept="video/mp4, video/avi, video/mkv"
                                                    onChange={(e) => {
                                                        const selectedFile = e.target.files[0];
                                                        setTask({ ...taskData, video: selectedFile });
                                                        setSelectedVideo(selectedFile);
                                                        setSelectedVideoName(selectedFile ? selectedFile.name : ''); // Update video name
                                                    }}
                                                />
                                                {selectedVideoName && (
                                                    <span className="ml-2">{selectedVideoName}</span>
                                                )}
                                            </div>

                                            {/* Document Upload */}
                                            <div className="position-relative mx-2">
                                                {selectedDocName && (
                                                    <div className="avatar-title bg-light rounded d-flex align-items-center mb-2">
                                                        <i className="ri-file-fill" style={{ fontSize: "30px", marginRight: "10px", color: "#000" }}></i>
                                                        <span style={{ color: "#000" }}>{selectedDocName.split("/").pop().substring(0, 15) + "..."}</span>
                                                    </div>
                                                )}
                                                <label
                                                    htmlFor="employeeDocument"
                                                    className="mb-0 cursor-pointer"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="right"
                                                    title="Select Document"
                                                >
                                                    <div className="avatar-xs">
                                                        <div
                                                            className="avatar-title border rounded-circle text-light"
                                                            style={{ width: '40px', height: '40px', backgroundColor: 'transparent' }}
                                                        >
                                                            <i className="ri-file-upload-fill" style={{ fontSize: '20px', color: '#000' }}></i>
                                                        </div>
                                                    </div>
                                                </label>
                                                <input
                                                    className="form-control d-none"
                                                    id="employeeDocument"
                                                    type="file"
                                                    accept=".pdf,.doc,.docx,.txt,.xlsx"
                                                    onChange={(e) => {
                                                        const selectedFile = e.target.files[0];
                                                        setTask({ ...taskData, document: selectedFile });
                                                        setSelectedDocName(selectedFile ? selectedFile.name : ''); // Update document name
                                                    }}
                                                />
                                            </div>

                                            {/* Audio Recorder */}
                                            <div className="position-relative mx-2">

                                                {audioBlob && (
                                                    <div className="avatar-title bg-light rounded d-flex align-items-center mb-2" style={{ marginTop: "50px" }}>
                                                        <i className="ri-file-fill" style={{ fontSize: "30px", marginRight: "10px", color: "#000" }}></i>
                                                        <span style={{ color: "#000" }}>
                                                            {audioBlob.name
                                                                ? audioBlob.name.split("/").pop().substring(0, 15) + "..."
                                                                : "Recorded Audio"}
                                                        </span>
                                                    </div>
                                                )}

                                                <label
                                                    htmlFor="audioRecorder"
                                                    className="mb-0 cursor-pointer"
                                                    data-bs-toggle="tooltip"
                                                    data-bs-placement="right"
                                                    title="Record Audio"
                                                >
                                                </label>


                                                <AudioRecorder onRecordingComplete={addAudioElement} recorderControls={recorderControls} />
                                            </div>


                                        </div>
                                    </div>
                                </Col>

                                <Col className="mt-4 text-center" lg={12}>
                                    {taskData.status != 0 ? <Button
                                        name="close"
                                        id="close"
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={(e) => SubmitStatus(e)}
                                    >
                                        <i className="ri-save-3-line align-bottom me-1"></i>
                                        Update Status
                                    </Button> :
                                        <Button
                                            name="close"
                                            id="close"
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={(e) => ReApplyStatus(e)}
                                        >
                                            <i className="ri-save-3-line align-bottom me-1"></i>
                                            Re-Open
                                        </Button>}
                                </Col>

                            </Row>
                        </Card>
                    </ModalBody>
                </span>
            </Modal>
        </div>
    );
};

export default StatusHandle;
