import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Form,
  Button,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import CustomInput from "../Unit/Input";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Flatpickr from "react-flatpickr";



const ProductEdit = (props) => {
  const [modal, setModal] = useState(false);
  const [Product, SetProduct] = useState(props.edit_data || {});
  const [ProductID] = useState(props.edit_data.product_id);
  const { https } = AuthUser();
  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");
  const [filedList, setFiledList] = useState([]);
  const [fieldValues, setFieldValues] = useState({});
  const [filedListName, setFiledListName] = useState([]);
  const [filedListNamevalid, setFiledListNamevalid] = useState([]);
  const [valid, setValid] = useState('');
  useEffect(() => {
    https
      .get("/formdynamic/list")
      .then((res) => {
        const filterData = res.data.filter(
          (temp) => temp.formdynamic_category == 1
        );
        setFiledListName(filterData);

        const filterDataValid = res.data.filter(
          (temp) => temp.formdynamic_category == 1 && temp.formdynamic_status == 2 || temp.formdynamic_status == 4
        );
        setFiledListNamevalid(filterDataValid);


      })
      .catch((e) => {
        console.log(e);
      });
  }, []); 

  useEffect(() => {
    if (props.edit_data.product_fields && filedListName.length > 0) {
      const parsedFields = JSON.parse(props.edit_data.product_fields);

      setFieldValues(parsedFields);
      setFiledList(
        Object.keys(parsedFields).map((key, index) => {
          const matchingField = filedListName.find(
            (field) => field.formdynamic_path === key
          );
          return {
            formdynamic_id: key,
            formdynamic_name: matchingField?.formdynamic_name || "NA",
            formdynamic_type: matchingField?.formdynamic_type || 1,
            formdynamic_status: matchingField?.formdynamic_status || 1,
          };
        })
      );
    }
  }, [props.edit_data, filedListName]); 


 
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  const handleFieldChange = (e, fieldId) => {
    const { value } = e.target;
    setFieldValues((prevState) => ({
      ...prevState,
      [fieldId]: value,
    }));
  }
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalState]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const SubmitData = () => {
    const updatedFields = JSON.stringify(fieldValues);

    if (!Product.product_name) {
      setMsg("Product Name cannot be empty!");
    } else {
      let isValidSet = false;
      Object.entries(fieldValues).forEach(([key, value], index) => {

        for (const temp of filedListNamevalid) {
          if (temp.formdynamic_path == key) {
            if (value == null || value === "") {
              if (!isValidSet) {

                setValid(key);
                isValidSet = true;
              }
              return; 
            }
          }
        }
      });
      if (!isValidSet) {
        https
          .put("/product/update", {
            product_id: ProductID,
            product_name: Product.product_name,
            product_fields: updatedFields,
          })
          .then((response) => {
            props.checkchang(response.data.message, response.data.status);
            toggle();
          })
          .catch((error) => console.log(error));
      }
    }
  };
  const handleProduct = (e) => {
    setCheckStatus({});
    setMsg("");
    SetProduct((prevState) => ({
      ...prevState,
      product_name: e.target.value,
    }));
  };

  return (
    <div>
      <Modal id="showModal" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Product Edit
        </ModalHeader>
        <span className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              SubmitData();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="Productyname-field"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Product Name<span style={{ color: "red" }}> *</span>
                        </div>
                        <div style={{ color: "red" }}>{msg}</div>
                      </Label>
                      <CustomInput
                        checkNameStatus={checkNameStatus}
                        name="Product"
                        id="Product-field"
                        className="form-control fw-bold"
                        placeholder="Product Name"
                        type="text"
                        value={Product.product_name}
                        onChange={handleProduct}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  {filedList.map((item) => (
                    <Col lg={12} key={item.formdynamic_id}>
                      <Label className="form-label fw-bold mt-2 d-flex justify-content-between">
                        <div>

                          {filedListName.filter((temp => temp.formdynamic_path == item.formdynamic_id)).map((data) => (

                            data.formdynamic_name
                          ))}
                          {(item.formdynamic_status == 2 || item.formdynamic_status == 4) && (
                            <span style={{ color: "red" }}>*</span>
                          )}
                        </div>
                        {valid === item.formdynamic_id && (
                          <span className="text-danger">
                            {item.formdynamic_name} is required
                          </span>
                        )}
                      </Label>
                      {item.formdynamic_type == 1 && (
                        <Input
                          type="text"
                          value={fieldValues[item.formdynamic_id] || ""}
                          onChange={(e) => handleFieldChange(e, item.formdynamic_id)}
                        />
                      )}
                      {item.formdynamic_type == 2 && (
                        <Flatpickr
                          className="form-control fw-bold "
                          value={fieldValues[item.formdynamic_id] || ""}
                          onChange={(date) =>
                            setFieldValues((prevState) => ({
                              ...prevState,
                              [item.formdynamic_id]: date[0],
                            }))
                          }
                        />
                      )}


                      {item.formdynamic_type == 3 && (
                        <CKEditor
                          editor={ClassicEditor}
                          data={fieldValues[item.formdynamic_id] || ""}
                          onChange={(event, editor) =>
                            setFieldValues((prevState) => ({
                              ...prevState,
                              [item.formdynamic_id]: editor.getData(),
                            }))
                          }
                        />
                      )}
                    </Col>
                  ))}
                </Row>

              </Card>
            </ModalBody>
            <div className="modal-footer">
              <button
                name="close"
                id="close"
                type="submit"
                className="btn btn-primary"
          
                
              >
                <i className="ri-save-3-line align-bottom me-1"></i>
                Update
              </button>
              <button
                name="close"
                id="close"
                type="button"
                className="btn btn-danger"
                onClick={() => Close()}
              >
                <i className="ri-close-line me-1 align-middle" />
                Close
              </button>
            </div>
          </Form>
        </span>
      </Modal>
    </div>
  );
};

export default ProductEdit;