import React, { useEffect, useRef, useState, useCallback } from "react";
import {
  Container,
  Button,
  UncontrolledTooltip,
  Input,
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  Row,
  Col,
  Card,
  CardBody,
  UncontrolledDropdown,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import 'jspdf-autotable';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Link } from "react-router-dom";
import { isEmpty, map } from "lodash";
import classnames from "classnames";
import SimpleBar from "simplebar-react";
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import AudioFileIcon from '@mui/icons-material/AudioFile';
// import VideoFileIcon from '@mui/icons-material/VideoFile';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import IconButton from '@mui/material/IconButton';
import io from 'socket.io-client';


//Import Icons
import FeatherIcon from "feather-icons-react";
import PersonalInfo from "./PersonalInfo";
import SpeechRecognition, { useSpeechRecognition, } from "react-speech-recognition";
import useClipboard from "react-use-clipboard";



import Picker from 'emoji-picker-react';

//redux
import { useSelector, useDispatch } from "react-redux";
import {
  getDirectContact as onGetDirectContact,
  getMessages,
  getChannels as onGetChannels,
  addMessage as onAddMessage,
  deleteMessage as onDeleteMessage
} from "../../store/chat/action";

import avatar2 from "../../assets/images/users/avatar-2.jpg";
import userDummayImage from "../../assets/images/users/user-dummy-img.jpg";

//Import Scrollbar
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { createSelector } from "reselect";
import AuthUser from "../../helpers/Authuser";
import ImgCom from "../../pages/Fullscreenimg/ImgCom";
import { IMG_API_URL } from "../../helpers/url_helper";
import D_img from "../../pages/D_img";


const Chat = () => {



  const { http, user, https } = AuthUser();
  console.log("user id", user.user.uid);

  const [chatImage, setChatImage] = useState(null);
  const [chatFile, setChatFile] = useState(null);
  const [chatAudio, setChatAudio] = useState(null);

  const [filePreviews, setFilePreviews] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const imageInputRef = useRef(null);
  const fileInputRef = useRef(null);
  const audioInputRef = useRef(null);
  const isImage = (fileName) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return imageExtensions.includes(fileExtension);
  };
  const isAudio = (fileName) => {
    const audioExtensions = ['mp3', 'aac', 'wav', 'flac', 'mpeg'];
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return audioExtensions.includes(fileExtension);
  };

  const isVideo = (fileName) => {
    const videoExtensions = ['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv'];
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return videoExtensions.includes(fileExtension);
  };


  const [message, setMessage] = useState({
    message_description: " ",
    sender_id: user.user.uid,
    receiver_id: "",

  });
  const [Images, SetImages] = useState("");
  const FILE_SIZE_LIMIT = 5 * 1024 * 1024; // 5 MB in bytes

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const file = files[0];
    if (!file) return;
    if (file) {
      if (file.size > FILE_SIZE_LIMIT) {
        alert("File size is too large. Maximum allowed size is 5 MB.");
        return;
      }
      if (event.target.accept.includes('image') || event.target.accept.includes('video')) {
        setChatImage(file);
      }
      else if (event.target.accept.includes('audio')) {
        setChatFile(file);
        const fileUrl = URL.createObjectURL(file);
        downloadFile(fileUrl, file.name);
        URL.revokeObjectURL(fileUrl);
      }
      else {
        setChatFile(file);
      }
      const previews = files.map(file => URL.createObjectURL(file));
      setFilePreviews(previews);
    }
  }


  const handleChange = (e) => {
    const newMessage = e.target.value;
    setCurMessage(newMessage);

    // Update the message object with the new text
    setMessage((prevState) => ({
      ...prevState,
      message_description: newMessage,
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSubmit(e);
    }
  };



  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("message_description", TextToCopy); // Use TextToCopy for combined text and emojis
    formData.append("sender_id", user.user.uid);
    formData.append("receiver_id", Chat_Box_Uid);

    if (chatImage) {
      formData.append("message_image", chatImage);
    } else if (chatFile) {
      formData.append("message_image", chatFile);
    } else if (chatAudio) {
      formData.append("message_audio", chatAudio);
    }

    https
      .post("/message/store", formData)
      .then((response) => {
        setMessage({ message_description: "" });
        setTextToCopy(""); // Clear the input box after submission
        setChatImage(null);
        setChatFile(null);
        setChatAudio(null);
        setFilePreviews([]);
        if (imageInputRef.current) {
          imageInputRef.current.value = "";
        }
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
        if (audioInputRef.current) {
          audioInputRef.current.value = "";
        }
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error Sending Message:", error);
      });
  };


  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const openImageInput = () => {
    if (imageInputRef.current) {
      imageInputRef.current.click();
    }
    setShowModal(false);
  };

  const openFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
    setShowModal(false);
  };
  const openAudioInput = () => {
    if (audioInputRef.current) { // Use audioInputRef here
      audioInputRef.current.click();
    }
    setShowModal(false);
  };





  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  const SOCKET_SERVER_URL = 'https://crmapi.oneclickbot.in/';
  // const SOCKET_SERVER_URL = 'http://localhost:8880/';
  const socket = io(SOCKET_SERVER_URL);
  useEffect(() => {
    // Listen for messages from the server
    socket.on('chatbox', (message) => {
      http
        .get("/message/list")
        .then((response) => {
          setMsg(response.data);
        })
        .catch((error) => {
          console.error("Error Fetching Message Data:", error);
        });
    });

    // Clean up the socket connection when the component is unmounted
    return () => {
      socket.disconnect();
    };
  }, [socket]);


  const deleteMessage = (messageId) => {
    http
      .delete(`/message/delete/${messageId}`)
      .then(() => {
        setMsg((prevMsg) =>
          Array.isArray(prevMsg)
            ? prevMsg.filter((message) => message.message_id !== messageId)
            : []
        );
      })
      .catch((error) => {
        console.error("Error deleting Message:", error);
      });
  };

  const [userData, setUserData] = useState([]);
  const [unreadCounts, setUnreadCounts] = useState({});
  const fetchUser = () => {
    http
      .get("/user/list-list")
      .then((response) => {
        setUserData(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        console.error("Error Fetching User Data:", error);
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const [msg, setMsg] = useState([]);
  const fetchMsg = async () => {
    try {
      const response = await http.get("/message/list");
      setMsg(response.data);
      // Calculate unread message counts
      const counts = {};
      response.data.forEach((message) => {
        if (
          message.receiver_id === user.user.uid &&
          !message.read &&
          message.message_status === 1
        ) {
          counts[message.sender_id] = (counts[message.sender_id] || 0) + 1;
        }
      });

      setUnreadCounts(counts);
    } catch (error) {
      console.error("Error Fetching Message Data:", error);
    }
  };
  useEffect(() => {
    fetchMsg();
  }, []);

  const filteredUserData = userData.filter(user =>
    msg.some(message => user.uid === message.sender_id || user.uid === message.receiver_id)
  );


  const downloadFile = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);
      if (!response.ok) throw new Error("Failed to fetch file");

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const generatePDF = async (fileUrl, fileName) => {
    try {
      const supportedFileTypes = [
        ".jpg", ".jpeg", ".png", ".gif",
        ".pdf", ".doc", ".docx", ".xlsx",
        ".txt", ".mp3", ".aac", ".wav", ".flac", ".mpeg",
        ".mp4", ".avi", ".mkv" // Add video formats
      ];

      if (supportedFileTypes.some(type => fileName.endsWith(type))) {
        // Download the file directly
        await downloadFile(fileUrl, fileName);
      } else {
        console.error("Unsupported file type:", fileName);
        alert("Unsupported file type. Please select a valid file.");
      }
    } catch (error) {
      console.error("Error handling the file:", error);
    }
  };




  const formatToIST = (utcDateString) => {
    const utcDate = new Date(utcDateString);
    const istOffset = 5.5 * 60 * 60 * 1000; // IST is +5:30 from UTC
    const istDate = new Date(utcDate.getTime() + istOffset);

    let hours = istDate.getUTCHours();
    const minutes = istDate.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;

    return `${hours}:${minutesStr} ${ampm}`;
  };

  const ref = useRef();
  const dispatch = useDispatch();
  const [isInfoDetails, setIsInfoDetails] = useState(false);
  const [Chat_Box_Username, setChat_Box_Username] = useState("");
  console.log("name",Chat_Box_Username);

  const [Chat_Box_mobile, setChat_Box_mobile] = useState("");
  console.log("mobile",Chat_Box_mobile);

  const [Chat_Box_email, setChat_Box_email] = useState("");
  console.log("email",Chat_Box_email);

  const [Chat_Box_profile, setChat_Box_profile] = useState("");
  console.log("profile",Chat_Box_profile);

  const [Chat_Box_address, setChat_Box_address] = useState("");
  console.log("Address",Chat_Box_address);

  const [Chat_Box_Uid, setChat_Box_Uid] = useState("");
  const [Chat_Box_Image, setChat_Box_Image] = useState(avatar2);
  const [currentRoomId, setCurrentRoomId] = useState(1);
  const [curMessage, setCurMessage] = useState("");
  const [search_Menu, setsearch_Menu] = useState(false);
  const [settings_Menu, setsettings_Menu] = useState(false);
  const [messageBox, setMessageBox] = useState(null);
  const [reply, setreply] = useState("");
  const [emojiPicker, setEmojiPicker] = useState(false);
  const [currentUser, setCurrentUser] = useState({
    // name: "Anna Adame",
    // isActive: true,
  });
  

  const selectLayoutState = (state) => state.chat;
  const selectLayoutProperties = createSelector(
    selectLayoutState,
    (msg) => ({
      // chats: msg.chats,
      // messages: msg.messages,
      // channels  : msg.channels  ,

    })
  );

  // Inside your component
  const {
    chats,
    messages,
    channels,
  } = useSelector(selectLayoutProperties);
  //Toggle Chat Box Menus
  const toggleSearch = () => {
    setsearch_Menu(!search_Menu);
  };

  //Info details offcanvas
  const toggleInfo = () => {
    setIsInfoDetails(!isInfoDetails);
  };

  const toggleSettings = () => {
    setsettings_Menu(!settings_Menu);
  };
  useEffect(() => {
    dispatch(onGetDirectContact());
    dispatch(onGetChannels());
    dispatch(getMessages(currentRoomId));
  }, [dispatch, currentRoomId]);


  const userChatOpen = async (uid, name,mobile_no,email,address,profile_photo, status, roomId, image, messageId) => {
    setChat_Box_Uid(uid);
    setChat_Box_Username(name);
    setChat_Box_mobile(mobile_no);
    setChat_Box_email(email);
    setChat_Box_profile(profile_photo);
    setChat_Box_address(address);
    setCurrentRoomId(roomId);
    setChat_Box_Image(image);
    dispatch(getMessages(roomId));

    // Clear unread messages count for this user
    setUnreadCounts(prevCounts => ({ ...prevCounts, [uid]: 0 }));

    // Fetch all messages in the current chat and update their status if they are unread
    const currentMessages = msg.filter(
      message =>
        (message.receiver_id === uid && message.sender_id === user.user.uid) ||
        (message.sender_id === uid && message.receiver_id === user.user.uid)
    );

    try {
      for (let message of currentMessages) {
        // Only update status if the message is received by the current user
        if (message.message_status === 1 && message.receiver_id === user.user.uid) {
          await http.post('/message/updatestatus', {
            message_id: message.message_id,
            uid: user.user.uid
          });
          console.log(`Message status updated for message_id: ${message.message_id}`);
        }
      }
    } catch (error) {
      console.error('Failed to update message status:', error);
    }
  };

  const addMessage = (roomId, sender) => {
    const message = {
      id: Math.floor(Math.random() * 100),
      roomId,
      sender,
      message: curMessage,
      createdAt: new Date(),
    };
    setCurMessage("");
    dispatch(onAddMessage(message));
  };

  const scrollToBottom = useCallback(() => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000;
    }
  }, [messageBox]);

  useEffect(() => {
    if (!isEmpty(messages)) scrollToBottom();
  }, [messages, scrollToBottom]);


  const onKeyPress = (e) => {
    const { key, value } = e;
    if (key === "Enter") {
      e.preventDefault();
      setCurMessage(value);
      addMessage(currentRoomId, currentUser.name);
    }
  };

  //serach recent user
  const searchUsers = () => {
    var input, filter, ul, li, a, i, txtValue;
    input = document.getElementById("search-user");
    filter = input.value.toUpperCase();
    var userList = document.getElementsByClassName("users-list");
    Array.prototype.forEach.call(userList, function (el) {
      li = el.getElementsByTagName("li");
      for (i = 0; i < li.length; i++) {
        a = li[i].getElementsByTagName("a")[0];
        txtValue = a.textContent || a.innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          li[i].style.display = "";
        } else {
          li[i].style.display = "none";
        }
      }
    });
  };

  //Search Message
  const searchMessages = () => {
    var searchInput, searchFilter, searchUL, searchLI, a, i, txtValue;
    searchInput = document.getElementById("searchMessage");
    searchFilter = searchInput.value.toUpperCase();
    searchUL = document.getElementById("users-conversation");
    searchLI = searchUL.getElementsByTagName("li");
    Array.prototype.forEach.call(searchLI, function (search) {
      a = search.getElementsByTagName("p")[0] ? search.getElementsByTagName("p")[0] : '';
      txtValue = a.textContent || a.innerText ? a.textContent || a.innerText : '';
      if (txtValue.toUpperCase().indexOf(searchFilter) > -1) {
        search.style.display = "";
      } else {
        search.style.display = "none";
      }
    });
  };

  // Copy Message
  const handleCkick = (ele) => {
    var copy = ele.closest(".chat-list").querySelector('.ctext-content').innerHTML;
    navigator.clipboard.writeText(copy);

    document.getElementById("copyClipBoard").style.display = "block";
    setTimeout(() => {
      document.getElementById("copyClipBoard").style.display = "none";
    }, 2000);
  };


  // emoji
  const [emojiArray, setemojiArray] = useState("");

  const onEmojiClick = (event, emojiObject) => {
    // Append the selected emoji to the current text
    setTextToCopy((prev) => prev + emojiObject.emoji);
  };


  document.title = "Chat | Velzon - React Admin & Dashboard Template";

  const [TextToCopy, setTextToCopy] = useState('');
  const [isListening, setIsListening] = useState(false);

  const { transcript, browserSupportsSpeechRecognition } = useSpeechRecognition();

  useEffect(() => {
    if (transcript) {
      setTextToCopy(transcript); // Update TextToCopy with the transcript
    }
  }, [transcript]);

  useEffect(() => {
    if (isListening) {
      SpeechRecognition.startListening({ continuous: true, language: 'en-IN' });
    } else {
      SpeechRecognition.stopListening();
    }
  }, [isListening]);

  if (!browserSupportsSpeechRecognition) {
    return <div>Your browser does not support speech recognition.</div>;
  }

  const handleMicClick = () => {
    setIsListening(!isListening); // Toggle listening state
  };

  const handleTextareaChange = (e) => {
    setTextToCopy(e.target.value); // Manually update text in textarea
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
        <Row className="chat-wrapper g-0">
          {/* <div className="chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1"> */}
          {/* <Col lg={3} className="d-none d-lg-block"> */}
          <Col lg={3} >
            <div className="chat-leftsidebar">
              <div className="px-4 pt-4 mb-4">
                <div className="d-flex align-items-start">
                  <div className="flex-grow-1">
                    <h5 className="mb-4">Chats</h5>
                  </div>
                  <div className="flex-shrink-0">
                    <UncontrolledTooltip placement="bottom" target="addcontact">
                      Add Contact
                    </UncontrolledTooltip>

                    <Button
                      color=""
                      id="addcontact"
                      className="btn btn-soft-success btn-sm shadow-none"
                    >
                      <i className="ri-add-line align-bottom"></i>
                    </Button>
                  </div>
                </div>
                <div className="search-box">
                  <input
                    onKeyUp={searchUsers}
                    id="search-user"
                    type="text"
                    className="form-control bg-light border-light"
                    placeholder="Search here..."
                  />
                  <i className="ri-search-2-line search-icon"></i>
                </div>
              </div>

              <Nav tabs className="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}

                  >
                    Chats
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "2",
                    })}
                    onClick={() => {
                      toggleCustom("2");
                    }}
                  >
                    Contacts
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent
                activeTab={customActiveTab}
                className="text-muted"
              >
                <TabPane tabId="1" id="chats">
                  <SimpleBar
                    className="chat-room-list pt-3"
                    style={{ margin: "-16px 0px 0px" }}
                  >
                   

                    <div className="chat-message-list">
                      <ul className="list-unstyled chat-list chat-user-list users-list" id="userList">
                        {(filteredUserData || [])
                          .filter(chat => chat.uid !== user.user.uid) // Filter out the current user
                          .map(chat => (
                            <li key={chat.uid + chat.status} className={currentRoomId === chat.uid ? "active" : ""}>
                              <Link
                                to="#"
                                onClick={(e) => {
                                  e.preventDefault();
                                  userChatOpen(
                                    chat.uid,
                                    chat.name,
                                    chat.mobile_no,
                                    chat.email,
                                    chat.address,
                                    chat.profile_photo,
                                    chat.status,
                                    chat.roomId,
                                    chat.image,
                                    chat.message_id 
                                  );
                                }}
                              >
                                <div className="d-flex align-items-center">
                                  <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                    <div className="avatar-xxs">
                                      {chat.image ? (
                                        <img
                                          src={chat.image}
                                          className="rounded-circle img-fluid userprofile"
                                          alt=""
                                        />
                                      ) : (
                                        <div
                                          className={"avatar-title rounded-circle bg-" + chat.bgColor + " userprofile"}
                                        >
                                          {chat.name.charAt(0)}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="flex-grow-1 overflow-hidden">
                                    <p className="text-truncate mb-0">{chat.name}</p>
                                  </div>
                                  {unreadCounts[chat.uid] > 0 && (
                                    <div className="flex-shrink-0">
                                      <span className="user-status rounded-circle bg-success text-white p-1">
                                        {unreadCounts[chat.uid]}
                                      </span>
                                    </div>
                                  )}
                                  {chat.badge && (
                                    <div className="flex-shrink-0">
                                      <span className="badge bg-dark-subtle text-body rounded p-1">
                                        {chat.badge}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              </Link>
                            </li>
                          ))}
                      </ul>



                    </div>

                  </SimpleBar>
                </TabPane>

                <TabPane tabId="2" id="contacts">
                  <SimpleBar className="chat-room-list pt-3" style={{ margin: "-16px 20px 0px" }}>
                    <div className="sort-contact">
                      {(() => {
                        const currentUserType = 1; // Replace with the actual current user type
                        const groupedChats = [];

                        // Define groups
                        const allGroups = [
                          {
                            label: 'Super Admin',
                            uType: 1
                          },
                          {
                            label: 'Managers',
                            uType: 2,
                            userBehave: 1
                          },
                          {
                            label: 'Employees',
                            uType: 2,
                            userBehave: 0
                          },
                          {
                            label: 'Business',
                            uType: 3
                          },
                          {
                            label: 'Business Manager',
                            uType: 4,
                            userBehave: 1
                          },
                          {
                            label: 'Business Employee',
                            uType: 4,
                            userBehave: 0
                          },
                          {
                            label: 'Franchise',
                            uType: 5
                          }
                        ];

                        // Add groups based on current user type
                        allGroups.forEach(group => {
                          if (
                            (currentUserType === 1) ||
                            (currentUserType === 2 && (group.uType === 1 || group.uType === 2)) ||
                            (currentUserType === 3 && (group.uType === 1 || group.uType === 3 || group.uType === 4)) ||
                            (currentUserType === 4 && (group.uType !== 1 || group.uType === 3 || group.uType === 4)) ||
                            (currentUserType === 5 && (group.uType === 1 || group.uType === 5))
                          ) {
                            const options = (userData || [])
                              .filter(chat => chat.u_type === group.uType && (group.userBehave === undefined || chat.user_behave === group.userBehave));

                            if (options.length > 0) {
                              groupedChats.push({
                                label: group.label,
                                options
                              });
                            }
                          }
                        });

                        // Render grouped chats
                        return groupedChats.map((group, groupIndex) => (
                          <div key={groupIndex}>
                            <h6>{group.label}</h6>
                            <ul className="list-unstyled">
                              {group.options.map(chat => (
                                <li
                                  key={chat.uid + chat.status}
                                  className={currentRoomId === chat.uid ? "active" : ""}
                                >
                                  <Link
                                    to="#"
                                    onClick={() => {
                                      userChatOpen(
                                        chat.uid,
                                        chat.name,
                                        chat.status,
                                        chat.roomId,
                                        chat.image
                                      );
                                    }}
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="flex-shrink-0 chat-user-img online align-self-center me-2 ms-0">
                                        <div className="avatar-xxs">
                                          {chat.image ? (
                                            <img
                                              src={chat.image}
                                              className="rounded-circle img-fluid userprofile"
                                              alt=""
                                            />
                                          ) : (
                                            <div
                                              className={
                                                "avatar-title rounded-circle bg-" +
                                                chat.bgColor +
                                                " userprofile"
                                              }
                                            >
                                              {chat.name.charAt(0)}
                                            </div>
                                          )}
                                        </div>
                                        {/* <span className="user-status"></span> */}
                                      </div>
                                      <div className="flex-grow-1 overflow-hidden">
                                        <p className="text-truncate mb-0">{chat.name}</p>
                                      </div>
                                      {chat.badge && (
                                        <div className="flex-shrink-0">
                                          <span className="badge bg-dark-subtle text-body rounded p-1">
                                            {chat.badge}
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </Link>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ));
                      })()}
                    </div>
                  </SimpleBar>
                </TabPane>






              </TabContent>
            </div>
            </Col>
            <Col lg={9} xs={12}>
            <div >
              <div className="chat-content d-lg-flex">
                <div className="w-100 overflow-hidden position-relative">
                  <div className="position-relative">
                    <div className="p-3 user-chat-topbar">
                      <Row className="align-items-center">
                        <Col sm={4} xs={8}>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 d-block d-lg-none me-3">
                              <Link
                                to="#"
                                className="user-chat-remove fs-18 p-1"
                              >
                                <i className="ri-arrow-left-s-line align-bottom"></i>
                              </Link>
                            </div>
                            <div className="flex-grow-1 overflow-hidden">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0 chat-user-img online user-own-img align-self-center me-3 ms-0">
                                  {/* {Chat_Box_Image === undefined ? (
                                    <img
                                      src={userDummayImage}
                                      className="rounded-circle avatar-xs"
                                      alt=""
                                    />
                                  ) : (
                                    <img
                                      src={user.profile_photo}
                                      className="rounded-circle avatar-xs"
                                      alt=""
                                    />
                                  )} */}
                                  <span className="user-status"></span>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <h5 className="text-truncate mb-0 fs-16">
                                    <a
                                      className="text-reset username"
                                      data-bs-toggle="offcanvas"
                                      href="#userProfileCanvasExample"
                                      aria-controls="userProfileCanvasExample"
                                    >
                                      {Chat_Box_Username}
                                      {setChat_Box_address}
                                    </a>
                                  </h5>
                                  {/* <p className="text-truncate text-muted fs-14 mb-0 userStatus">
                                    <small>Online</small>
                                  </p> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col sm={8} xs={4}>
                          <ul className="list-inline user-chat-nav text-end mb-0">
                            <li className="list-inline-item m-0">
                              <Dropdown
                                isOpen={search_Menu}
                                toggle={toggleSearch}
                              >
                                <DropdownToggle
                                  className="btn btn-ghost-secondary btn-icon shadow-none"
                                  tag="button"
                                >
                                  <FeatherIcon
                                    icon="search"
                                    className="icon-sm"
                                  />
                                </DropdownToggle>
                                <DropdownMenu className="p-0 dropdown-menu-end dropdown-menu-lg">
                                  <div className="p-2">
                                    <div className="search-box">
                                      <Input
                                        onKeyUp={searchMessages}
                                        type="text"
                                        className="form-control bg-light border-light"
                                        placeholder="Search here..."
                                        id="searchMessage"
                                      />
                                      <i className="ri-search-2-line search-icon"></i>
                                    </div>
                                  </div>
                                </DropdownMenu>
                              </Dropdown>
                            </li>

                            <li className="list-inline-item d-none d-lg-inline-block m-0">
                              <button
                                type="button"
                                className="btn btn-ghost-secondary btn-icon shadow-none"
                                onClick={toggleInfo}
                              >
                                <FeatherIcon icon="info" className="icon-sm" />
                              </button>
                            </li>

                            <li className="list-inline-item m-0">
                              <Dropdown
                                isOpen={settings_Menu}
                                toggle={toggleSettings}
                              >
                                <DropdownToggle
                                  className="btn btn-ghost-secondary btn-icon shadow-none"
                                  tag="button"
                                >
                                  <FeatherIcon
                                    icon="more-vertical"
                                    className="icon-sm"
                                  />
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem
                                    href="#"
                                    className="d-block d-lg-none user-profile-show"
                                  >
                                    <i className="ri-user-2-fill align-bottom text-muted me-2"></i>{" "}
                                    View Profile
                                  </DropdownItem>
                                  <DropdownItem href="#">
                                    <i className="ri-inbox-archive-line align-bottom text-muted me-2"></i>{" "}
                                    Archive
                                  </DropdownItem>
                                  <DropdownItem href="#">
                                    <i className="ri-mic-off-line align-bottom text-muted me-2"></i>{" "}
                                    Muted
                                  </DropdownItem>
                                  <DropdownItem href="#">
                                    {" "}
                                    <i className="ri-delete-bin-5-line align-bottom text-muted me-2"></i>{" "}
                                    Delete
                                  </DropdownItem>
                                </DropdownMenu>
                              </Dropdown>
                            </li>
                          </ul>
                        </Col>
                      </Row>
                    </div>

                    <div className="position-relative" id="users-chat">
                      <div className="chat-conversation p-3 p-lg-4" id="chat-conversation">
                        <PerfectScrollbar
                          containerRef={ref => setMessageBox(ref)} >
                          <div id="elmLoader"></div>
                          <ul
                            className="list-unstyled chat-conversation-list"
                            id="users-conversation"
                          >
                            {msg
                              .filter(
                                message =>
                                  (message.receiver_id === Chat_Box_Uid || message.sender_id === Chat_Box_Uid) &&
                                  (message.sender_id === user.user.uid || message.receiver_id === user.user.uid)
                              )
                              .map((message, key) => (
                                <li
                                  className={
                                    message.sender_id === user.user.uid
                                      ? "chat-list right"
                                      : "chat-list left"
                                  }
                                  key={key}
                                >
                                  <div className="conversation-list">
                                    {message.sender_id !== user.user.uid && (
                                      <div className="chat-avatar">
                                        {user.profile_photo === undefined ? (
                                          <img src={userDummayImage} alt="" />
                                        ) : (
                                          <img src={user.profile_photo} alt="" />
                                        )}
                                      </div>
                                    )}

                                    <div className="user-chat-content">
                                      <div className="ctext-wrap">


                                        {message.message_image && isImage(message.message_image) ? (
                                          <a href={`${IMG_API_URL}/message/${message.message_image}`}>
                                            <ImgCom
                                              imageUrl={`${IMG_API_URL}/message/${message.message_image}`}
                                              alt={message.message_image}
                                            />
                                          </a>
                                        ) : message.message_image && isAudio(message.message_image) ? (
                                          <a href={`${IMG_API_URL}/message/${message.message_image}`} >
                                            <div className="file-wrap-content">
                                              {/* <AudioFileIcon /> */}
                                              {/* <p className="file-name">{message.message_image}</p> */}
                                              <audio controls>
                                                <source src={`${IMG_API_URL}/message/${message.message_image}`} />
                                                Your browser does not support the audio element.
                                              </audio>
                                            </div>
                                          </a>
                                        ) : message.message_image && isVideo(message.message_image) ? (
                                          <a href={`${IMG_API_URL}/message/${message.message_image}`} >
                                            <div className="file-wrap-content">
                                              {/* <VideoFileIcon /> */}
                                              {/* <p className="file-name">{message.message_image}</p> */}
                                              <video controls style={{ width: '280px', height: '240px' }}>
                                                <source src={`${IMG_API_URL}/message/${message.message_image}`} />
                                                Your browser does not support the video element.
                                              </video>
                                            </div>
                                          </a>
                                        ) : message.message_image ? (
                                          <a href={`${IMG_API_URL}/message/${message.message_image}`} >
                                            <div className="file-wrap-content">
                                              <DescriptionIcon />
                                              <p className="file-name">{message.message_image}</p>
                                            </div>
                                          </a>
                                        ) : (
                                          <div className="ctext-wrap-content">
                                            <p className="mb-0 ctext-content">
                                              {message.message_description}
                                              {message.message_image}
                                            </p>
                                          </div>
                                        )}





                                        <UncontrolledDropdown className="align-self-start message-box-drop">
                                          <DropdownToggle href="#" className="btn nav-btn" tag="a">
                                            <i className="ri-more-2-fill"></i>
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            {/* <DropdownItem
                                              href="#"
                                              className="reply-message"
                                              onClick={() => setreply(message)}
                                            >
                                              <i className="ri-reply-line me-2 text-muted align-bottom"></i>
                                              Reply
                                            </DropdownItem> */}
                                            {/* <DropdownItem href="#">
                                              <i className="ri-share-line me-2 text-muted align-bottom"></i>
                                              Forward
                                            </DropdownItem> */}
                                            <DropdownItem href="#" onClick={(e) => handleCkick(e.target)}>
                                              <i className="ri-file-copy-line me-2 text-muted align-bottom"></i>
                                              Copy
                                            </DropdownItem>
                                            <DropdownItem href="#" onClick={() => generatePDF(`${IMG_API_URL}/message/${message.message_image}`, message.message_image)}>
                                              <i className="ri-download-line me-2 text-muted align-bottom"></i>
                                              Download
                                            </DropdownItem>

                                            <DropdownItem href="#" onClick={() => deleteMessage(message.message_id)}>
                                              <i className="ri-delete-bin-5-line me-2 text-muted align-bottom"></i>
                                              Delete
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </UncontrolledDropdown>
                                      </div>
                                      <div className="conversation-name">
                                        <small className="text-muted time">
                                          {formatToIST(message.createdAt)}
                                        </small>
                                        <span className="text-success check-message-icon">
                                          <i className="ri-check-double-line align-bottom"></i>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))}


                          </ul>
                        </PerfectScrollbar>
                      </div>
                      <div
                        className="alert alert-warning alert-dismissible copyclipboard-alert px-4 fade show "
                        id="copyClipBoard"
                        role="alert"
                      >
                        Message copied
                      </div>
                      {/* {emojiPicker && (
                        <div className="alert pickerEmoji" style={{ marginLeft: "90px" }}>
                          <Picker disableSearchBar={true} onEmojiClick={onEmojiClick} style={{ marginRight: "20px" }} />
                        </div>
                      )} */}
                    </div>
                    <div className="file-previews">
                      {filePreviews.map((preview, index) => (
                        <img
                          key={index}
                          src={preview}
                          alt={`file-preview-${index}`}
                          style={{ width: "100px", marginRight: "10px" }}
                        />
                      ))}
                    </div>
                    <div className="chat-input-section p-3 p-lg-4">
                      {Images ? (
                        <img
                          src={URL.createObjectURL(Images)}
                          id="product-img"
                          alt="product-img"
                          height={"100px"}
                          width={"100px"}
                        />
                      ) : (
                        ""
                      )}
                      <form id="chatinput-form" onKeyDown={handleKeyDown} >
                        <Row className="g-0 align-items-center">
                          <div className="col-auto">
                            <div className="chat-input-links me-2">
                              <div className="links-list-item">
                                {/* <button
                                  type="button"
                                  className="btn btn-link text-decoration-none emoji-btn"
                                  id="emoji-btn"
                                  onClick={() => setEmojiPicker(!emojiPicker)}
                                >
                                  <i className="bx bx-smile align-middle"></i>
                                </button> */}
                              </div>
                            </div>
                          </div>
                          <div className="col">
                            <div className="chat-input-feedback">
                              Please Enter a Message
                            </div>
                            <div style={{ position: "relative" }}>
                              <input
                                name="message_description"
                                type="text"
                                value={TextToCopy} // Display combined text and emojis
                                onChange={handleTextareaChange}
                                className="form-control chat-input bg-light border-light"
                                id="chat-input"
                                placeholder="Type your message..."
                                style={{ paddingRight: "40px" }} // Add padding to make space for the button
                              />
                              <Button
                                type="button"
                                // color="success"
                                className="chat-send waves-effect waves-light rounded-circle"
                                style={{
                                  position: "absolute",
                                  right: "10px", // Adjust as needed to position it inside the input box
                                  top: "50%",
                                  transform: "translateY(-50%) ",
                                  width: "30px",
                                  height: "30px",
                                  padding: "0",
                                  border: "none", // Remove the border
                                  background: "none", // Remove the background
                                  // zIndex: 1, // Ensure the button is above the input field
                                }}
                                onClick={handleMicClick}
                              >
                                <i
                                  className={isListening ? "ri-stop-circle-fill align-bottom" : "ri-mic-fill align-bottom"}
                                  style={{ color: "blue" }} // Set the icon color to blue
                                ></i>
                              </Button>

                            </div>

                          </div>

                          <div className="col-auto">
                            {/* Hidden file inputs */}
                            <input
                              type="file"
                              id="imageInput"
                              style={{ display: 'none' }}
                              accept="image/png, image/gif, image/jpeg, video/mp4, video/avi, video/mkv" // Accept both image and video files
                              onChange={handleFileChange}
                              ref={imageInputRef}
                            />
                            <input
                              type="file"
                              id="fileInput"
                              style={{ display: 'none' }}
                              accept=".pdf,.doc,.docx,.txt,.xlsx,*/*"
                              onChange={handleFileChange}
                              ref={fileInputRef}
                            />
                            <input
                              type="file"
                              id="audioInput"
                              style={{ display: 'none' }}
                              accept=".mp3,.aac,.wav,.flac,.mpeg,*/*"
                              onChange={handleFileChange} // This will handle the audio file change as well
                              ref={audioInputRef}
                            />
                            {/* Custom icon button */}
                            <IconButton component="span" onClick={toggleModal}>
                              <AttachFileIcon />
                            </IconButton>
                            <Dialog open={showModal} onClose={toggleModal}>
                              {/* <DialogTitle>Select a File Type</DialogTitle> */}
                              <DialogContent>
                                <Button variant="contained" component="span" color="primary" onClick={openImageInput}>
                                  <ImageIcon />
                                  Image
                                </Button>
                                <Button variant="contained" component="span" color="secondary" onClick={openFileInput}>
                                  <DescriptionIcon /> File
                                </Button>
                                <Button variant="contained" component="span" color="secondary" onClick={openAudioInput}>
                                  <AudioFileIcon /> Audio
                                </Button>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={toggleModal} color="primary">
                                  Cancel
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </div>

                          {/* {message.message_audio && (
                            <div className="audio-wrap-content">
                              <audio controls>
                                <source
                                  src={`${IMG_API_URL}/audio/${message.message_audio}`}
                                  type="audio/wav"
                                />
                                Your browser does not support the audio element.
                              </audio>
                            </div>
                          )} */}

                          {/* <div className="col-auto">
                            <Button
                              type="button"
                              color="success"
                              className="chat-send waves-effect waves-light rounded-circle"
                              style={{ width: "30px", height: "30px", padding: "0" }}
                              onClick={handleMicClick}
                            >
                              <i className={isListening ? "ri-stop-circle-fill align-bottom" : "ri-mic-fill align-bottom"}></i>
                            </Button>
                          </div> */}

                          <div className="col-auto">
                            <div className="chat-input-links ms-2">
                              <div className="links-list-item">

                                <Button
                                  type="button"
                                  color="success"
                                  onClick={handleSubmit}
                                  className="chat-send waves-effect waves-light"
                                >
                                  <i className="ri-send-plane-2-fill align-bottom"></i>
                                </Button>

                              </div>
                            </div>
                          </div>
                        </Row>

                      </form>
                    </div>

                    <div className={reply ? "replyCard show" : "replyCard"}>
                      <Card className="mb-0">
                        <CardBody className="py-3">
                          <div className="replymessage-block mb-0 d-flex align-items-start">
                            <div className="flex-grow-1">
                              <h5 className="conversation-name">{reply && reply.sender_id}</h5>
                              <p className="mb-0">{reply && reply.message}</p>
                            </div>
                            <div className="flex-shrink-0">
                              <button
                                type="submit"
                                id="close_toggle"
                                className="btn btn-sm btn-link mt-n2 me-n3 fs-18"
                                onClick={() => setreply("")}
                              >
                                <i className="bx bx-x align-middle"></i>
                              </button>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>

                  </div>

                </div>

              </div>
            </div>
            </Col>
          {/* </div> */}
        </Row>
        </Container>
      </div >

      <PersonalInfo
        show={isInfoDetails}
        onCloseClick={() => setIsInfoDetails(false)}
        currentuser={Chat_Box_Username}
        currentuser_mobile={Chat_Box_mobile}
        currentuser_email={Chat_Box_email}
        currentuser_profile={Chat_Box_profile}
        currentuser_address={Chat_Box_address}
        cuurentiseImg={Chat_Box_Image}
      />
      
    </React.Fragment >
  );
};

export default Chat;