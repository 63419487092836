import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_URL } from "./url_helper";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

export default function AuthUser() {
  // const permissionsList = useSelector(
  //   (state) => state.permissionsSlice.permissionsList
  // );
  const navigate = useNavigate();
  const getToken = () => {
    const tokenString = sessionStorage.getItem("token");
    const userToken = JSON.parse(tokenString);
    return userToken;
  };

  const getUser = () => {
    const userString = JSON.parse(sessionStorage.getItem("authUser"));
    return userString;
  };

  const [token, setToken] = useState(getToken());
  const [user, setUser] = useState(getUser());

  const saveToken = (user, token) => {
    sessionStorage.setItem("token", JSON.stringify(token));
    sessionStorage.setItem("user", JSON.stringify(user));
    // setToken(token);
    // setUser(user);
    // navigate("");
  };

  const http = axios.create({
    baseURL: `${API_URL}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const https = axios.create({
    baseURL: `${API_URL}`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  const checkPermission = (permissionName) => {
    if (JSON.parse(sessionStorage.getItem("authUser")).user.email == "admin") {
      return true;
    }
    // const resp = permissionsList.find(
    //   (permission) => permission.permission_name == permissionName
    // );
    // if (resp) {
    //   return true;
    // }
    return true;
  };

  const logout = async () => {

    window.location.href = '/login';
    sessionStorage.clear();
    setToken(null);
    setUser(null);


  };
  const [permission, setPermission] = useState([]);
  useEffect(() => {
    http.get(`role/show/${user && user.user.user_role_id}`).then((res) => {
      setPermission(res.data.rolesAndPermissionsData)
    }).catch((e) => {
      console.log(e);
    })
  }, [user])
  return {
    setToken: saveToken,
    token,
    user,
    http,
    permission,
    https,
    logout,
    checkPermission,
  };
}
