import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  CardText,
} from "reactstrap";
import Chart from "chart.js/auto";
import { activeProjects } from "../../common/data";
import { Link } from "react-router-dom";
import Section from "./Section";
import AuthUser from "../../helpers/Authuser";
import Widgets from "./Widgets";
import RecentOrders from "./RecentOrders";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import PieCharts from "../Charts/ApexCharts/PieCharts";
import {
  SimpleDonut,
  SimpleDonut1,
} from "../Charts/ApexCharts/PieCharts/PieCharts";
import D_img from "../D_img";
import ImgCom from "../Fullscreenimg/ImgCom";
import { IMG_API_URL } from "../../helpers/url_helper";
import LeadsView from "../Leads/LeadsView";
import TaskView from "../Task/TaskView";
import CoverSuccessMsg from "../AuthenticationInner/SuccessMessage/CoverSuccessMsg";
import { FormatShapes } from "@mui/icons-material";
const DashboardEcommerce = () => {
  const [LeadData, SetLeadData] = useState([]);
  const [LeadData11, SetLeadData11] = useState([]);
  const { http, user, token } = AuthUser();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Dashboard | CRM";
    if (!token) {
      navigate("/login");
    } else {
      navigate("/dashboard");
    }
  }, []);
  // console.log(user);
  const { type } = useParams();
  document.title = "Dashboard | CRM";
  const [rightColumn, setRightColumn] = useState(true);
  const toggleRightColumn = () => {
    setRightColumn(!rightColumn);
  };
  const [shiftedData, setshiftedData] = useState({
    leads: [],
    task: [],
  });

  const [TaskData, SetTaskData] = useState([]);
  const [TaskData11, SetTaskData11] = useState([]);
  const [LeaveData, SetLeaveData] = useState([]);

  // Get data
  const [getCustomerData, setCustomerData] = useState([]);

  const customerData = async () => {
    http
      .get(`/customer/list`)
      .then(function (response) {
        if (response.data.length > 0) {
          if (formData.department > 0) {
            setCustomerData(
              response.data.filter(
                (data) => data.user_role_id == formData.department
              )
            );
          } else {
            setCustomerData(response.data);
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [tagsData, settagsData] = useState([]);
  const gettagsData = async () => {
    http
      .get("/tags/list")
      .then((response) => {
        if (response.data.length > 0) {
          settagsData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [assignedByData, setassignedByData] = useState([]);
  const getassignedByData = async () => {
    http
      .get("/user/list")
      .then((response) => {
        if (response.data.length > 0) {
          setassignedByData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [counts, Setcounts] = useState(1);
  //
  const currentYear = new Date().getFullYear();
  const [formData, setformData] = useState({
    start_date: `${currentYear}-01-01` + " 00:00:00",
    end_date: `${currentYear}-12-31` + " 23:59:59",
    leave_status: 0,
    leave_id: type,
    user_id: 0,
    customer_name: 0,
    lead_id: type ? type : 0,
    assigned_by: 0,
    department: 0,
  });

  const [totalData, settotalData] = useState({
    taskCount: 0,
    taskStatusCount: [],
    leadCount: 0,
    leadStatusCount: [],
    customerCount: 0,
    attendanceCount: 0,
  });


  const [Employee, setEmployee] = useState([]);
  const [selectedCount, setSelectedCount] = useState('')
  const [leavetoday, setleavetoday] = useState(0);
  const [statusData, setStatusData] = useState([]);

  const handleFilter = async () => {
    //dash board total counts and statuswise report
    http.post("/dashboard/total", formData).then((res) => {
      settotalData({
        taskCount: res.data.taskCount,
        leadStatusCount: res.data.leadStatusCounts,
        taskStatusCount: res.data.taskStatusCounts,
        leadCount: res.data.leadCount,
        customerCount: res.data.customerCount,
        attendanceCount: res.data.attendanceCount,
        attendanceCountout: res.data.attendanceCountout,
      });
    });
    const ct = new Date().toISOString().split("T")[0];
    //dashboard leave list
    http
      .post(`leave/list`, formData)
      .then(function (response) {
        // console.log("LeaveData:", response.data);
        if (response.data.length > 0) {
          const filteredData = response.data.filter((item) => {
            const itemDate = ct; // Assuming item.date is in YYYY-MM-DD format
            return itemDate >= item.from_date && itemDate <= item.to_date;
          });
          // console.log("jhgdcjv", filteredData);
          setleavetoday(filteredData.length);
          SetLeaveData(response.data); // Update state with filtered data
        } else {
          SetLeaveData([]); // Set state to empty array if no data
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    //dashboard leads list

    http
      .post(`/leads/filter`, filterData)
      .then(function (response) {
        SetLeadData(response.data);
        SetLeadData11(response.data);
        // console.log(response.data);
        // const uniqueLeads = response.data.filter((item, index, self) =>
        //   index === self.findIndex(t =>
        //     t.lead_id === item.lead_id || t.lead_status_id === item.lead_status_id
        //   )
        // );
        // setStatusData(uniqueLeads);
      })
      .catch(function (error) {
        console.log(error);
      });
    http
      .get(`/lead/list`)
      .then(function (response) {
        setStatusData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    http
      .get(`/user/list`)
      .then(function (response) {
        if (response.data.length > 0) {
          if (formData.department > 0) {
            setEmployee(
              response.data.filter(
                (data) => data.user_role_id == formData.department || data.role_created_for == formData.department
              )
            );
          } else {
            setEmployee(response.data);
          }
        } else {
          setEmployee([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    //dashboard task list

    http
      .post(`/task/filter`, filterData)
      .then(function (response) {
        if (response.data.length > 0) {
          SetTaskData(response.data);

          SetTaskData11(response.data);
        } else {
          SetTaskData([]);
        }
      })
      .catch(function (error) {
        console.log(error);
      });

    //dashboard Shift Task & Shift Leads

    http
      .post("/slt/details", formData)
      .then((response) => {
        if (response.data.leads.length > 0) {
          setshiftedData({
            leads: response.data.leads,
            task: response.data.task,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  const [filterData, setfilterData] = useState({
    start_date: "",
    end_date: "",
    user_id:
      user != null && user != null
        ? user.user.u_type === 1
          ? 0
          : user.user.uid
        : 0,
    department: 0,
  });



  useEffect(() => {
    getassignedByData();
    document.title = "Lead | CRM";
    handleFilter();
  }, [counts]);
  useEffect(() => {
    handleFilter();
  }, [formData])

  const handleCallback = (data, status) => {
    SetLeadData([]);
    if (status == 1) {
      Setcounts(counts + 1);
    } else {
      Setcounts(counts + 1);
    }
    handleFilter();
  };
  const [FindData, SetFind] = useState([]);
  const [viewModalaStates, setviewModalaStates] = useState(false);
  const ViewPage = (index) => {
    let FindArray = LeadData.filter((item, i) => item.lead_id == index);
    SetFind(FindArray[0]);
    setviewModalaStates(!viewModalaStates);
  };

  const [FindData1, SetFind1] = useState([]);
  const [viewModalaStates1, setviewModalaStates1] = useState(false);
  const ViewPage1 = (index) => {
    let FindArray = TaskData.filter((item, i) => item.task_id == index);
    SetFind1(FindArray[0]);
    setviewModalaStates1(!viewModalaStates1);
  };

  const dataColors = statusData.map((data) => `--vz-${data.lead_status_color}`);
  // Convert colors array to a string
  const colorsString = JSON.stringify(dataColors);

  const [role, setRole] = useState([]);
  const [showBoxSub, setShowBoxSub] = useState(false);
  const [showBoxTeam, setShowBoxTeam] = useState(false);
  const [showMain, setshowMain] = useState(null);
  const [showSub, setshowSub] = useState(null);
  const [selectedRoleIds, setSelectedRoleIds] = useState(
    user && user.user.u_type === 2 && user && user.user.user_behave === 1
      ? user.user.user_role_id
      : null
  );
  const [statuscheckforAssignedBy, setstatuscheckforAssignedBy] = useState(0);

  const getRole = async () => {
    try {
      const response = await http.get("/role/list");

      const filteredData =
        user && (user.user.u_type === 1 || user.user.u_type === 2)
          ? response.data.filter(
            (role) =>
              parseInt(role.role_created_user_id) ===
              user.user.user_user_id ||
              parseInt(role.role_created_user_id) === user.user.uid
          )
          : response.data.filter(
            (role) => parseInt(role.role_created_user_id) === user.user.uid
          );
      setRole(filteredData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRole();
  }, []);
  const [originalLeadData, setOriginalLeadData] = useState([]);

  const fetchChartData = async () => {
    try {
      const filterPayload = {
        ...filterData,
        department: filterData.department || 0, // Send department 0 when "Select All" or no selection
      };

      const response = await http.post(`/leads/filter`, filterPayload);
      SetLeadData(response.data); // Update the leads based on the filter criteria
    } catch (error) {
      console.error("Failed to fetch lead data", error);
    }
  };
  useEffect(() => {
    fetchChartData();
  }, [filterData]);

  const handleRoleChange = (e) => {
    const selectedDepartment = e.value === 0 ? null : e.value;

    setSelectedRoleIds(selectedDepartment);
    setstatuscheckforAssignedBy(statuscheckforAssignedBy + 1);

    // Update filterData for leads
    setfilterData({
      ...filterData,
      department: selectedDepartment, // Set department or null if "Select All"
    });

    // handleFilter();
    // Update chart filter
    setfilterData({
      ...filterData,
      department: selectedDepartment, // Set department or null if "Select All"
    });

    // Update form data
    setformData({
      ...formData,
      department: selectedDepartment, // Update the department value in formData
    });

    Setcounts(counts + 1);

    const tempdata = TaskData.slice(0, 10).filter((temp) => {
      // If selectedUserId is not set, return all items
      if (!selectedUserId) {
        return true;
      }

      // Parse assigned_by and compare with selectedUserId if provided
      const assignedBy = JSON.parse(temp.assigned_by); // Parse it if it's a valid JSON array
      return parseInt(assignedBy[0]) === parseInt(selectedUserId); // Use the first element
    })


  };
  const tableRef = useRef(null);
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [highlightColor, sethighlightColor] = useState("");


  const handleLinkClick = (e) => {
    // Filter the lead data based on the clicked item
    SetLeadData(LeadData11.filter((item) => item.lead_status_id === e));

    if (tableRef.current) {
      const element = tableRef.current;

      const elementRect = element.getBoundingClientRect();
      const middleOffset = window.innerHeight / 6 - elementRect.height / 6;

      window.scrollTo({
        top: window.scrollY + elementRect.top + middleOffset,
        behavior: "smooth",
      });

      // Set highlight state
      setIsHighlighted(true);

      // Remove highlight after a delay
      setTimeout(() => {
        setIsHighlighted(false);
      }, 2000); // Adjust the time as needed
    }
  };

  const tableRef1 = useRef(null);
  const [isHighlighted1, setIsHighlighted1] = useState(false);

  const handleLinkClick1 = (e, color) => {
    // console.log(e);
    SetTaskData(TaskData11.filter((item) => item.lead_status_id == e));
    if (tableRef1.current) {
      const element = tableRef1.current;

      const elementRect = element.getBoundingClientRect();
      // const middleOffset = window.innerHeight / 6 - elementRect.height / 6;

      window.scrollTo({
        top: window.scrollY + elementRect.top,
        behavior: "smooth",
      });
      sethighlightColor(color);

      // Set highlight state
      setIsHighlighted1(true);

      // Remove highlight after a delay
      setTimeout(() => {
        setIsHighlighted1(false);
      }, 2000); // Adjust the time as needed
    }
  };
  const [selectedUserId, setSelectedUserId] = useState('');


  useEffect(() => {

  }, [])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <div className="h-100">
                <Section rightClickBtn={toggleRightColumn} />

                {/* <CoverSuccessMsg/> */}
                <Row>
                  <Row className="align-items-center gy-3">
                    <div className="text-center pt-2 pb-4">
                      <h2 className="fw-bold mb-0">
                        {filterData && filterData.department > 0
                          ? `${role.filter(
                            (data) => data.role_id == filterData.department
                          )[0].role_name
                          } `
                          : "All "}
                        Dashboard's
                      </h2>
                    </div>
                  </Row>
                  <Col xl={12}>
                    <Row className="justify-content-center align-items-center d-flex">


                      <Col lg="auto" sm="12">
                        <div>
                          <div className="fw-bold">Department</div>
                          <Select
                            className="fw-bold"
                            options={[
                              { value: 0, label: "Select All" },
                              ...role.filter(item => item.role_created_for == null).map((e) => ({
                                value: e.role_id,
                                label: e.role_name,
                              })),
                            ]}
                            onChange={(e) => {
                              setshowMain(e.value);
                              setshowSub(e.value)
                              setShowBoxSub(true)
                              handleRoleChange(e);
                            }}

                          />
                        </div>
                      </Col>

                      {
                        showBoxSub === true && <Col lg="auto" sm="12">
                          <div>
                            <div className="fw-bold" > Sub-Department</div>
                            <Select

                              className="fw-bold apexcharts-legend-text"
                              options={[
                                { value: 0, label: "Select All" },
                                ...role.filter(item => item.role_created_for == showMain).map(e => ({
                                  value: e.role_id,
                                  label: e.role_name,
                                })),
                              ]}
                              onChange={(e) => {
                                handleRoleChange(e)
                                setshowSub(e.value)
                                setShowBoxTeam(true)
                              }}
                            />


                          </div>

                        </Col>

                      }
                      {showBoxTeam === true && <Col lg="auto" sm="12">
                        <div>
                          <div className="fw-bold">Team Member</div>
                          <Select
                            className="fw-bold"
                            placeholder="Employee"
                            options={[
                              { value: 0, label: "Select All" },
                              {
                                label: 'Managers',
                                options: Employee
                                  .filter(e => showSub == 0 ? (e.user_role_id == showMain || e.role_created_for == showMain) && e.user_behave == 1 : e.user_behave == 1)
                                  .map(e => ({ value: e.uid, label: e.name })),
                              },
                              {
                                label: 'Team Member',
                                options: Employee
                                  .filter(e => showSub == 0 ? (e.user_role_id == showMain || e.role_created_for == showMain) && e.user_behave == 0 : e.user_behave == 0)
                                  .map(e => ({ value: e.uid, label: e.name })),
                              },
                            ]}
                            onChange={(e) => {
                              setSelectedUserId(e.value);
                              setformData({
                                ...formData,
                                assigned_by: e.value
                              });
                            }}
                          ></Select>
                        </div>
                      </Col>
                      }
                      <Col lg="auto" sm="12">
                        <div>
                          <div className="fw-bold">Start Date</div>
                          <Flatpickr
                            className="form-control fw-bold"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            name="payment_date"
                            placeholder="Enter start date"
                            value={null}
                            onChange={(selectedDates) => {
                              const selectedDate = selectedDates[0];
                              const year = selectedDate.getFullYear();
                              const month = (selectedDate.getMonth() + 1)
                                .toString()
                                .padStart(2, "0");
                              const day = selectedDate
                                .getDate()
                                .toString()
                                .padStart(2, "0");
                              const formattedDate = `${year}-${month}-${day} 00:00:00`;

                              setfilterData({
                                ...filterData,
                                start_date: formattedDate,
                              });
                              setformData((prevFormData) => ({
                                ...prevFormData,
                                start_date: formattedDate,
                              }));
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg="auto" sm="12">
                        <div>
                          <div className="fw-bold">End Date</div>
                          <Flatpickr
                            className="form-control fw-bold"
                            options={{
                              dateFormat: "d/m/Y",
                              defaultDate: "today",
                            }}
                            name="payment_date"
                            placeholder="Enter start date"
                            value={null}
                            onChange={(selectedDates) => {
                              const selectedDate = selectedDates[0];
                              // Convert to YYYY-MM-DD format
                              const year = selectedDate.getFullYear();
                              const month = (selectedDate.getMonth() + 1)
                                .toString()
                                .padStart(2, "0");
                              const day = selectedDate
                                .getDate()
                                .toString()
                                .padStart(2, "0");
                              const formattedDate = `${year}-${month}-${day} 23:59:59`;
                              setformData((prevFormData) => ({
                                ...prevFormData,
                                end_date: formattedDate,
                              }));
                              setfilterData({
                                ...filterData,
                                end_date: formattedDate,
                              });
                            }}
                          />
                        </div>
                      </Col>
                      <Col lg="auto" sm="12">
                        <button className="btn mt-3 btn-success" onClick={handleFilter}>
                          Search
                        </button>
                      </Col>

                    </Row>
                  </Col>

                  <Col xl={12} className="mt-3">

                    <Row>
                      <Col xl={6}>
                        <Card>
                          <CardHeader className=" d-flex justify-content-between">
                            <h4 className="card-title mb-0">Task </h4>
                            <h4 className="card-title mb-0">
                              {(() => {
                                const tempdata = TaskData.filter((temp) => {
                                  // If selectedUserId is not set, return all items
                                  if (!selectedUserId) {
                                    return true;
                                  }

                                  try {
                                    // Parse assigned_by and compare with selectedUserId if provided
                                    const assignedBy = JSON.parse(temp.assigned_by);
                                    return Array.isArray(assignedBy) && parseInt(assignedBy[0]) === parseInt(selectedUserId);
                                  } catch (error) {
                                    // Handle invalid JSON gracefully
                                    return false;
                                  }
                                });

                                return `Total: ${tempdata.length}`;
                              })()}
                            </h4>

                          </CardHeader>
                          <CardBody>
                            <SimpleDonut1
                              additionalData={totalData.taskStatusCount}
                              dataColors={colorsString}
                              statusData={statusData}
                              goToStatus={handleLinkClick1}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                              }}
                            >
                              {statusData.map((data, i) => (
                                <Link
                                  key={i}
                                  onClick={() =>
                                    handleLinkClick1(data.lead_status_id, data.lead_status_color)
                                  }
                                  // to={`/task-list/0/${data.lead_status_id}`}
                                  style={{
                                    marginRight: "10px",
                                    height: "50px",
                                    linHeight: "50px",
                                    marginBottom: "-10px",
                                    marginTop: "-7px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `var(--vz-${data.lead_status_color})`,
                                      fontSize: "3.5em",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    •
                                  </span>{" "}
                                  {/* Fifth bullet with color --vz-danger and larger size */}
                                  <span
                                    className="apexcharts-legend-text"
                                    style={{
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {data.lead_status_name}-
                                    {totalData.taskStatusCount[
                                      data.lead_status_name
                                    ] || 0}
                                  </span>
                                </Link>
                              ))}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={6}>
                        <Card>
                          <CardHeader>
                            <h4 className="card-title mb-0">Leads</h4>
                          </CardHeader>
                          <CardBody>
                            <SimpleDonut
                              additionalData={totalData.leadStatusCount}
                              dataColors={colorsString}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "center",
                              }}
                            >
                              {statusData.map((data, i) => (
                                <Link
                                  key={i}
                                  onClick={() =>
                                    handleLinkClick(data.lead_status_id, data.lead_status_color)
                                  }
                                  // to={`/leads-list/0/${data.lead_status_id}`}
                                  style={{
                                    marginRight: "10px",
                                    height: "50px",
                                    lineHeight: "50px",
                                    marginBottom: "-10px",
                                    marginTop: "-7px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: `var(--vz-${data.lead_status_color})`,
                                      fontSize: "3.5em",
                                      verticalAlign: "middle",
                                    }}
                                  >
                                    •
                                  </span>{" "}
                                  {/* Fifth bullet with color --vz-danger and larger size */}
                                  <span
                                    className="apexcharts-legend-text"
                                    style={{
                                      fontWeight: "bold",
                                      color: "black",
                                    }}
                                  >
                                    {data.lead_status_name}-
                                    {totalData.leadStatusCount[
                                      data.lead_status_name
                                    ] || 0}
                                  </span>
                                </Link>
                              ))}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      {/* <Widgets Comp={totalData} emp={Employee} todayleave={leavetoday} /> */}
                    </Row>
                  </Col>
                </Row>
                <div ref={tableRef1}>
                  <Row>
                    <Col lg={12}>
                      <Card lg={12}>
                        <CardHeader className="card-header border-0">
                          <Row className="align-items-center gy-3">
                            <div className="col-sm">
                              <h5 className="card-title mb-0">Tasks </h5>
                            </div>
                            <div className="col-sm-auto">
                              <div className="d-flex gap-1 flex-wrap">
                                <Link
                                  to="/task-list"
                                  className="btn btn-soft-info btn-sm shadow-none"
                                >
                                  View All
                                </Link>
                              </div>
                            </div>
                          </Row>
                        </CardHeader>
                        <CardBody
                          className="pt-0"
                          style={{ height: "400px", overflowY: "auto" }}
                        >
                          <div className="table-responsive">
                            <table
                              role="table"
                              className="align-middle table-nowrap table table-hover"
                            >
                              <thead className="table-light text-muted text-uppercase">
                                <tr>
                                  <th
                                    title="Task List"
                                    style={{ cursor: "pointer" }}
                                  >
                                    #
                                  </th>
                                  <th>Date & time</th>


                                  <th>End Date</th>
                                  <th>Task Title</th>
                                  <th>Assigned by</th>
                                  <th>Team Member</th>

                                  <th>Priority</th>

                                </tr>
                              </thead>
                              <tbody
                                className={isHighlighted1 ? ("bg-" + highlightColor) : ""}
                                style={{

                                  transition: "background-color 0.5s ease",
                                }}
                              >
                                {TaskData.filter((temp) => {
                                  // If selectedUserId is not set, return all items
                                  if (!selectedUserId) {
                                    return true;
                                  }

                                  // Parse assigned_by and compare with selectedUserId if provided
                                  const assignedBy = JSON.parse(temp.assigned_by); // Parse it if it's a valid JSON array
                                  return parseInt(assignedBy[0]) === parseInt(selectedUserId); // Use the first element
                                }).slice(0, 10).map((item, index) => (
                                  <tr
                                    key={index}
                                    onClick={() => ViewPage1(item.task_id)}
                                  >
                                    <td
                                      title="Index"
                                      onClick={() => ViewPage1(index)}
                                    >
                                      <a
                                        className="fw-medium link-primary"
                                        href="/apps-ecommerce-order-details"
                                      >
                                        {index + 1}
                                      </a>
                                    </td>
                                    <td onClick={() => ViewPage1(index)}>
                                      {/* {item.today_date.split("T")[0]} */}
                                      {new Date(
                                        item.minimum_due_date
                                      ).toLocaleDateString("en-GB")}
                                      <br />

                                      {new Date(
                                        item.minimum_due_date
                                      ).toLocaleTimeString("en-US", {
                                        hour12: true,
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}
                                    </td>
                                    <td
                                      title="Start Date"
                                      onClick={() => ViewPage1(index)}
                                    >
                                      {new Date(
                                        item.next_follow_up
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                    <td
                                      title="Task Title"
                                      onClick={() => ViewPage1(index)}
                                    >
                                      {item.task_title}
                                    </td>
                                    <td
                                      title="Assigned By"
                                      onClick={() => ViewPage1(index)}
                                    >
                                      {item.name}
                                    </td>
                                    <td
                                      title="Assigned To"
                                      onClick={() => ViewPage1(index)}
                                    >
                                      {assignedByData != null && item.assigned_by ? (
                                        assignedByData.map((e) => {
                                          // Parse assigned_by and check if it is an array
                                          const parsedAssignedBy = JSON.parse(item.assigned_by);

                                          return Array.isArray(parsedAssignedBy) && parsedAssignedBy.includes(e.uid) ? (
                                            <div key={e.uid} className="p-1">
                                              <button className="bg-primary fw-bold text-white border border-primary">
                                                {e.name}
                                              </button>
                                            </div>
                                          ) : null;
                                        })
                                      ) : (
                                        "Loading..."
                                      )}

                                    </td>
                                    <td
                                      title="Priority"
                                      onClick={() => ViewPage1(index)}
                                    >
                                      {item.priority === 1
                                        ? "Low"
                                        : item.priority === 2
                                          ? "Medium"
                                          : item.priority === 3
                                            ? "High"
                                            : item.priority === 4
                                              ? "Urgent"
                                              : ""}
                                    </td>

                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>{" "}
                </div>

                <Row>
                  <div ref={tableRef}>
                    <Col lg={12}>
                      <Card lg={12}>
                        <CardHeader className="card-header border-0">
                          <Row className="align-items-center gy-3">
                            <div className="col-sm">
                              <h5 className="card-title mb-0">Leads </h5>
                            </div>
                            <div className="col-sm-auto">
                              <div className="d-flex gap-1 flex-wrap">
                                <Link
                                  to="/leads-list"
                                  className="btn btn-soft-info btn-sm shadow-none"
                                >
                                  View All
                                </Link>
                              </div>
                            </div>
                          </Row>
                        </CardHeader>
                        <CardBody
                          className="pt-0"
                          style={{ height: "400px", overflowY: "auto" }}
                        >
                          <div className="table-responsive">
                            <table
                              role="table"
                              className="align-middle table-nowrap table table-hover"
                              style={{ width: "100%" }}
                            >
                              <thead className="table-light text-muted text-uppercase">
                                <tr>
                                  <th
                                    title="Toggle SortBy"
                                    style={{ cursor: "pointer" }}
                                  >
                                    #
                                  </th>
                                  <th>DATE & TIME</th>
                                  <th
                                    title="Toggle SortBy"
                                    style={{ cursor: "pointer" }}
                                  >
                                    Customer
                                  </th>
                                  <th>Phone</th>
                                  <th>City </th>
                                  <th>Product </th>
                                  <th>Value</th>
                                  <th>Tags</th>
                                  <th>Team Member</th>
                                  <th>Status</th>
                                  <th>Source</th>
                                </tr>
                              </thead>

                              <tbody
                                style={{
                                  backgroundColor: isHighlighted
                                    ? "yellow"
                                    : "transparent",
                                  transition: "background-color 0.5s ease",
                                }}
                              >
                                {LeadData.slice(0, 10).filter((temp) => {
                                  // If selectedUserId is not set, return all items
                                  if (!selectedUserId) {
                                    return true;
                                  }

                                  // Parse assigned_by and compare with selectedUserId if provided
                                  const assignedBy = JSON.parse(temp.assigned_by); // Parse it if it's a valid JSON array
                                  return parseInt(assignedBy[0]) === parseInt(selectedUserId); // Use the first element
                                }).map((item, index) => (
                                  <tr
                                    key={index}
                                    onClick={() => ViewPage(item.lead_id)}
                                  >
                                    <td>{index + 1}</td>
                                    <td >
                                      {/* {item.today_date.split("T")[0]} */}
                                      {new Date(
                                        item.minimum_due_date
                                      ).toLocaleDateString("en-GB")}
                                      <br />

                                      {new Date(
                                        item.minimum_due_date
                                      ).toLocaleTimeString("en-US", {
                                        hour12: true,
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}
                                    </td>
                                    <td>{item.customer_name}</td>
                                    <td>{item.customer_whatsapp_no}</td>
                                    <td>{item.city_name}</td>
                                    <td>{item.product_name}</td>
                                    <td>{item.value}</td>
                                    <td>
                                      {tagsData != null && item.tags
                                        ? tagsData.map((e) =>
                                          JSON.parse(item.tags).includes(
                                            e.tag_id
                                          ) ? (
                                            <div
                                              key={e.tag_id}
                                              className="p-1"
                                            >
                                              <button>{e.tag_name}</button>
                                            </div>
                                          ) : null
                                        )
                                        : "Loading..."}
                                    </td>
                                    <td>
                                      {assignedByData != null &&
                                        item.assigned_by ? (
                                        Array.isArray(JSON.parse(item.assigned_by)) ? (
                                          assignedByData.map((e) =>
                                            JSON.parse(item.assigned_by).includes(e.uid) ? (
                                              <div key={e.uid} className="p-1">
                                                <button className="bg-primary fw-bold text-white border border-primary">
                                                  {e.name}
                                                </button>
                                              </div>
                                            ) : null
                                          )
                                        ) : (
                                          <span>Invalid data format</span>
                                        )
                                      ) : (
                                        "Loading..."
                                      )}
                                    </td>

                                    <td>
                                      <div>
                                        <span
                                          className={`badge bg-info-subtle text-${item.lead_status_id === 1
                                            ? "info"
                                            : item.lead_status_id === 2
                                              ? "primary"
                                              : item.lead_status_id === 3
                                                ? "warning"
                                                : item.lead_status_id === 4
                                                  ? "success"
                                                  : item.lead_status_id === 5
                                                    ? "danger"
                                                    : ""
                                            } text-uppercase`}
                                        >
                                          {item.lead_status_name}
                                        </span>
                                      </div>
                                    </td>
                                    <td>{item.source_name}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </div>
                </Row>
                <Row>
                  {/* <Col lg={6}>
                    <Card lg={12}>
                      <CardHeader className="card-header border-0">
                        <div className="col-sm">
                          <h5 className="card-title mb-0">Shift Leads </h5>
                        </div>
                      </CardHeader>
                      <CardBody style={{ height: "400px", overflowY: "auto" }}>
                        {shiftedData.leads.map((e, i) => (
                          <>
                            <div
                              className="d-flex align-items-center "
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/leads-list/${e.slt_lead_id}/0`)
                              }
                            >
                              <div>
                                <h5 className="mb-1 fs-13 fw-normal text-dark">
                                  <b>{e.send_by_name} </b>
                                  Shifted The Lead to <b> {e.send_to_name}</b>
                                </h5>
                                <p className="mb-0 fs-12 text-muted">
                                  Reason :{" "}
                                  {e.slt_reason.replace(/<[^>]*>/g, "")}
                                </p>
                              </div>
                            </div>
                            <hr></hr>
                          </>
                        ))}
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={6}>
                    <Card lg={12}>
                      <CardHeader className="card-header border-0">
                        <Row className="align-items-center gy-3">
                          <div className="col-sm">
                            <h5 className="card-title mb-0">Shift Task </h5>
                          </div>
                          <div className="col-sm-auto">
                            <div className="d-flex gap-1 flex-wrap">
                              <button
                                type="button"
                                className="btn fw-bold btn-info add-btn"
                                id="create-btn"
                                onClick={() => navigate("/leads-list")}
                              >
                                <i className="ri-eye-fill align-bottom me-1"></i>{" "}
                                View All
                              </button>
                            </div>
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody style={{ height: "400px", overflowY: "auto" }}>
                        {shiftedData.task.map((e, i) => (
                          <>
                            <div
                              className="d-flex align-items-center "
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/task-list/${e.slt_task_id}/0`)
                              }
                            >
                              <div>
                                <h5 className="mb-1 fs-13 fw-normal text-dark">
                                  <b>{e.send_by_name} </b>
                                  Shifted The Task to <b> {e.send_to_name}</b>
                                </h5>
                                <p className="mb-0 fs-12 text-muted">
                                  Reason :{" "}
                                  {e.slt_reason.replace(/<[^>]*>/g, "")}
                                </p>
                              </div>
                            </div>
                            <hr></hr>
                          </>
                        ))}
                      </CardBody>
                    </Card>
                  </Col> */}
                </Row>

                <Row>
                  <Col lg={12}>
                    <Card lg={12}>
                      <CardHeader className="card-header border-0">
                        <Row className="align-items-center gy-3">
                          <div className="col-sm">
                            <h5 className="card-title mb-0">Leaves </h5>
                          </div>
                          <div className="col-sm-auto">
                            <div className="d-flex gap-1 flex-wrap">
                              <Link
                                to="/leave"
                                className="btn btn-soft-info btn-sm shadow-none"
                              >
                                View All
                              </Link>
                            </div>
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody
                        className="pt-0"
                        style={{ height: "400px", overflowY: "auto" }}
                      >
                        <div className="table-responsive">
                          <table
                            role="table"
                            className="align-middle table-nowrap table table-hover"
                          >
                            <thead className="table-light text-muted text-uppercase">
                              <tr>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  #
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  DATE
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  TEAM MEMBER
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  REASON
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  FORM DATE
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  TO DATE
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  STATUS
                                </th>

                                <th>STATUS DES</th>
                              </tr>
                            </thead>

                            <tbody>
                              {LeaveData.slice(0, 10)
                                .reverse()
                                .map((item, index) => (
                                  <tr key={index}>
                                    <td>
                                      <a
                                        className="fw-medium link-primary"
                                        href="/apps-ecommerce-order-details"
                                      >
                                        {index + 1}
                                      </a>
                                    </td>
                                    <td>
                                      {item.leaveCreatedAt != undefined
                                        ? new Date(
                                          item.leaveCreatedAt
                                        ).toLocaleDateString("en-GB")
                                        : ""}
                                    </td>
                                    <td>{item.name}</td>
                                    <td
                                      style={{
                                        maxHeight: "5px",
                                        maxWidth: "200px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "pre-wrap",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: item.leave_reason,
                                      }}
                                    ></td>

                                    <td>
                                      {" "}
                                      {new Date(
                                        item.from_date
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                    <td>
                                      {new Date(
                                        item.to_date
                                      ).toLocaleDateString("en-GB")}
                                    </td>
                                    {user && user.user.u_type === 1 ? (
                                      <td>
                                        {item.leave_status === "1" ? (
                                          <span
                                            className="badge bg-info-subtle text-success text-uppercase"
                                          // onClick={() => EditStatus(index)}
                                          >
                                            {/* <i className="ri-add-line align-bottom me-1"></i> */}
                                            Pending
                                          </span>
                                        ) : item.leave_status === "2" ? (
                                          <span
                                            className="badge bg-success-subtle text-success text-uppercase"
                                          // onClick={() => EditStatus(index)}
                                          >
                                            {/* <i className="ri-add-line align-bottom me-1"></i> */}
                                            Approved
                                          </span>
                                        ) : item.leave_status === "3" ? (
                                          <span className="badge bg-danger-subtle text-danger text-uppercase">
                                            {/* <i className="ri-add-line align-bottom me-1"></i> */}
                                            Rejected
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    ) : user.user.u_type === 2 ? (
                                      <td>
                                        {item.leave_status === "1" ? (
                                          <button
                                            type="button"
                                            className="btn fw-bold btn-info add-btn"
                                            id="create-btn"
                                          >
                                            {/* <i className="ri-add-line align-bottom me-1"></i> */}
                                            Pending
                                          </button>
                                        ) : item.leave_status === "2" ? (
                                          <button
                                            type="button"
                                            className="btn fw-bold btn-success add-btn"
                                            id="create-btn"
                                          >
                                            {/* <i className="ri-add-line align-bottom me-1"></i> */}
                                            Approved
                                          </button>
                                        ) : item.leave_status === "3" ? (
                                          <button
                                            type="button"
                                            className="btn fw-bold btn-danger add-btn"
                                            id="create-btn"
                                          >
                                            {/* <i className="ri-add-line align-bottom me-1"></i> */}
                                            Rejected
                                          </button>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    ) : (
                                      ""
                                    )}

                                    <td
                                      style={{
                                        maxHeight: "5px",
                                        maxWidth: "200px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "pre-wrap",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: item.leave_reject_reason,
                                      }}
                                    ></td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Card lg={6}>
                      <CardHeader className="card-header border-0">
                        <Row className="align-items-center gy-3">
                          <div className="col-sm">
                            <h5 className="card-title mb-0">Customer's </h5>
                          </div>
                          <div className="col-sm-auto">
                            <div className="d-flex gap-1 flex-wrap">
                              <Link
                                to="/customer-list"
                                className="btn btn-soft-info btn-sm shadow-none"
                              >
                                View All
                              </Link>
                            </div>
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody
                        className="pt-0"
                        style={{ height: "400px", overflowY: "auto" }}
                      >
                        <div className="table-responsive">
                          <table
                            role="table"
                            className="align-middle table-nowrap table table-hover"
                          >
                            <thead className="table-light text-muted text-uppercase">
                              <tr>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  #
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Customers Name
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Group
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Email
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Mobile No
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  City
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {getCustomerData
                                .slice(0, 10)
                                .map((item, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.customer_name}</td>
                                    <td>{item.customer_group_name}</td>
                                    <td>{item.customer_email}</td>
                                    <td>{item.customer_whatsapp_no}</td>
                                    <td>{item.city_name}</td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={6}>
                    <Card lg={6}>
                      <CardHeader className="card-header border-0">
                        <Row className="align-items-center gy-3">
                          <div className="col-sm">
                            <h5 className="card-title mb-0">Team Member's </h5>
                          </div>
                          <div className="col-sm-auto">
                            <div className="d-flex gap-1 flex-wrap">
                              <Link
                                to="/users-list"
                                className="btn btn-soft-info btn-sm shadow-none"
                              >
                                View All
                              </Link>
                            </div>
                          </div>
                        </Row>
                      </CardHeader>
                      <CardBody
                        className="pt-0"
                        style={{ height: "400px", overflowY: "auto" }}
                      >
                        <div className="table-responsive">
                          <table
                            role="table"
                            className="align-middle table-nowrap table table-hover"
                          >
                            <thead className="table-light text-muted text-uppercase">
                              <tr>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  #
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Profile
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Team member
                                </th>

                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Email
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Mobile No
                                </th>
                                <th
                                  title="Toggle SortBy"
                                  style={{ cursor: "pointer" }}
                                >
                                  Role
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {Employee.slice(0, 10).map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>
                                    {item.profile_photo != "/user/null" ? (
                                      <ImgCom
                                        imageUrl={
                                          IMG_API_URL + item.profile_photo
                                        }
                                        style={{
                                          height: "50px",
                                          width: "50px",
                                        }}
                                      />
                                    ) : (
                                      <D_img />
                                    )}
                                  </td>
                                  <td>{item.name}</td>
                                  <td>{item.email}</td>
                                  <td>{item.mobile_no}</td>
                                  <td>{item.role_name}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      {viewModalaStates === true ? (
        <LeadsView
          modalStates={viewModalaStates}
          setModalStates={() => {
            setviewModalaStates(false);
          }}
          checkchang={handleCallback}
          edit_data={FindData}
        />
      ) : (
        ""
      )}
      {viewModalaStates1 === true ? (
        <TaskView
          modalStates={viewModalaStates1}
          setModalStates={() => {
            setviewModalaStates1(false);
          }}
          checkchang={handleCallback}
          edit_data={FindData1}
        />
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export default DashboardEcommerce;
