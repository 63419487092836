import React, { useRef, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
  Badge,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Button,
} from "reactstrap";
import Flatpickr from "react-flatpickr";

import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import LeadsUpdate from "./LeadsUpdate";
import LeadsAdd from "./LeadsAdd";
import Select from "react-select";
import { useParams } from "react-router-dom";
import ShiftLeadAdd from "./ShiftLead/ShiftLeadAdd";
import ShiftLeadList from "./ShiftLead/ShiftLeadList";
import LeadsView from "./LeadsView";
import jsPDF from "jspdf";
import FolllowUpDetails from "./FollowUp/FollowUpDetails";
import Import from "./Import";

const LeadsList = () => {
  const [selected, setSelected] = useState("today");
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedLeadIndex, setSelectedLeadIndex] = useState(null);
  const [followUpDetails, setfollowUpDetails] = useState(false);
  const togglePopover = (index) => {
    setPopoverOpen(!popoverOpen);
    setSelectedLeadIndex(index);
  };

  const { type, status } = useParams();
  const { http, https, user, permission } = AuthUser();
  const [shiftModal, setShiftModal] = useState(false);
  const [shiftListModal, setshiftListModal] = useState(false);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const [filedList, setFiledList] = useState([])




  const [filterData, setfilterData] = useState({
    start_date:
      status != undefined
        ? new Date(currentYear, 0, 1).toISOString().split("T")[0] + " 00:00:00"
        : "",
    end_date:
      status != undefined
        ? new Date(currentYear, 11, 31).toISOString().split("T")[0] +
        " 23:59:59"
        : "",
    customer_name: 0,
    lead_id: type ? type : 0,
    status_name: status ? status : 0,
    assigned_by: 0,
    department: 0,
  });
  const handleSelect = (value) => {
    setfilterData({
      ...filterData,
      repeat_category: value,
    });
    setSelected(value);
  };
  const handelReapply = (value, leadvalue) => {
    let tempFilterData = filterData

    if (value) {
      tempFilterData = {
        ...filterData,
        task_redo_status: value,

      };
    }
    http
      .post(`/leads/filter`, tempFilterData)
      .then(function (response) {
        if (response.data.length > 0) {
          console.log("response.data", response.data);
          if (leadvalue != undefined) {
            if (leadvalue === 0) {
              const filteredData = response.data.filter((item) =>
                item.assigned_by.includes(user.user.uid)
              );
              SetLeadData(filteredData);
            } else if (leadvalue === 1) {
              const filteredData = response.data.filter(
                (item) => item.lead_created_by === user.user.uid
              );
              // console.log(filteredData);
              SetLeadData(filteredData);
            } else if (leadvalue === 2) {
              SetLeadData(response.data);
            }
          } else {
            SetLeadData(response.data);
          }
        } else {
          SetLeadData([]);
        }

        // console.log("Task Dat", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [modalStates, setModalStates] = useState(false);
  const [modalStatesimport, setModalStatesimport] = useState(false);
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);
  const [viewModalaStates, setviewModalaStates] = useState(false);
  const [followUpStates, setfollowUpStates] = useState(false);
  const [followUpList, setfollowUpList] = useState(false);

  const [counts, Setcounts] = useState(1);
  const [LeadData, SetLeadData] = useState([]);



  // infinity

  const [statusChange, setstatusChange] = useState([]);
  useEffect(() => {
    document.title = "lead | CRM";

    http.get("/formdynamic/list")
      .then((res) => {
        const filterData = res.data.filter((temp) => temp.formdynamic_category == 3)
        setFiledList(filterData);
      }).catch((e) => {
        console.log(e);

      })

    // Fetch the leads list
    http.get("/leads/list")
      .then((response) => {
        if (response.data.length > 0) {
          setstatusChange(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }, [counts]);

  const [tagsData, settagsData] = useState([]);
  const gettagsData = async () => {
    http
      .get("/tags/list")
      .then((response) => {
        if (response.data.length > 0) {
          settagsData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const ChangeStatus = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
  };
  const [assignedByData, setassignedByData] = useState([]);

  const [selectedRoleIds, setSelectedRoleIds] = useState((user.user.u_type == 2 && user.user.user_behave == 1) ? user.user.user_role_id : null);
  const [selectedRoleUserIds, setSelectedRoleUserIds] = useState([]);
  const [statuscheckforAssignedBy, setstatuscheckforAssignedBy] = useState(0);
  const [role, setRole] = useState([]);


  const getRole = async () => {
    try {
      const response = await http.get("/role/list");
      const filteredData = user && (user.user.u_type === 1 || user.user.u_type === 2)
        ? response.data.filter(
          (role) =>
            parseInt(role.role_created_user_id) === user.user.user_user_id ||
            parseInt(role.role_created_user_id) === user.user.uid
        )
        : response.data.filter(
          (role) => parseInt(role.role_created_user_id) === user.user.uid
        );
      setRole(filteredData);
    } catch (error) {
      console.log(error);
    }
  };
  const getassignedByData = async () => {

    const res = await http.get("/franchisebusiness/list");
    const filteredEmployee = res.data.filter(
      (e) => e.u_type !== 3 && e.u_type !== 5
    );
    setassignedByData(filteredEmployee);
  };
  const OverdueStatus = () => {
    http
      .get(`/leads/list`)
      .then((res) => { })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  };
  useEffect(() => {
    OverdueStatus();
  }, []);
  const handleFilter = async (start_date, end_date, leadvalue) => {
    let tempFilterData = filterData;

    if (
      start_date != "" &&
      end_date != "" &&
      start_date != undefined &&
      end_date != undefined
    ) {
      tempFilterData = {
        ...filterData,
        start_date: start_date,
        end_date: end_date,
      };
    }
    // console.log("tempFilterData: ", tempFilterData);

    http
      .post(`/leads/filter`, tempFilterData)
      .then(function (response) {
        if (response.data.length > 0) {
          if (leadvalue != undefined) {
            if (leadvalue === 0) {
              const filteredData = response.data.filter((item) =>
                item.assigned_by.includes(user.user.uid)
              );
              SetLeadData(filteredData);
            } else if (leadvalue === 1) {
              const filteredData = response.data.filter(
                (item) => item.task_created_by === user.user.uid
              );
              // console.log(filteredData);
              SetLeadData(filteredData);
            } else if (leadvalue === 2) {
              SetLeadData(response.data);
            }
          } else {
            SetLeadData(response.data);
          }
        } else {
          SetLeadData([]);
        }

        // console.log("Task Dat", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
    OverdueStatus();
  };
  const [customerData, setcustomerData] = useState([]);
  const getcustomerData = async () => {
    http
      .get("/customer/list")
      .then((response) => {
        if (response.data.length > 0) {
          setcustomerData(response.data);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    gettagsData();
    getassignedByData();
    getcustomerData();
    document.title = "Lead | CRM";
    getRole();
    handleFilter();
  }, [counts && filterData]);
  const fetchData = () => {
    Setcounts(counts + 1);
  };
  //   Delete Aleart
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, SetID] = useState();
  const onClickDelete = (data) => {
    SetID(data);
    setDeleteModal(true);
  };
  const handleDeleteOrder = (data) => {
    if (data._reactName == "onClick") {
      http
        .delete(`/lead/delete/${ID}`)
        .then(function (response) {
          if (response.data.status == 0) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          Setcounts(counts + 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    SetLeadData([]);
    setDeleteModal(false);
  };

  // shortcuts for opening add form
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (
        (event.altKey && event.key === "a") ||
        (event.altKey && event.key === "A")
      ) {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //   end Alert
  const handleCallback = (data, status) => {
    SetLeadData([]);
    if (status == 1) {
      Setcounts(counts + 1);
      setModalStates(false);
      setModalStatesimport(false);
      setUpdateModalStates(false);
      setShiftModal(false);
      toast.success(data);
    } else {
      toast.warn(data);
      Setcounts(counts + 1);
    }
    handleFilter();
  };
  // Edit Data
  const [FindData, SetFind] = useState([]);
  const EditUpdate = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };
  const ViewPage = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setviewModalaStates(!viewModalaStates);
  };
  const transeferData = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setShiftModal(!shiftModal);
  };
  const FollowUp = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setfollowUpStates(!followUpStates);
  };
  const FollowListed = (index) => {
    let FindArray = LeadData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setfollowUpList(!followUpList);
  };
  const tableRef = useRef(null);
  const downloadPDF = () => {
    const table = tableRef.current;
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    // Create a new jsPDF instance with landscape orientation
    const pdf = new jsPDF({
      orientation: "landscape",
    });

    // Add the table to the PDF using autoTable
    pdf.autoTable({ html: table });

    // Output the PDF as a blob
    const blob = pdf.output("blob");
    const url = URL.createObjectURL(blob);

    // Create a link element to download the PDF
    const link = document.createElement("a");
    link.href = url;
    link.download = "leads_list.pdf";

    // Append the link to the document, trigger the download, and then remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const downloadCSV = () => {
    const table = tableRef.current;
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    const csvContent = Array.from(table.querySelectorAll("tr"))
      .map((row) =>
        Array.from(row.children)
          .map((cell) => `"${cell.innerText.trim()}"`)
          .join(",")
      )
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "lead_list.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const [file, setFile] = useState(null);
  // const [message, setMessage] = useState('');

  // const onFileChange = (e) => {
  //   setFile(e.target.files[0]);
  // };

  // const onFileUpload = async () => {
  //   if (!file) {
  //     setMessage('Please select a file first.');
  //     return;
  //   }

  //   const formData = new FormData();
  //   formData.append('file', file);

  //   try {

  //     https
  //       .post("/leads/upload", formData)
  //       .then(function (response) {
  //         // props.checkchang(response.data.message, response.data.status);
  //         if (response.data.status === 1) {
  //           toast.success(response.data.message);
  //         } else {
  //           toast.success(response.data.message);

  //         }

  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //     const response = await https.post('/leads/upload', formData);
  //     setMessage(response.data.message);
  //   } catch (error) {
  //     setMessage('Error uploading file');
  //     console.error('Error:', error);
  //   }
  // };
  // const [filedList, setFiledList] = useState([])
  // const [selectedLeadIndex, setSelectedLeadIndex] = useState(null);
  const [selectedUserName, setSelectedUserName] = useState({ value: 0, label: "Select All" });
  const filteredLeadData = filedList.filter((lead) => {
    return selectedUserName.value === 0 || lead.lead_id === selectedUserName.value;
  });
  const parseLeadFields = (fields) => {
    try {
      return JSON.parse(fields);
    } catch (e) {
      return {};
    }
  };
  const dynamicHeaders = React.useMemo(() => {
    const allHeaders = filteredLeadData.map((item) => {
      try {
        const fields = JSON.parse(item.lead_fields || "{}");
        return Object.keys(fields);
      } catch (error) {
        console.error("Error parsing lead_fields:", error);
        return [];
      }
    });
    return [...new Set(allHeaders.flat())];
  }, [filteredLeadData]);



  return (
    <div className="page-content">
      <table
        ref={tableRef}
        style={{ display: "none" }}
        role="table"
        id="tableToPrint"
        className="text-center bg-white table"
      >
        <thead className="table-light text-muted text-uppercase">
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Time</th>
            <th>Customer</th>
            <th>Phone</th>
            <th>City</th>
            <th>Product </th>
            <th>Source</th>
            <th>Value</th>
            <th>Tags</th>
            <th>Assigned By</th>
            <th>Assigned To</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {LeadData.length > 0
            ? LeadData.map((item, index) => (
              <tr title="Open Lead" style={{ cursor: "pointer" }} key={index}>
                <td>
                  <a
                    className="fw-medium link-primary"
                    href="/apps-ecommerce-order-details"
                  >
                    {index + 1}
                  </a>
                </td>
                <td >
                  {/* {item.today_date.split("T")[0]} */}
                  {new Date(
                    item.minimum_due_date
                  ).toLocaleDateString("en-GB")}
                  <br />
                </td>
                <td >
                  {new Date(
                    item.minimum_due_date
                  ).toLocaleTimeString("en-US", {
                    hour12: true,
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </td>
                <td>{item.customer_name}</td>
                <td>{item.customer_whatsapp_no}</td>
                <td>{item.city_name}</td>
                <td>{item.product_name}</td>

                <td>{item.source_name}</td>
                <td>{item.value}</td>
                <td>
                  {tagsData != null && item.tags
                    ? tagsData.map((e) =>
                      JSON.parse(item.tags).includes(e.tag_id) ? (
                        <Col key={e.tag_id} lg={6}>
                          <Badge className="badge-gradient-info ">
                            {e.tag_name}
                          </Badge>
                        </Col>
                      ) : null
                    )
                    : "Loading..."}
                </td>
                <td>{item.name}</td>
                <td>
                  {assignedByData != null && item.assigned_by ? (
                    assignedByData.map((e) => {
                      const assignedByList = JSON.parse(item.assigned_by || "[]");
                      return Array.isArray(assignedByList) && assignedByList.includes(e.uid) ? (
                        <div key={e.uid} className="p-1">
                          <button className="bg-primary fw-bold text-white border border-primary">
                            {e.name}
                          </button>
                        </div>
                      ) : null;
                    })
                  ) : (
                    "Loading..."
                  )}
                </td>

                <td>
                  <div>
                    <div className="hstack flex-wrap gap-2">
                      <Badge
                        color={
                          item.lead_status_color
                        }
                      >
                        {item.lead_status_name}
                      </Badge>
                    </div>
                  </div>
                </td>
              </tr>
            ))
            : ""}
        </tbody>
      </table>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <div className="text-center">
                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="today"
                    autoComplete="off"
                    checked={selected === "today"}
                    onChange={() => {
                      let today = new Date();
                      let formattedDate = today.toISOString().split("T")[0]; // Get today's date in 'YYYY-MM-DD' format
                      let start_date = formattedDate + " 00:00:00"; // Set start date to today's beginning
                      let end_date = formattedDate + " 23:59:59"; // Set end date to today's end

                      handleFilter(start_date, end_date); // Pass both start_date and end_date as today's date
                      handleSelect("today");
                    }}
                  />
                  <label
                    className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "today" ? "checked" : ""
                      }`}
                    htmlFor="today"
                  >
                    Today
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="yesterday"
                    autoComplete="off"
                    checked={selected === "yesterday"}
                    onChange={() => {
                      let today = new Date();
                      let yesterday = new Date(today);
                      yesterday.setDate(today.getDate() - 1); // Set date to yesterday

                      let formattedYesterday = yesterday
                        .toISOString()
                        .split("T")[0]; // Get yesterday's date in 'YYYY-MM-DD' format
                      let start_date = formattedYesterday + " 00:00:00"; // Set start date to yesterday's beginning
                      let end_date = formattedYesterday + " 23:59:59"; // Set end date to yesterday's end

                      handleFilter(start_date, end_date); // Pass both start_date and end_date as yesterday's date
                      handleSelect("yesterday");
                    }}
                  />
                  <label
                    className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "yesterday" ? "checked" : ""
                      }`}
                    htmlFor="yesterday"
                  >
                    Yesterday
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="thisWeek"
                    autoComplete="off"
                    checked={selected === "thisWeek"}
                    onChange={() => {
                      let today = new Date();
                      let dayOfWeek = today.getDay(); // Get the current day of the week (0 - Sunday, 6 - Saturday)

                      let startOfWeek = new Date(today); // Clone today's date
                      startOfWeek.setDate(today.getDate() - dayOfWeek); // Set to the start of the week (Sunday)

                      let endOfWeek = new Date(today); // Clone today's date
                      endOfWeek.setDate(today.getDate() + (6 - dayOfWeek)); // Set to the end of the week (Saturday)

                      let formattedStartOfWeek =
                        startOfWeek.toISOString().split("T")[0] + " 00:00:00"; // Start of the week
                      let formattedEndOfWeek =
                        endOfWeek.toISOString().split("T")[0] + " 23:59:59"; // End of the week

                      handleFilter(formattedStartOfWeek, formattedEndOfWeek); // Pass the start and end dates of the current week
                      handleSelect("thisWeek");
                    }}
                  />
                  <label
                    className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "thisWeek" ? "checked" : ""
                      }`}
                    htmlFor="thisWeek"
                  >
                    This Week
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="thisMonth"
                    autoComplete="off"
                    checked={selected === "thisMonth"}
                    onChange={() => {
                      let today = new Date();
                      let startOfMonth = new Date(
                        today.getFullYear(),
                        today.getMonth(),
                        1
                      );
                      let endOfMonth = new Date(
                        today.getFullYear(),
                        today.getMonth() + 1,
                        0
                      );
                      let formattedStartOfMonth =
                        startOfMonth.toISOString().split("T")[0] +
                        " 00:00:00"; // Start of the month
                      let formattedEndOfMonth =
                        endOfMonth.toISOString().split("T")[0] + " 23:59:59"; // End of the month
                      handleFilter(
                        formattedStartOfMonth,
                        formattedEndOfMonth
                      ); // Pass the start and end dates of the current month
                      handleSelect("thisMonth");
                    }}
                  />
                  <label
                    className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "thisMonth" ? "checked" : ""
                      }`}
                    htmlFor="thisMonth"
                  >
                    This Month
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="lastMonth"
                    autoComplete="off"
                    checked={selected === "lastMonth"}
                    onChange={() => {
                      let today = new Date();

                      // Get the last month
                      let lastMonth = new Date(today);
                      lastMonth.setMonth(today.getMonth() - 1);

                      // Get the start of the last month (1st day of the last month)
                      let startOfLastMonth = new Date(
                        lastMonth.getFullYear(),
                        lastMonth.getMonth(),
                        1
                      );

                      // Get the end of the last month (last day of the last month)
                      let endOfLastMonth = new Date(
                        lastMonth.getFullYear(),
                        lastMonth.getMonth() + 1,
                        0
                      ); // The 0th day of the current month gives the last day of the last month

                      let formattedStartOfLastMonth =
                        startOfLastMonth.toISOString().split("T")[0] +
                        " 00:00:00"; // Start of last month
                      let formattedEndOfLastMonth =
                        endOfLastMonth.toISOString().split("T")[0] +
                        " 23:59:59"; // End of last month

                      handleFilter(
                        formattedStartOfLastMonth,
                        formattedEndOfLastMonth
                      ); // Pass the start and end dates for last month
                      handleSelect("lastMonth");
                    }}
                  />
                  <label
                    className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "lastMonth" ? "checked" : ""
                      }`}
                    htmlFor="lastMonth"
                  >
                    Last Month
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="nextWeek"
                    autoComplete="off"
                    checked={selected === "nextWeek"}
                    onChange={() => {
                      let today = new Date();
                      let dayOfWeek = today.getDay(); // Get the current day of the week (0 - Sunday, 6 - Saturday)

                      // Get the start of next week (next Sunday's date)
                      let startOfNextWeek = new Date(today);
                      startOfNextWeek.setDate(
                        today.getDate() + (7 - dayOfWeek)
                      ); // Move to next Sunday

                      // Get the end of next week (next Saturday's date)
                      let endOfNextWeek = new Date(startOfNextWeek);
                      endOfNextWeek.setDate(startOfNextWeek.getDate() + 6); // Add 6 days to get to next Saturday

                      let formattedStartOfNextWeek =
                        startOfNextWeek.toISOString().split("T")[0] +
                        " 00:00:00"; // Start of next week
                      let formattedEndOfNextWeek =
                        endOfNextWeek.toISOString().split("T")[0] +
                        " 23:59:59"; // End of next week

                      handleFilter(
                        formattedStartOfNextWeek,
                        formattedEndOfNextWeek
                      ); // Pass the start and end dates of next week
                      handleSelect("nextWeek");
                    }}
                  />
                  <label
                    className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "nextWeek" ? "checked" : ""
                      }`}
                    htmlFor="nextWeek"
                  >
                    Next Week
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="nextMonth"
                    autoComplete="off"
                    checked={selected === "nextMonth"}
                    onChange={() => {
                      let today = new Date();

                      // Get the start of next month (1st day of the next month)
                      let startOfNextMonth = new Date(
                        today.getFullYear(),
                        today.getMonth() + 1,
                        1
                      );

                      // Get the end of next month (last day of the next month)
                      let endOfNextMonth = new Date(
                        today.getFullYear(),
                        today.getMonth() + 2,
                        0
                      ); // The 0th day of the month after the next gives the last day of next month

                      let formattedStartOfNextMonth =
                        startOfNextMonth.toISOString().split("T")[0] +
                        " 00:00:00"; // Start of next month
                      let formattedEndOfNextMonth =
                        endOfNextMonth.toISOString().split("T")[0] +
                        " 23:59:59"; // End of next month

                      handleFilter(
                        formattedStartOfNextMonth,
                        formattedEndOfNextMonth
                      ); // Pass the start and end dates of next month
                      handleSelect("nextMonth");
                    }}
                  />
                  <label
                    className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "nextMonth" ? "checked" : ""
                      }`}
                    htmlFor="nextMonth"
                  >
                    Next Month
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="allTime"
                    autoComplete="off"
                    checked={selected === "allTime"}
                    onChange={() => {
                      handleFilter();
                      handleSelect("allTime");
                    }}
                  />
                  <label
                    className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "allTime" ? "checked" : ""
                      }`}
                    htmlFor="allTime"
                  >
                    All Time
                  </label>

                  <input
                    type="radio"
                    className="btn-check"
                    name="options"
                    id="Re_Apply"
                    autoComplete="off"
                    checked={selected === "Re_Apply"}
                    onChange={() => {
                      handelReapply(1);
                      handleSelect("Re_Apply");
                    }}
                  />
                  {/* <label
                      className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${
                        selected === "Re_Apply" ? "checked" : ""
                      }`}
                      htmlFor="Re_Apply"
                    >
                      Re-Apply
                    </label> */}
                </div>
                <Row className="align-items-center gy-3">
                  <div className="text-center pt-2 pb-4">
                    <h2 className="fw-bold mb-0"> {filterData.department > 0 ? role.filter((data) => data.role_id == filterData.department)[0].role_name : "All "} Lead's</h2>
                  </div>
                </Row>
                <Row className="justify-content-center align-items-center d-flex">


                  {user.user.u_type === 1 ? (
                    <Col lg="auto" sm="12">
                      <div className="fw-bold">
                        Department
                      </div>

                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused ? 'white' : 'white',
                          }),
                        }}
                        className="fw-bold"
                        options={[
                          { value: 0, label: "Select All" },
                          ...role.filter(item => item.role_created_for == null).map((e) => ({
                            value: e.role_id,
                            label: e.role_name,
                          }))]} // Map the role data to options
                        onChange={(e) => {

                          setSelectedRoleIds(e.value); // Update the selected role ID(s)

                          setstatuscheckforAssignedBy(statuscheckforAssignedBy + 1)
                          setfilterData({
                            ...filterData,
                            department: e.value
                          })


                        }}
                      />
                    </Col>
                  ) : ""}
                  {user.user.u_type === 1 ||
                    (user.user.u_type === 2 && user.user.user_behave == 1) ||
                    user.user.u_type === 3 ? (
                    <Col lg="auto" sm="12">
                      <div className="fw-bold" style={{ width: "150px" }}>
                        Assigned to
                      </div>
                      <Select
                        className="fw-bold"
                        placeholder="Assigned"
                        options={[
                          { value: 0, label: "Select All" }, // "Select All" option at the top
                          {
                            label: 'Managers', // Group heading for Managers
                            options: assignedByData
                              .filter((e) => (e.user_role_id == selectedRoleIds || e.role_created_for == selectedRoleIds) && e.user_behave === 1 && (selectedRoleUserIds.length === 0 || selectedRoleUserIds.includes(e.uid.toString()))) // Filter for Managers
                              .map((e, i) => ({
                                value: e.uid,
                                label: e.name, // Show the name under Managers group
                                index: i,
                                behaveType: e.user_behave
                              })),
                          },
                          {
                            label: 'Employees', // Group heading for Employees
                            options: assignedByData
                              .filter((e) => (e.user_role_id == selectedRoleIds || e.role_created_for == selectedRoleIds) && e.user_behave === 0 && (selectedRoleUserIds.length === 0 || selectedRoleUserIds.includes(e.uid.toString()))) // Filter for Employees
                              .map((e, i) => ({
                                value: e.uid,
                                label: e.name, // Show the name under Employees group
                                index: i,
                                behaveType: e.user_behave
                              })),
                          },
                        ]}
                        onChange={(e) => {
                          setfilterData({
                            ...filterData,
                            assigned_by: e.value,
                          });
                        }}
                      ></Select>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col lg="auto" sm="12">
                    <div className="fw-bold" style={{ width: "150px" }}>
                      By Customer
                    </div>
                    <Select
                      className="fw-bold"
                      placeholder="Customer"
                      options={[
                        { value: 0, label: "Select All" },
                        ...customerData.map((e) => ({
                          value: e.customer_id,
                          label: e.customer_name,
                        })),
                      ]}
                      onChange={(e) => {
                        setfilterData({
                          ...filterData,
                          customer_name: e.value,
                        });
                      }}
                    ></Select>
                  </Col>
                  <Col lg="auto" sm="12">
                    <div className="fw-bold" style={{ width: "150px" }}>
                      By Status
                    </div>
                    <Select
                      className="fw-bold"
                      placeholder="Status"
                      options={[
                        { value: 0, label: "Select All" },
                        ...statusChange.map((e) => ({
                          value: e.lead_status_id,
                          label: e.lead_status_name,
                        })),
                      ]}
                      onChange={(e) => {
                        setfilterData({
                          ...filterData,
                          status_name: e.value,
                        });
                      }}
                    ></Select>
                  </Col>
                  <Col lg="auto" sm="12">
                    <div className="fw-bold">Start Date</div>
                    <Flatpickr
                      className="form-control fw-bold"
                      options={{
                        dateFormat: "d/m/Y",
                        defaultDate: "today",
                      }}
                      style={{
                        width: "110px",
                      }}
                      name="payment_date"
                      placeholder="Enter start date"
                      onChange={(selectedDates) => {
                        const selectedDate = selectedDates[0];
                        // Convert to YYYY-MM-DD format
                        const year = selectedDate.getFullYear();
                        const month = (selectedDate.getMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        const day = selectedDate
                          .getDate()
                          .toString()
                          .padStart(2, "0");
                        const formattedDate = `${year}-${month}-${day} 00:00:00`;

                        setfilterData({
                          ...filterData,
                          start_date: formattedDate,
                        });
                        Setcounts(counts + 1);
                      }}
                    />
                  </Col>

                  <Col lg="auto" sm="12">
                    <div className="fw-bold">End Date</div>
                    <Flatpickr
                      className="form-control fw-bold"
                      options={{
                        dateFormat: "d/m/Y",
                        defaultDate: "today",
                      }}
                      style={{
                        width: "110px",
                      }}
                      name="payment_date"
                      placeholder="Enter start date"
                      onChange={(selectedDates) => {
                        const selectedDate = selectedDates[0];
                        // Convert to YYYY-MM-DD format
                        const year = selectedDate.getFullYear();
                        const month = (selectedDate.getMonth() + 1)
                          .toString()
                          .padStart(2, "0");
                        const day = selectedDate
                          .getDate()
                          .toString()
                          .padStart(2, "0");
                        const formattedDate = `${year}-${month}-${day} 23:59:59`;

                        setfilterData({
                          ...filterData,
                          end_date: formattedDate,
                        });
                        Setcounts(counts + 1);
                      }}
                    />
                  </Col>
                </Row>


                <Row className="justify-content-end mt-2">
                  <Col lg="auto" sm="12" className="me-0">
                    <button
                      className="btn mt-3 btn-success "
                      onClick={(e) => {
                        handleFilter();
                      }}
                    >
                      Search
                    </button>
                  </Col>

                  <Col
                    lg="auto"
                    sm="11"
                    className="me-0"
                    style={{ marginLeft: "-15px" }}
                  >

                    <button
                      type="button"
                      className="btn mt-3 btn-info"
                      onClick={() => downloadCSV()}
                    >
                      <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                      Export
                    </button>
                  </Col>
                  <Col
                    lg="auto"
                    sm="11"
                    className="me-0"
                    style={{ marginLeft: "-15px" }}
                  >

                    <button
                      type="button"
                      className="btn mt-3 btn-danger"
                      onClick={() => downloadPDF()}
                    >
                      <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                      Pdf
                    </button>
                  </Col>
                  <Col
                    lg="auto"
                    sm="11"
                    className="me-0"
                    style={{ marginLeft: "-15px" }}
                  >

                    {type != "report"
                      ? permission.find(
                        (permission) =>
                          permission.permission_category === "LEAD" &&
                          permission.permission_path === "2"
                      ) && (
                        <button
                          type="button"
                          className="btn mt-3 fw-bold btn-success add-btn"
                          id="create-btn"
                          onClick={() => setModalStates(!modalStates)}
                        >
                          <i className="ri-add-line align-bottom me-1"></i>{" "}
                          Add Lead
                        </button>
                      )
                      : ""}
                  </Col>
                  {/* <div>
                        <button
                          type="button"
                          className="btn mt-3 btn-info"
                          onClick={() =>
                            setModalStatesimport(!modalStatesimport)
                          }
                        >
                          <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                          Import Data
                        </button>
                      </div> */}
                  <Col lg="auto" sm="12" style={{ marginLeft: "-15px" }}>

                    {/* {user.user.u_type === 1 || user.user.u_type === 2 ? ( */}
                    <div className="btn btn-info mt-3">
                      <div
                        onClick={() => {
                          setshiftListModal(true);
                        }}
                      >
                        <i className="ri-eye-line align-bottom me-1"></i>{" "}
                        Shifted Leads
                      </div>
                    </div>
                    {/* ) : (
                          ""
                        )} */}
                  </Col>

                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  {permission.find(
                    (permission) =>
                      permission.permission_category === "LEAD" &&
                      permission.permission_path === "1"
                  ) && (
                      <div className="table-responsive">
                        <table
                          role="table"
                          className="align-middle table-nowrap table table-hover"
                        >
                          <thead className="table-light text-muted text-uppercase">
                            <tr>
                              <th style={{ cursor: "pointer" }}>#</th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Date & time
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Customer
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Phone
                              </th>
                              {/* <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                City
                              </th> */}
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Product{" "}
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  LeadData.slice().reverse();
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Source
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Value
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Tags
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Assigned BY
                              </th>
                      
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Assigned TO
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Follow Up
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Status
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Shift
                              </th>
                              {filedList.filter((temp) => temp.formdynamic_status == 4 || temp.formdynamic_status == 3).map((item, index) => (
                                <th key={index}>{item.formdynamic_name}</th>
                              ))}
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetLeadData(LeadData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>

                            {
                              LeadData.filter((item, index, self) =>
                                index === self.findIndex((t) => (
                                  t.lead_id === item.lead_id
                                ))
                              ).map((item, index) => (
                                <tr
                                  title="Open Task"
                                  style={{
                                    cursor: "pointer",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                    transition: "box-shadow 0.3s ease",
                                  }}
                                  onMouseEnter={(e) => {
                                    e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
                                    e.currentTarget.style.backgroundColor = "#f0f0f0"; // Optional: highlight background on hover
                                  }}
                                  onMouseLeave={(e) => {
                                    e.currentTarget.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.1)";
                                    e.currentTarget.style.backgroundColor = ""; // Reset background color
                                  }}
                                  key={index}
                                >
                                  <td onClick={() => ViewPage(index)}>
                                    <a
                                      className="fw-medium link-primary"
                                      href="/apps-ecommerce-order-details"
                                    >
                                      {index + 1}
                                    </a>
                                  </td>
                                  <td onClick={() => ViewPage(index)}>
                                    {/* {item.today_date.split("T")[0]} */}
                                    {new Date(
                                      item.minimum_due_date
                                    ).toLocaleDateString("en-GB")}
                                    <br />

                                    {new Date(
                                      item.minimum_due_date
                                    ).toLocaleTimeString("en-US", {
                                      hour12: true,
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                                  </td>
                                  <td onClick={() => ViewPage(index)}>
                                    {item.customer_name}
                                  </td>
                                  <td onClick={() => ViewPage(index)}>
                                    {item.customer_whatsapp_no}
                                  </td>
                                  {/* <td onClick={() => ViewPage(index)}>
                                    {item.city_name}
                                  </td> */}
                                  <td onClick={() => ViewPage(index)}>
                                    {item.product_name}
                                  </td>
                                  <td onClick={() => ViewPage(index)}>
                                    {item.source_name}
                                  </td>
                                  <td onClick={() => ViewPage(index)}>
                                    {item.value}
                                  </td>
                                  <td onClick={() => ViewPage(index)}>
                                    {tagsData != null && item.tags ? (
                                      tagsData.map((e) => {
                                        const parsedTags = JSON.parse(item.tags || "[]");
                                        return Array.isArray(parsedTags) && parsedTags.includes(e.tag_id) ? (
                                          <Col key={e.tag_id} lg={6}>
                                            <Badge className="badge-gradient-info">
                                              {e.tag_name}
                                            </Badge>
                                          </Col>
                                        ) : null;
                                      })
                                    ) : (
                                      "Loading..."
                                    )}
                                  </td>

                                  <td>{item.name}</td>
                                 
                              
                                  
                                  <td onClick={() => ViewPage(index)}>
                                    {assignedByData != null && item.assigned_by ? (
                                      assignedByData.map((e) => {
                                        try {
                                          // Parse item.assigned_by and ensure it's an array
                                          const assignedByList = JSON.parse(item.assigned_by || "[]");
                                          if (Array.isArray(assignedByList) && assignedByList.includes(e.uid)) {
                                            return (
                                              <div key={e.uid} className="p-1">
                                                <button className="bg-primary fw-bold text-white border border-primary">
                                                  {e.name}
                                                </button>
                                              </div>
                                            );
                                          }
                                        } catch (err) {
                                          console.error("Error parsing item.assigned_by:", err, item.assigned_by);
                                        }
                                        return null;
                                      })
                                    ) : (
                                      "Loading..."
                                    )}
                                  </td>

                                  <td>
                                    <button
                                      className="btn border-0"
                                      style={{
                                        background:
                                          "linear-gradient(to right, #FF69B4, #FF1493)",
                                        color: "white",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        SetFind(
                                          LeadData.filter((_, i) => i === index)
                                        );
                                        setfollowUpDetails(true);
                                      }}
                                    >
                                      Follow Up
                                    </button>
                                  </td>
                                  <td>
                                    <div onClick={() => ChangeStatus(index)}>
                                      <div className="hstack flex-wrap gap-2">
                                        <Button
                                          color={
                                            item.lead_status_color
                                          }
                                          className="btn-label right "
                                        >
                                          {" "}
                                          <i
                                            id={`PopoverDismissible${index}`}
                                            className=" ri-arrow-down-s-line label-icon align-middle fs-16 ms-2"
                                            onClick={() => togglePopover(index)}
                                          ></i>{" "}
                                          {item.lead_status_name}
                                        </Button>

                                        <UncontrolledPopover
                                          trigger="legacy"
                                          placement="bottom-start"
                                          target={`PopoverDismissible${index}`}
                                          isOpen={
                                            popoverOpen &&
                                            selectedLeadIndex === index
                                          }
                                          toggle={() => togglePopover(index)}
                                        >
                                          {statusChange.map((e, i) => (
                                            <PopoverBody key={i}>
                                              <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                  https
                                                    .put("/leads/update", {
                                                      lead_id: FindData.lead_id,
                                                      status: e.lead_status_id,
                                                    })
                                                    .then(function (response) {
                                                      handleFilter();
                                                      toast.success(
                                                        "Lead status updated successfully!"
                                                      );
                                                      togglePopover();
                                                    })
                                                    .catch(function (err) {
                                                      console.log(err);
                                                    });
                                                }}
                                              >
                                                {e.lead_status_name}
                                              </div>
                                            </PopoverBody>
                                          ))}
                                        </UncontrolledPopover>
                                      </div>
                                    </div>
                                  </td>
                                  <td>
                                    <ul className="list-inline hstack gap-2 mb-0">
                                      <li className="list-inline-item edit">
                                        <button
                                          className="btn btn-info border-0"
                                          onClick={() => {
                                            transeferData(index);
                                          }}
                                          style={{
                                            background:
                                              "linear-gradient(to right, #ff7e5f, #feb47b)",
                                          }}
                                        >
                                          Shift{" "}
                                        </button>
                                      </li>
                                    </ul>
                                  </td>

                                  {filedList
                                    .filter((temp) => temp.formdynamic_status == 4 || temp.formdynamic_status == 3)
                                    .map((data, index) => {
                                      let LeadObject;
                                      try {
                                        // Attempt to parse the JSON string
                                        LeadObject = item?.lead_fields ? JSON.parse(item.lead_fields) : null;
                                      } catch (error) {
                                        console.error("Invalid JSON in lead_fields:", item.lead_fields);
                                        return (
                                          <td key={index} className="text-danger">
                                            Invalid Data
                                          </td>
                                        );
                                      }

                                      const dynamicPath = data?.formdynamic_path;
                                      const value = LeadObject && dynamicPath ? LeadObject[dynamicPath] : null;

                                      const formatDate = (date) => {
                                        const d = new Date(date);
                                        if (isNaN(d)) return "Invalid Date";
                                        const day = String(d.getDate()).padStart(2, "0");
                                        const month = String(d.getMonth() + 1).padStart(2, "0");
                                        const year = String(d.getFullYear()).slice(-2);
                                        return `${day}/${month}/${year}`;
                                      };

                                      return (
                                        <td key={index}>
                                          {data?.formdynamic_type == 2
                                            ? value
                                              ? formatDate(value)
                                              : "Invalid Date"
                                            : value || "N/A"}
                                        </td>
                                      );
                                    })}

                                  <td>
                                    <ul className="list-inline hstack gap-2 d-flex justify-content-center mb-0">
                                      <li className="list-inline-item edit">
                                        {permission.find(
                                          (permission) =>
                                            permission.permission_category ===
                                            "LEAD" &&
                                            permission.permission_path === "3"
                                        ) && (
                                            <button
                                              className="text-primary border-0 bg-transparent"
                                              onClick={() => EditUpdate(index)}
                                            >
                                              <i className="ri-pencil-fill fs-16" />
                                            </button>
                                          )}
                                      </li>
                                    </ul>
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </div>
                    )}
                </div>

                {modalStates === true ? (
                  <LeadsAdd
                    modalStates={modalStates}
                    setModalStates={() => {
                      setModalStates(false);
                    }}
                    checkchang={handleCallback}
                  />
                ) : (
                  ""
                )}
                {modalStatesimport === true ? (
                  <Import
                    modalStates={modalStatesimport}
                    setModalStates={() => {
                      setModalStatesimport(false);
                    }}
                    checkchang={handleCallback}
                  />
                ) : (
                  ""
                )}
                {UpdatemodalStates === true ? (
                  <LeadsUpdate



                    modalStates={UpdatemodalStates}
                    setModalStates={() => {
                      setUpdateModalStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {viewModalaStates === true ? (
                  <LeadsView
                    modalStates={viewModalaStates}
                    setModalStates={() => {
                      setviewModalaStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {shiftModal === true ? (
                  <ShiftLeadAdd
                    modalStates={shiftModal}
                    setModalStates={() => {
                      setShiftModal(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                    status={0}
                  />
                ) : (
                  ""
                )}

                {shiftListModal === true ? (
                  <ShiftLeadList
                    modalStates={shiftListModal}
                    setModalStates={() => {
                      setshiftListModal(false);
                    }}
                    status={0}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {followUpDetails === true ? (
                  <FolllowUpDetails
                    modalStates={followUpDetails}
                    setModalStates={() => {
                      setfollowUpDetails(!followUpDetails);
                    }}
                    status={0}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                <ToastContainer closeButton={false} limit={1} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LeadsList;