import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthUser from "../helpers/Authuser";

const BusinessLayoutMenuData = () => {
  const history = useNavigate();
  const { user, permission } = AuthUser();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isReports, setIsReports] = useState(false);
  const [isAccounts, setIsAccounts] = useState(false);
  const [isBarcodePrint, setIsBarcodePrint] = useState(false);
  const [isPaymentAndReciept, setIsPaymentAndReciept] = useState(false);
  const [isRewardPoints, setisRewardPoints] = useState(false);
  const [isHrDepartment, setisHrDepartment] = useState(false);
  const [isProfitBook, setIsProfitBook] = useState(false);
  const [isCategories, setIsCategories] = useState(false);
  const [isSettings, setIsSettings] = useState(false);
  const [isTax, setIsTax] = useState(false);
  const [isUnits, setIsUnits] = useState(false);
  const [isProfile, setIsProfile] = useState(false);
  const [isemployee, setIsemployee] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }
  const permissionsList = useSelector(
    (state) => state.permissionsSlice.permissionsList
  );
  const [isAdmin, setIsAdmin] = useState(false);

  // CHECKING IF THE LOGGED IN USER IS AN ADMIN OR NOT
  const checkIsAdmin = () => {
    const data = sessionStorage.getItem("authUser");
    const jsonData = JSON.parse(data);
    if (jsonData.user.email == "admin") {
      setIsAdmin(true);
    }
  };
  useEffect(() => {
    checkIsAdmin();
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "DASHBOARD") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Print Barcodes") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Categories") {
      setIsCategories(false);
    }
    if (iscurrentState !== "units") {
      setIsUnits(false);
    }
    if (iscurrentState !== "setting") {
      setIsSettings(false);
    }
    if (iscurrentState !== "Tax") {
      setIsTax(false);
    }
    if (iscurrentState !== "Profile") {
      setIsProfile(false);
    }
  }, [history, isAccounts, isReports, iscurrentState, isDashboard, isCategories, isTax, isemployee]);

  const menuItemsList = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "DASHBOARD",
      label: "Dashboard",
      icon: "mdi mdi-speedometer",
      link: user.user.u_type === 3
        ? "/business-dashboard"
        : user.user.u_type === 4
          ? "/businessuser-dashboard"
          : "",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("DASHBOARD");
        updateIconSidebar(e);
      },
    },

    {
      id: "ATTENDENCE",
      label: "Attendence",
      icon: "bx bx-user-circle",
      link: user.user.u_type === 4 && user.user.user_behave !== 1 ? "/attendence" : "/attendence-lists",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "TASK",
      label: "Task",
      icon: "bx bx-task",
      link: "/task-list",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "LEAD",
      label: "Leads",
      icon: "bx bx-check",
      link: "/leads-list",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },

    {
      id: "LEAVE",
      label: "Leave",
      icon: "bx bx-calendar",
      link: "/leave",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },

    {
      id: "CUSTOMER",
      label: "Customers",
      icon: "bx bx-user",
      link: "/customer-list",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },

    {
      id: "COMMUNICATION",
      label: "Communication",
      icon: "bx bx-user",
      link: "/chat-app",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },

    // {
    //   id: "LOG-VIEW",
    //   label: "Log-view",
    //   icon: "bx bx-user",
    //   link: "#",
    //   stateVariables: isDashboard,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsDashboard(!isDashboard);
    //     setIscurrentState("Dashboard");
    //     updateIconSidebar(e);
    //   },
    // },
    {
      id: "SALARYGENERATION",
      label: "Salary Generation",
      icon: "bx bx-wallet",
      link: "/salary-generation",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "REPORTS",
      label: "Reports",
      icon: "ri-account-pin-box-fill",
      link: "/report",
      click: function (e) {
        e.preventDefault();
        setIsReports(!isReports);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
      stateVariables: isReports,
      subItems: [
        {
          id: "followup_list",
          label: " Follow-up Report",
          link: `/followup-report`,
          parentId: "apps",
        },
        {
          id: "task_list",
          label: " Task Report",
          link: `/task-report`,
          parentId: "apps",
        },
        {
          id: "lead_list",
          label: " Leads Report",
          link: "/leads-report",
          parentId: "apps",
        },
        {
          id: "attendence_list",
          label: " Attendence Report",
          link: "/attendence-reports",
          parentId: "apps",
        },
        {
          id: "leave_list",
          label: " Leave Report",
          link: "/leave-reports",
          parentId: "apps",
        },
        {
          id: "leave_list",
          label: "Payment Report",
          link: "/payment-reports",
          parentId: "apps",
        },
      ],
    },
    {
      id: "EMPLOYEE",
      label: "Employee",
      icon: "bx bx-user",
      link: "/users-list",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },
    {
      id: "DEPARTMENT",
      label: "Department",
      icon: "mdi mdi-view-grid-plus-outline",
      link: "/department-list",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
    },


    // {
    //   id: "SUBCRIPTION",
    //   label: "Subscription Management",
    //   icon: "bx bx-user",
    //   link: "/customer-list",
    //   stateVariables: isDashboard,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsDashboard(!isDashboard);
    //     setIscurrentState("Dashboard");
    //     updateIconSidebar(e);
    //   },
    // },

    {
      id: "SETTINGS",
      label: "Settings",
      icon: "bx bx-cog",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsSettings(!isSettings);
        setIscurrentState("setting");
        updateIconSidebar(e);
      },
      stateVariables: isSettings,
      subItems: [

        {
          id: "PIPELINES",
          label: "Pipe Lines",
          link: "/pipeline-list",
          parentId: "apps",
        },
        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "1") ? [{
          id: "CATEGORY",
          label: "Category",
          link: "/category-list",
          parentId: "apps",
        }] : []),
        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "2") ? [{
          id: "CITY",
          label: "City",
          link: "/city-list",
          parentId: "apps",
        }] : []),
        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "14") ? [{
          id: "LEAVETYPE",
          label: "Leave-Type",
          link: "/leavetype",
          parentId: "apps",
        }] : []),
        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "7") ? [{

          id: "PAYMENT_LIST",
          label: "Payment Mode",
          link: "/paymentmode-list",
          parentId: "apps",
        }] : []),
        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "6") ? [{

          id: "PAYMENTTYPE_LIST",
          label: "Payment Type",
          link: "/paymenttype-list",
          parentId: "apps",
        }] : []),
        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "5") ? [{

          id: "PRODUCT",
          label: "Product",
          link: "/product-list",
          parentId: "apps",
        }] : []),
        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "8") ? [{

          id: "SHIFT",
          label: "Shift",
          link: "/shift-list",
          parentId: "apps",
        }] : []),
        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "3") ? [{

          id: "SOURCE",
          label: "Source",
          link: "/source-list",
          parentId: "apps",
        }] : []),
        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "4") ? [{

          id: "REFERENCES",
          label: "References",
          link: "/references-list",
          parentId: "apps",
        }] : []),
        //  ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "7") ? [{

        //   id: "related_to",
        //   label: "Related_to",
        //   link: "/related_to-list",
        //   parentId: "apps",
        //}] : []),
        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "11") ? [{

          id: "TAGS",
          label: "Tags",
          link: "/tags-list",
          parentId: "apps",
        }] : []),
        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "9") ? [{

          id: "STATUS",
          label: "Status",
          link: "/leads_status-list",
          parentId: "apps",
        }] : []),

        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "10") ? [{

          id: "EXPENSES_TYPE",
          label: "Expenses Type",
          link: "/expenses-list",
          parentId: "apps",
        }] : []),
        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "12") ? [{

          id: "MESSAGE",
          label: "Message",
          link: "/message-list",
          parentId: "apps",
        }] : []),
        ...(permission.find(permission => permission.permission_category === "SETTINGS" && permission.permission_path === "13") ? [{

          id: "CUSTOMER_GROUPS",
          label: "Customer Groups",
          link: "/customer-group-list",
          parentId: "apps",
        }] : []),
      ],
    },

  ];

  const filteredMenuItems = menuItemsList.filter(menuItem => {
    if (user.user.u_type === 4 && user.user.user_behave !== 1) {
      return permission.some(dataItem => {
        // Check if permission_category is not in the excluded categories and matches menuItem.id
        return !['SETTINGS', 'SUBCRIPTION', 'EMPLOYEE', 'ACCOUNTS', 'SALARYGENERATION', 'FRANCHISE', 'BUSINESS'].includes(dataItem.permission_category) && menuItem.id === dataItem.permission_category;
      });
    } else {
      return permission.some(dataItem => menuItem.id === dataItem.permission_category);
    }
  });

  return (
    <React.Fragment>
      {filteredMenuItems.filter((item) => item !== null)}
    </React.Fragment>
  );
};
export default BusinessLayoutMenuData;
