import React, { useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Container,
    CardHeader,
    Nav,
    Row,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import jsPDF from "jspdf";
import Import from "../City/Import";
import PipeLineUpdate from "./PipeLineUpdate";  
import PipeLineCreate from "./PipeLineCreate";


const Pipeline = () => {
    const [modalStates, setModalStates] = useState(false);
    const [UpdatemodalStates, setUpdateModalStates] = useState(false);
    const { http } = AuthUser();
    const [counts, Setcounts] = useState(1);    
    const [pipelineData, SetPipelineData] = useState([]);
    // infinity
    const [Pages, SetPages] = useState(1);
    const [NoMore, SetNoMore] = useState(true);
    useEffect(() => {
        document.title = "Pipeline | CRM";


    }, []);
    const fetchData = () => {
        http
            .get(`/pipeline/list`)
            .then(function (response) {
                SetPipelineData(response.data);
                SetPages(Pages + 1);
                if (response.data.length === 0) {
                    SetNoMore(false);
                }
            })
            .catch(function (error) {
                console.log(error);
                SetNoMore(false);
            });
    };

    const [deleteModal, setDeleteModal] = useState(false);
    const [ID, SetID] = useState();
    const onClickDelete = (data) => {
        SetID(data);
        setDeleteModal(true);
    };
    const handleDeleteOrder = (data) => {
        if (data._reactName == "onClick") {
            http
                .delete(`/pipeline/delete/${ID}`)
                .then(function (response) {
                    if (response.data.status == 0) {
                        fetchData();
                        toast.success(response.data.message);
                    } else {
                        toast.error(response.data.message);
                    }
                    Setcounts(counts + 1);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        SetPipelineData([]);
        SetPages(1);
        setDeleteModal(false);
    };

    // shortcuts for opening add form
    useEffect(() => {
        fetchData()
        const handleKeyDown = (event) => {
            if (event.altKey && event.key === "a" || event.altKey && event.key === "A") {
                event.preventDefault();
                setModalStates(true);
            }
        };

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);
    //   end Alert
    const handleCallback = (data, status) => {
        SetPipelineData([]);
        SetPages(1);
        if (status == 1) {
            fetchData();
            toast.success(data);
            Setcounts(counts + 1);
        } else {
            toast.warn(data);
            Setcounts(counts + 1);
        }
        setModalStates(false); setModalStatesimport(false);
        setUpdateModalStates(false);
    };
    // Edit Data
    const [FindData, SetFind] = useState([]);
    const EditUpdate = (index) => {
        let FindArray = pipelineData.filter((_, i) => i == index);
        SetFind(FindArray[0]);
        setUpdateModalStates(!UpdatemodalStates);
    };
    const [modalStatesimport, setModalStatesimport] = useState(false);

    const generatePDF = () => {
        const doc = new jsPDF('landscape');

        // Set table headers
        const headers = [
            "#",
            " PIPE LINE NAME"
        ];
        // Set table rows
        const data = pipelineData.map((item, index) => [
            index + 1,
            item.pipeline_name,
        ]);
        doc.setFontSize(12);
        doc.text("Pipe Line List", 14, 15);
        doc.autoTable({
            head: [headers],
            body: data,
            startY: 20,
        });

        // Save the PDF
        doc.save("Pipe line.pdf");

        toast.success("PDF generated successfully!");
    };
    const convertToCSVexcel = () => {
        let csvContent = "#,PIPE LINE NAME";

        pipelineData.forEach((item, index) => {
            csvContent += `\n${index + 1},"${item.pipeline_name}"`;
        });

        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

        const link = document.createElement("a");
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", "PipeLineList.csv");
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        toast.success("CSV generated successfully!");
    };
    return (
        <div className="page-content">
            {modalStatesimport === true ? (
                <Import
                    modalStates={modalStatesimport}
                    setModalStates={() => {
                        setModalStatesimport(false);
                    }}
                    checkchang={handleCallback}
                />
            ) : (
                ""
            )}
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardHeader className="card-header border-0">
                                <Row className="align-items-center gy-3">
                                    <div className="col-sm">
                                        <h5 className="card-title mb-0">Pipe Line  List</h5>
                                        {/* <button className="card-title mb-0" onClick={fetchData}>Pipe Line  List</button> */}
                                    </div>
                                    <div className="col-sm-auto">
                                        <div className="d-flex gap-1 flex-wrap">
                                            <button
                                                type="button"
                                                className="btn fw-bold btn-success  mt-3"
                                                id="create-btn"
                                                onClick={() => setModalStates(!modalStates)}
                                            >
                                                <i className="ri-add-line align-bottom me-1"></i> Add
                                                Pipe Line
                                            </button>
                                            <button
                                                type="button"
                                                className="btn  btn-info mt-3 "
                                                onClick={convertToCSVexcel}
                                            >
                                                <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                                                Export
                                            </button>

                                            <button
                                                type="button"
                                                className="btn  btn-danger mt-3 "
                                                onClick={generatePDF}
                                            >
                                                <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                                                PDF
                                            </button>
                                            <button
                                                type="button"
                                                className="btn  btn-info mt-3 "
                                                onClick={() => setModalStatesimport(!modalStatesimport)}
                                            >
                                                <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                                                Import Data
                                            </button>

                                        </div>
                                    </div>
                                </Row>
                            </CardHeader>

                            <CardBody className="pt-0">
                                <div>
                                    <Nav
                                        className="nav-tabs nav-tabs-custom nav-success"
                                        role="tablist"
                                    ></Nav>

                                    <table
                                        role="table"
                                        className="align-middle table-nowrap table table-hover"
                                    >
                                        <thead className="table-light text-muted text-uppercase">
                                            <tr>
                                                <th
                                                    title="Toggle SortBy"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    #
                                                </th>
                                                <th
                                                    title="Toggle SortBy"
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    Pipe line Name
                                                </th>

                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.isArray(pipelineData)&&pipelineData.map((item, index) => (
                                                <tr key={index}>
                                                    <td>
                                                        <a
                                                            className="fw-medium link-primary"
                                                            href="/apps-ecommerce-order-details"
                                                        >
                                                            {index + 1}
                                                        </a>
                                                    </td>
                                                    <td>{item.pipeline_name}</td>

                                                    <td>
                                                        <ul className="list-inline hstack gap-2 mb-0">
                                                            <li className="list-inline-item edit">
                                                                <button
                                                                    className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                                                    onClick={() => EditUpdate(index)}
                                                                >
                                                                    <i className="ri-pencil-fill fs-16" />
                                                                </button>
                                                            </li>
                                                            <li className="list-inline-item">
                                                                {item.pipeline_id != 1 ? (
                                                                    <button
                                                                        onClick={() =>
                                                                            onClickDelete(item.pipeline_id)
                                                                        }
                                                                        className="text-danger d-inline-block remove-item-btn  border-0 bg-transparent"
                                                                    >
                                                                        <i className="ri-delete-bin-5-fill fs-16" />
                                                                    </button>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                                {modalStates === true ? (
                                    <PipeLineCreate
                                        modalStates={modalStates}
                                        setModalStates={() => {
                                            setModalStates(false);
                                        }}
                                        checkchang={handleCallback}
                                    />
                                ) : (
                                    ""
                                )}
                                {UpdatemodalStates === true ? (
                                    <PipeLineUpdate
                                        modalStates={UpdatemodalStates}
                                        setModalStates={() => {
                                            setUpdateModalStates(false);
                                        }}
                                        checkchang={handleCallback}
                                        edit_data={FindData}
                                    />
                                ) : (
                                    ""
                                )}
                                <ToastContainer closeButton={false} limit={1} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Pipeline;




