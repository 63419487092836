import React, { useRef, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  CardHeader,
  Nav,
  Row,
  UncontrolledPopover,
  PopoverBody,
  Badge,
  Button,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import "./checkButton.css";
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";
import AuthUser from "../../helpers/Authuser";
import { useEffect } from "react";
import { API, IMG_API_URL } from "../../helpers/url_helper";
import D_img from "../D_img";
import TaskUpdate from "./TaskUpdate";
import TaskAdd from "./TaskAdd";
import Select from "react-select";
import { useParams } from "react-router-dom";
import ShiftLeadList from "../Leads/ShiftLead/ShiftLeadList";
import ShiftLeadAdd from "../Leads/ShiftLead/ShiftLeadAdd";
import TaskView from "../Task/TaskView";
import FollowUpAdd from "../Leads/FollowUp/FollowUpAdd";
import FollowUpList from "../Leads/FollowUp/FollowUpList";
import jsPDF from "jspdf";
import FolllowUpDetails from "../Leads/FollowUp/FollowUpDetails";
import Import from "./Import";
import { io } from "socket.io-client";

const TaskList = () => {
  const [selected, setSelected] = useState("today");
  const [followUpDetails, setfollowUpDetails] = useState(false);
  const { http, https, user, permission } = AuthUser();
  const [shiftModal, setShiftModal] = useState(false);
  const [shiftListModal, setshiftListModal] = useState(false);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const { type, status } = useParams();

  const [filterData, setfilterData] = useState({
    start_date:
      status != undefined
        ? new Date(currentYear, 0, 1).toISOString().split("T")[0] + " 00:00:00"
        : "",
    end_date:
      status != undefined
        ? new Date(currentYear, 11, 31).toISOString().split("T")[0] +
        " 23:59:59"
        : "",
    customer_name: 0,
    task_id: type,
    status_name: status ? status : 0,
    assigned_by: 0,
    department: 0,
  });

  const handleSelect = (value) => {
    setfilterData({
      ...filterData,
      repeat_category: value,
    });
    setSelected(value);
  };

  // console.log(filterData);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [selectedLeadIndex, setSelectedLeadIndex] = useState(null);

  const togglePopover = (index) => {
    setPopoverOpen(!popoverOpen);
    setSelectedLeadIndex(index);
  };

  const [modalStates, setModalStates] = useState(false);
  const [modalStatesimport, setModalStatesimport] = useState(false);
  const [UpdatemodalStates, setUpdateModalStates] = useState(false);
  const [viewModalaStates, setviewModalaStates] = useState(false);
  const [followUpStates, setfollowUpStates] = useState(false);
  const [followUpList, setfollowUpList] = useState(false);
  const [selectedOption, setSelectedOption] = useState({ value: 1, label: "My Tasks" });
  const [counts, Setcounts] = useState(1);
  const [TaskData, SetTaskData] = useState([]);
  // infinity
  const [Pages, SetPages] = useState(1);

  const [statusChange, setstatusChange] = useState([]);
  const getstatusChange = async () => {
    http
      .get("/lead/list")
      .then((response) => {
        if (response.data.length > 0) {
          setstatusChange(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleFilter = async (start_date, end_date, taskvalue) => {
    let tempFilterData = filterData;

    if (start_date && end_date) {
      tempFilterData = {
        ...tempFilterData,
        start_date,
        end_date,
      };
    }

    try {
      const response = await http.post(`/task/filter`, tempFilterData);
      const data = response.data;

      if (data.length > 0) {
        if (taskvalue !== undefined) {
          if (taskvalue === 0) {
            const filteredData = data.filter((item) =>
              item.assigned_by.includes(user.user.uid)
            );
            SetTaskData(filteredData);
          } else if (taskvalue === 1) {
            const filteredData = data.filter(
              (item) => item.task_created_by === user.user.uid
            );
            SetTaskData(filteredData);
          } else {
            SetTaskData(data);
          }
        } else {
          SetTaskData(data);
        }
      } else {
        SetTaskData([]);
      }
    } catch (error) {
      console.error("Filter error:", error);
    }
    OverdueStatus();
  };

  const handelReapply = (value, taskvalue) => {
    let tempFilterData = filterData;

    if (value) {
      tempFilterData = {
        ...filterData,
        task_redo_status: value,
      };
    }
    http
      .post(`/task/filter`, tempFilterData)
      .then(function (response) {
        if (response.data.length > 0) {
          console.log("response.data", response.data);
          if (taskvalue != undefined) {
            if (taskvalue === 0) {
              const filteredData = response.data.filter((item) =>
                item.assigned_by.includes(user.user.uid)
              );
              SetTaskData(filteredData);
            } else if (taskvalue === 1) {
              const filteredData = response.data.filter(
                (item) => item.task_created_by === user.user.uid
              );
              // console.log(filteredData);
              SetTaskData(filteredData);
            } else if (taskvalue === 2) {
              SetTaskData(response.data);
            }
          } else {
            SetTaskData(response.data);
          }
        } else {
          SetTaskData([]);
        }

        // console.log("Task Dat", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const fetchData = () => {
    Setcounts(counts + 1);
  };
  //   Delete Aleart
  const [deleteModal, setDeleteModal] = useState(false);
  const [ID, SetID] = useState();
  const onClickDelete = (data) => {
    SetID(data);
    setDeleteModal(true);
  };
  const handleDeleteOrder = (data) => {
    if (data._reactName == "onClick") {
      http
        .delete(`/task/delete/${ID}`)
        .then(function (response) {
          if (response.data.status == 0) {
            toast.success(response.data.message);
          } else {
            toast.error(response.data.message);
          }
          Setcounts(counts + 1);
        })
        .catch(function (error) {
          console.log(error);
        });
    }
    SetTaskData([]);
    SetPages(1);
    setDeleteModal(false);
  };
  const [tagsData, settagsData] = useState([]);
  const gettagsData = async () => {
    http
      .get("/tags/list")
      .then((response) => {
        if (response.data.length > 0) {
          settagsData(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [assignedByData, setassignedByData] = useState([]);
  const [show, setshow] = useState(null);
  const [selectedRoleIds, setSelectedRoleIds] = useState(
    (user.user.u_type == 2 && user.user.user_behave == 1) ||
      (user.user.u_type == 4 && user.user.user_behave == 1)
      ? user.user.user_role_id
      : null
  );
  useEffect(() => {
    let today = new Date();
    let formattedDate = today.toISOString().split("T")[0];
    let start_date = formattedDate + " 00:00:00";
    let end_date = formattedDate + " 23:59:59";

    handleFilter(start_date, end_date);
  }, [filterData.assigned_by, selectedRoleIds, filterData.department]);
  const [selectedRoleUserIds, setSelectedRoleUserIds] = useState([]);
  const [statuscheckforAssignedBy, setstatuscheckforAssignedBy] = useState(0);
  const [role, setRole] = useState([]);

  const getRole = async () => {
    try {
      const response = await http.get("/role/list");
      const filteredData =
        user && (user.user.u_type === 1 || user.user.u_type === 2)
          ? response.data.filter(
            (role) =>
              parseInt(role.role_created_user_id) ===
              user.user.user_user_id ||
              parseInt(role.role_created_user_id) === user.user.uid
          )
          : response.data.filter(
            (role) => parseInt(role.role_created_user_id) === user.user.uid
          );
      setRole(filteredData);
    } catch (error) {
      console.log(error);
    }
  };
  const getassignedByData = async () => {
    http
      .get("/user/list")
      .then((response) => {
        if (response.data.length > 0) {
          setassignedByData(response.data);
          console.log(response.data)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // shortcuts for opening add form
  useEffect(() => {
    gettagsData();
    getRole();
    getassignedByData();
    const handleKeyDown = (event) => {
      if (
        (event.altKey && event.key === "a") ||
        (event.altKey && event.key === "A")
      ) {
        event.preventDefault();
        setModalStates(true);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  //   end Alert
  const handleCallback = (data, status) => {
    SetTaskData([]);
    SetPages(1);
    if (status == 1) {
      setModalStatesimport(false);
      setUpdateModalStates(false);
      setShiftModal(false);
      toast.success(data);
      Setcounts(counts + 1);
    } else {
      toast.warn(data);
      Setcounts(counts + 1);
    }
    let today = new Date();
    let formattedDate = today.toISOString().split("T")[0]; // Get today's date in 'YYYY-MM-DD' format
    let start_date = formattedDate + " 00:00:00"; // Set start date to today's beginning
    let end_date = formattedDate + " 23:59:59"; // Set end date to today's end

    handleFilter(start_date, end_date);
    handleSelect("today");
  };
  // Edit Data
  const [FindData, SetFind] = useState([]);
  const EditUpdate = (index) => {
    let FindArray = TaskData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setUpdateModalStates(!UpdatemodalStates);
  };
  const FollowUp = (index) => {
    let FindArray = TaskData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setfollowUpStates(!followUpStates);
  };
  const FollowListed = (index) => {
    let FindArray = TaskData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setfollowUpList(!followUpList);
  };
  const ViewPage = (index) => {
    let FindArray = TaskData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setviewModalaStates(!viewModalaStates);
  };
  const transeferData = (index) => {
    let FindArray = TaskData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
    setShiftModal(!shiftModal);
  };
  const ChangeStatus = (index) => {
    let FindArray = TaskData.filter((_, i) => i == index);
    SetFind(FindArray[0]);
  };
  const tableRef = useRef(null);
  const downloadPDF = () => {
    const table = tableRef.current;
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    // Create a new jsPDF instance with landscape orientation
    const pdf = new jsPDF({
      orientation: "landscape",
    });

    // Add the table to the PDF using autoTable
    pdf.autoTable({ html: table });

    // Output the PDF as a blob
    const blob = pdf.output("blob");
    const url = URL.createObjectURL(blob);

    // Create a link element to download the PDF
    const link = document.createElement("a");
    link.href = url;
    link.download = "task_list.pdf";

    // Append the link to the document, trigger the download, and then remove the link
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const downloadCSV = () => {
    const table = tableRef.current;
    if (!table) {
      console.error("Table element not found.");
      return;
    }

    const csvContent = Array.from(table.querySelectorAll("tr"))
      .map((row) =>
        Array.from(row.children)
          .map((cell) => `"${cell.innerText.trim()}"`)
          .join(",")
      )
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "task_list.csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  const OverdueStatus = () => {
    http
      .get(`/task/list`)
      .then((res) => { })
      .catch((error) => {
        console.error("Error fetching notifications:", error);
      });
  };
  useEffect(() => {
    getstatusChange();
    document.title = "Task | CRM";
    let today = new Date();
    let formattedDate = today.toISOString().split("T")[0]; // Get today's date in 'YYYY-MM-DD' format
    let start_date = formattedDate + " 00:00:00"; // Set start date to today's beginning
    let end_date = formattedDate + " 23:59:59"; // Set end date to today's end

    console.log("Hello");

    handleFilter(start_date, end_date); // Pass both start_date and end_date as today's date
  }, []);
  useEffect(() => {

    handleFilter("", "", selectedOption.value);
  }, [selectedOption]);
  const [showBoxSub, setShowBoxSub] = useState(false);
  const [showBoxTeam, setShowBoxTeam] = useState(false);
  const [showMain, setshowMain] = useState(null);
  const [showSub, setshowSub] = useState(null);
  const handleRoleChange = (e) => {
    const selectedDepartment = e.value === 0 ? null : e.value;

    setSelectedRoleIds(selectedDepartment);

  };
  return (
    <div className="page-content">
      {modalStatesimport === true ? (
        <Import
          modalStates={modalStatesimport}
          setModalStates={() => {
            setModalStatesimport(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      <table
        ref={tableRef}
        style={{ display: "none" }}
        role="table"
        id="tableToPrint"
        className="text-center bg-white table"
      >
        <thead className="table-light text-muted text-uppercase">
          <tr>
            <th title="Toggle SortBy" style={{ cursor: "pointer" }}>
              #
            </th>
            <th title="Toggle SortBy" style={{ cursor: "pointer" }}>
              Date
            </th>
            <th title="Toggle SortBy" style={{ cursor: "pointer" }}>
              Time
            </th>
            {/* <th title="Toggle SortBy" style={{ cursor: "pointer" }}>
              Customer Name
            </th> */}

            <th>End Date</th>
            <th>Maximum Date</th>
            <th>Team Member </th>
            {/* <th>Tags</th> */}
            <th>Priority</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {TaskData.map((item, index) => (
            <tr title="Open Task" style={{ cursor: "pointer" }} key={index}>
              <td title="Index">
                <a
                  className="fw-medium link-primary"
                  href="/apps-ecommerce-order-details"
                >
                  {index + 1}
                </a>
              </td>{" "}
              <td>
                {/* {item.today_date.split("T")[0]} */}
                {new Date(item.minimum_due_date).toLocaleDateString("en-GB")}
                <br />
              </td>
              <td>
                {new Date(item.minimum_due_date).toLocaleTimeString("en-US", {
                  hour12: true,
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </td>
              {/* <td title="Customer Name">{item.customer_name}</td> */}
              <td title="End Date">
                {new Date(item.minimum_due_date).toLocaleDateString("en-GB")}
              </td>
              <td title="Maximum Date">
                {new Date(item.maximum_due_date).toLocaleDateString("en-GB")}
              </td>
              <td title="Team Member">
                {assignedByData != null && item.assigned_by
                  ? assignedByData.map((e) =>
                    JSON.parse(item.assigned_by).includes(e.uid) ? (
                      <div key={e.uid} className="p-1">
                        <button className="bg-primary fw-bold text-white border border-primary">
                          {e.name}
                        </button>
                      </div>
                    ) : null
                  )
                  : "Loading..."}
              </td>

              <td title="Priority">
                {item.priority === 1
                  ? "Low"
                  : item.priority === 2
                    ? "Medium"
                    : item.priority === 3
                      ? "High"
                      : item.priority === 4
                        ? "Urgent"
                        : ""}
              </td>
              <td>
                <div>
                  <div className="hstack flex-wrap gap-2">
                    <Badge color={item.lead_status_color}>
                      {item.lead_status_name}
                    </Badge>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Container fluid>
        <Row>
          <Col lg={12}>
            <Card>
              <CardHeader className="card-header border-0">
                <Row className="align-items-center gy-3">
                  <div className="text-center">
                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="today"
                      autoComplete="off"
                      checked={selected === "today"}
                      onChange={() => {
                        let today = new Date();
                        let formattedDate = today.toISOString().split("T")[0]; // Get today's date in 'YYYY-MM-DD' format
                        let start_date = formattedDate + " 00:00:00"; // Set start date to today's beginning
                        let end_date = formattedDate + " 23:59:59"; // Set end date to today's end

                        handleFilter(start_date, end_date); // Pass both start_date and end_date as today's date
                        handleSelect("today");
                      }}
                    />
                    <label
                      className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "today" ? "checked" : ""
                        }`}
                      htmlFor="today"
                    >
                      Today
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="yesterday"
                      autoComplete="off"
                      checked={selected === "yesterday"}
                      onChange={() => {
                        let today = new Date();
                        let yesterday = new Date(today);
                        yesterday.setDate(today.getDate() - 1);
                        let formattedYesterday = yesterday
                          .toISOString()
                          .split("T")[0]; // Get yesterday's date in 'YYYY-MM-DD' format
                        let start_date = formattedYesterday + " 00:00:00"; // Set start date to yesterday's beginning
                        let end_date = formattedYesterday + " 23:59:59";
                        handleFilter(start_date, end_date); // Pass both start_date and end_date as yesterday's date
                        handleSelect("yesterday");
                      }}
                    />
                    <label
                      className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "yesterday" ? "checked" : ""
                        }`}
                      htmlFor="yesterday"
                    >
                      Yesterday
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="thisWeek"
                      autoComplete="off"
                      checked={selected === "thisWeek"}
                      onChange={() => {
                        let today = new Date();
                        let dayOfWeek = today.getDay();
                        let startOfWeek = new Date(today); // Clone today's date
                        startOfWeek.setDate(today.getDate() - dayOfWeek);
                        let endOfWeek = new Date(today); // Clone today's date
                        endOfWeek.setDate(today.getDate() + (6 - dayOfWeek));
                        let formattedStartOfWeek =
                          startOfWeek.toISOString().split("T")[0] + " 00:00:00"; // Start of the week
                        let formattedEndOfWeek =
                          endOfWeek.toISOString().split("T")[0] + " 23:59:59";
                        handleFilter(formattedStartOfWeek, formattedEndOfWeek);
                        handleSelect("thisWeek");
                      }}
                    />
                    <label
                      className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "thisWeek" ? "checked" : ""
                        }`}
                      htmlFor="thisWeek"
                    >
                      This Week
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="thisMonth"
                      autoComplete="off"
                      checked={selected === "thisMonth"}
                      onChange={() => {
                        let today = new Date();
                        let startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
                        let endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                        const formatDateTime = (date, time) => {
                          const year = date.getFullYear();
                          const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2 digits
                          const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits
                          return `${year}-${month}-${day} ${time}`;
                        };
                        let formattedStartOfMonth = formatDateTime(startOfMonth, "00:00:00");
                        let formattedEndOfMonth = formatDateTime(endOfMonth, "23:59:59");
                        handleFilter(formattedStartOfMonth, formattedEndOfMonth);
                        handleSelect("thisMonth");
                      }}
                    />
                    <label
                      className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "thisMonth" ? "checked" : ""
                        }`}
                      htmlFor="thisMonth"
                    >
                      This Month
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="lastMonth"
                      autoComplete="off"
                      checked={selected === "lastMonth"}
                      onChange={() => {
                        const today = new Date();
                        const lastMonth = new Date(today);
                        lastMonth.setMonth(today.getMonth() - 1);
                        const startOfLastMonth = new Date(
                          lastMonth.getFullYear(),
                          lastMonth.getMonth(),
                          1
                        );
                        const endOfLastMonth = new Date(
                          lastMonth.getFullYear(),
                          lastMonth.getMonth() + 1,
                          0 // 0th day gives the last day of the last month
                        );
                        const formatDateTime = (date, time) => {
                          const year = date.getFullYear();
                          const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2 digits
                          const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits
                          return `${year}-${month}-${day} ${time}`;
                        };
                        const formattedStartOfLastMonth = formatDateTime(startOfLastMonth, "00:00:00");
                        const formattedEndOfLastMonth = formatDateTime(endOfLastMonth, "23:59:59");
                        handleFilter(formattedStartOfLastMonth, formattedEndOfLastMonth);
                        handleSelect("lastMonth");
                      }}

                    />
                    <label
                      className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "lastMonth" ? "checked" : ""
                        }`}
                      htmlFor="lastMonth"
                    >
                      Last Month
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="nextWeek"
                      autoComplete="off"
                      checked={selected === "nextWeek"}
                      onChange={() => {
                        const today = new Date();
                        const dayOfWeek = today.getDay();
                        const startOfNextWeek = new Date(today);
                        startOfNextWeek.setDate(today.getDate() + (7 - dayOfWeek));
                        const endOfNextWeek = new Date(startOfNextWeek);
                        endOfNextWeek.setDate(startOfNextWeek.getDate() + 6);
                        const formatDateTime = (date, time) => {
                          const year = date.getFullYear();
                          const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2 digits
                          const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits
                          return `${year}-${month}-${day} ${time}`;
                        };
                        const formattedStartOfNextWeek = formatDateTime(startOfNextWeek, "00:00:00");
                        const formattedEndOfNextWeek = formatDateTime(endOfNextWeek, "23:59:59");
                        handleFilter(formattedStartOfNextWeek, formattedEndOfNextWeek);
                        handleSelect("nextWeek");
                      }}

                    />
                    <label
                      className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "nextWeek" ? "checked" : ""
                        }`}
                      htmlFor="nextWeek"
                    >
                      Next Week
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="nextMonth"
                      autoComplete="off"
                      checked={selected === "nextMonth"}
                      onChange={() => {
                        const today = new Date();
                        const startOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
                        const endOfNextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);
                        const formatDateTime = (date, time) => {
                          const year = date.getFullYear();
                          const month = String(date.getMonth() + 1).padStart(2, "0"); // Ensure 2 digits
                          const day = String(date.getDate()).padStart(2, "0"); // Ensure 2 digits
                          return `${year}-${month}-${day} ${time}`;
                        };
                        const formattedStartOfNextMonth = formatDateTime(startOfNextMonth, "00:00:00");
                        const formattedEndOfNextMonth = formatDateTime(endOfNextMonth, "23:59:59");
                        handleFilter(formattedStartOfNextMonth, formattedEndOfNextMonth);
                        handleSelect("nextMonth");
                      }}

                    />
                    <label
                      className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "nextMonth" ? "checked" : ""}`}
                      htmlFor="nextMonth"
                    >
                      Next Month
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="allTime"
                      autoComplete="off"
                      checked={selected === "allTime"}
                      onChange={() => {
                        handleFilter();
                        handleSelect("allTime");
                      }}
                    />
                    <label
                      className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "allTime" ? "checked" : ""
                        }`}
                      htmlFor="allTime"
                    >
                      All Time
                    </label>

                    <input
                      type="radio"
                      className="btn-check"
                      name="options"
                      id="Re_Apply"
                      autoComplete="off"
                      checked={selected === "Re_Apply"}
                      onChange={() => {
                        handelReapply(1);
                        handleSelect("Re_Apply");
                      }}
                    />
                    <label
                      className={`btn rounded-pill btn-sm ms-2 btn-outline-dark ${selected === "Re_Apply" ? "checked" : ""
                        }`}
                      htmlFor="Re_Apply"
                    >
                      Re-Open
                    </label>
                  </div>

                  <div className="text-center pt-2 pb-4">
                    <h2 className="fw-bold mb-0">
                      {" "}
                      {filterData.department > 0
                        ? role.filter(
                          (data) => data.role_id == filterData.department
                        )[0].role_name
                        : "All "}{" "}&nbsp;TASK'S
                    </h2>
                  </div>
                </Row>

                <Row className="justify-content-center align-items-center d-flex">
                  {(user.user.u_type === 1 || user.user.u_type === 3) && (
                    <>
                      <Col lg="auto" sm="12">
                        <div>
                          <div className="fw-bold">Department</div>
                          <Select
                            className="fw-bold"
                            options={[
                              { value: 0, label: "Select All" },
                              ...role.filter(item => item.role_created_for == null).map((e) => ({
                                value: e.role_id,
                                label: e.role_name,
                              })),
                            ]}
                            onChange={(e) => {
                              setshowMain(e.value);
                              setshowSub(e.value)
                              setShowBoxSub(true)
                              handleRoleChange(e);
                            }}

                          />
                        </div>
                      </Col>
                      {
                        showBoxSub === true &&
                         <Col lg="auto" sm="12">
                          <div>
                            <div className="fw-bold" > Sub-Department</div>
                            <Select

                              className="fw-bold apexcharts-legend-text"
                              options={[
                                { value: 0, label: "Select All" },
                                ...role.filter(item => item.role_created_for == showMain).map(e => ({
                                  value: e.role_id,
                                  label: e.role_name,
                                })),
                              ]}
                              onChange={(e) => {
                                handleRoleChange(e)
                                setshowSub(e.value)
                                setShowBoxTeam(true)
                              }}
                            />


                          </div>

                        </Col>

                      }

                    </>
                  )}
                  {(user.user.u_type === 1 ||
                    (user.user.u_type === 2 && user.user.user_behave == 1) ||
                    user.user.u_type === 3 ||
                    (user.user.u_type === 4 && user.user.user_behave == 1)) && (
                      showBoxTeam && ( 
                      <Col lg="auto" sm="12">
                        <div className="fw-bold">Team Member</div>
                        <Select
                          className="fw-bold"
                          placeholder="Assigned"
                         
                          options={[
                              { value: 0, label: "Select All" },
                              {
                                label: 'Managers',
                                options: assignedByData
                                  .filter(e => showSub == 0 ? (e.user_role_id == showMain || e.role_created_for == showMain) && e.user_behave == 1 : "")
                                  .map(e => ({ value: e.uid, label: e.name })),
                              },
                              {
                                label: 'Team Member',
                                options: assignedByData
                                  .filter(e => showSub == 0 ? (e.user_role_id == showMain || e.role_created_for == showMain) && e.user_behave == 0 : e.user_role_id == showSub  && e.user_behave == 0)
                                  .map(e => ({ value: e.uid, label: e.name })),
                              },
                            ]}
                          onChange={(e) => {

                            setfilterData({
                              ...filterData,
                              assigned_by: e.value,
                            });
                          }}
                        />
                      </Col>
                       )

                    )}


                  <Col lg="auto" sm="12">
                    <div className="fw-bold">Filter Tasks</div>
                    <Select
                      className="fw-bold"
                      placeholder="All Tasks"
                      value={selectedOption}
                      options={[
                        { value: 0, label: "My Tasks" },
                        { value: 1, label: "Delegated Tasks" },
                        { value: 2, label: "All Tasks" },
                      ]}
                      onChange={(e) => {
                        setSelectedOption(e);
                        handleFilter("", "", e.value);
                      }}
                    />
                  </Col>
                  <Col lg="auto" sm="12">
                    <div className="fw-bold">By Status</div>
                    <Select
                      className="fw-bold"
                      placeholder="Status"
                      options={[
                        { value: 0, label: "Select All" },
                        ...statusChange.map((e) => ({
                          value: e.lead_status_id,
                          label: e.lead_status_name,
                        })),
                      ]}
                      onChange={(e) => {
                        setSelectedRoleIds(e.value);
                        setfilterData({ ...filterData, status_name: e.value });
                      }}
                    />
                  </Col>

                  <Col lg="auto" sm="12">
                    <div className="fw-bold">Start Date</div>
                    <Flatpickr
                      className="form-control fw-bold"
                      options={{
                        dateFormat: "d/m/Y",
                        defaultDate: new Date(),
                      }}
                      placeholder="Enter Start Date"
                      value={null}
                      onChange={(selectedDates) => {
                        const selectedDate = selectedDates[0];
                        const formattedDate = `${selectedDate.getFullYear()}-${(
                          selectedDate.getMonth() + 1
                        )
                          .toString()
                          .padStart(2, "0")}-${selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0")} 00:00:00`;
                        handleFilter();
                        setfilterData({
                          ...filterData,
                          start_date: formattedDate,
                        });
                      }}
                    />
                  </Col>

                  <Col lg="auto" sm="12">
                    <div className="fw-bold">End Date</div>
                    <Flatpickr
                      className="form-control fw-bold"
                      options={{
                        dateFormat: "d/m/Y",
                        defaultDate: new Date(),
                      }}
                      placeholder="Enter end  Date"
                      value={null}
                      onChange={(selectedDates) => {
                        const selectedDate = selectedDates[0];
                        const formattedDate = `${selectedDate.getFullYear()}-${(
                          selectedDate.getMonth() + 1
                        )
                          .toString()
                          .padStart(2, "0")}-${selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0")} 23:59:59`;
                        handleFilter();
                        setfilterData({
                          ...filterData,
                          end_date: formattedDate,
                        });
                      }}
                    />
                  </Col>
                </Row>

                <Row className="justify-content-end mt-2">
                  <Col lg="auto" sm="12" className="me-0">
                    <button
                      className="btn mt-3 btn-success"
                      onClick={handleFilter}
                    >
                      Search
                    </button>
                  </Col>
                  {/* <button className="btn-dark" onClick={OverdueStatus}>dfsdfd</button> */}
                  <Col
                    lg="auto"
                    sm="11"
                    className="me-0"
                    style={{ marginLeft: "-15px" }}
                  >
                    <button className="btn mt-3 btn-info" onClick={downloadCSV}>
                      <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                      Export
                    </button>
                  </Col>

                  <Col
                    lg="auto"
                    sm="11"
                    className="me-0"
                    style={{ marginLeft: "-15px" }}
                  >
                    <button
                      className="btn mt-3 btn-danger"
                      onClick={downloadPDF}
                    >
                      <i className="ri-file-download-line align-bottom me-1"></i>{" "}
                      Pdf
                    </button>
                  </Col>

                  <Col
                    lg="auto"
                    sm="11"
                    className="me-0"
                    style={{ marginLeft: "-15px" }}
                  >
                    {type !== 1 &&
                      permission.some(
                        (p) =>
                          p.permission_category === "TASK" &&
                          p.permission_path === "1"
                      ) && (
                        <button
                          className="btn mt-3 fw-bold btn-success add-btn"
                          onClick={() => setModalStates(!modalStates)}
                        >
                          <i className="ri-add-line align-bottom me-1"></i> Add
                          Task
                        </button>
                      )}
                  </Col>

                  <Col lg="auto" sm="12" style={{ marginLeft: "-15px" }}>
                    <button
                      className="btn mt-3 btn-info"
                      onClick={() => setshiftListModal(true)}
                    >
                      <i className="ri-eye-line align-bottom me-1"></i> Shifted
                      Tasks
                    </button>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className="pt-0">
                <div>
                  <Nav
                    className="nav-tabs nav-tabs-custom nav-success"
                    role="tablist"
                  ></Nav>
                  {permission.find(
                    (permission) =>
                      permission.permission_category === "TASK" &&
                      permission.permission_path === "2"
                  ) && (
                      <div className="table-responsive">
                        <table
                          role="table"
                          className="align-middle table-nowrap table table-hover"
                        >
                          <thead className="table-light text-muted text-uppercase">
                            <tr>
                              <th title="Task List" style={{ cursor: "pointer" }}>
                                #
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetTaskData(TaskData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Start Date
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetTaskData(TaskData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                End Date
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetTaskData(TaskData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Next FollowUp
                              </th>
                              {/* <th
                              className="sort desc"
                              onClick={() => {
                                SetTaskData(TaskData.slice().reverse());
                              }}
                              title="Task List"
                              style={{ cursor: "pointer" }}
                            >
                              Customer
                            </th> */}

                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetTaskData(TaskData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Task Title
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetTaskData(TaskData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Assigned by
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetTaskData(TaskData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Team Member
                              </th>
                              {/* <th
                              className="sort desc"
                              onClick={() => {
                                SetTaskData(TaskData.slice().reverse());
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Tags
                            </th> */}
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetTaskData(TaskData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Priority
                              </th>

                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetTaskData(TaskData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Status
                              </th>
                              <th
                                className="sort desc"
                                onClick={() => {
                                  SetTaskData(TaskData.slice().reverse());
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                Shift
                              </th>
                              {permission.find(
                                (permission) =>
                                  permission.permission_category === "TASK" &&
                                  permission.permission_path === "3"
                              ) && (
                                  <th
                                    className="sort desc"
                                    onClick={() => {
                                      SetTaskData(TaskData.slice().reverse());
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Action
                                  </th>
                                )}
                            </tr>
                          </thead>
                          <tbody>
                            {TaskData.filter(
                              (item, index, self) =>
                                index ===
                                self.findIndex((t) => t.task_id === item.task_id)
                            ).map((item, index) => (
                              <tr
                                title="Open Task"
                                style={{
                                  cursor: "pointer",
                                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                  transition: "box-shadow 0.3s ease",
                                }}
                                onMouseEnter={(e) => {
                                  e.currentTarget.style.boxShadow =
                                    "0 8px 16px rgba(0, 0, 0, 0.2)";
                                  e.currentTarget.style.backgroundColor =
                                    "#f0f0f0"; // Optional: highlight background on hover
                                }}
                                onMouseLeave={(e) => {
                                  e.currentTarget.style.boxShadow =
                                    "0 4px 8px rgba(0, 0, 0, 0.1)";
                                  e.currentTarget.style.backgroundColor = ""; // Reset background color
                                }}
                                key={index}
                              >
                                <td title="Index" onClick={() => ViewPage(index)}>
                                  <a
                                    className="fw-medium link-primary"
                                    href="/apps-ecommerce-order-details"
                                  >
                                    {index + 1}
                                  </a>
                                </td>
                                <td onClick={() => ViewPage(index)}>
                                  {/* {item.today_date.split("T")[0]} */}
                                  {new Date(
                                    item.minimum_due_date
                                  ).toLocaleDateString("en-GB")}
                                  <br />

                                  {new Date(
                                    item.minimum_due_date
                                  ).toLocaleTimeString("en-US", {
                                    hour12: true,
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </td>
                                {/* <td
                                title="Customer Name"
                                onClick={() => ViewPage(index)}
                              >
                                {item.customer_name}
                              </td> */}

                                <td
                                  title="Start Date"
                                  onClick={() => ViewPage(index)}
                                >
                                  {new Date(
                                    item.maximum_due_date
                                  ).toLocaleDateString("en-GB")}
                                  <br />
                                  {new Date(
                                    item.maximum_due_date
                                  ).toLocaleTimeString("en-US", {
                                    hour12: true,
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </td>
                                <td
                                  title="Start Date"
                                  onClick={() => ViewPage(index)}
                                >
                                  {new Date(
                                    item.next_follow_up
                                  ).toLocaleDateString("en-IN")}
                                  <br />
                                  {new Date(
                                    item.next_follow_up
                                  ).toLocaleTimeString("en-GB", {
                                    hour12: true,
                                    hour: "2-digit",
                                    minute: "2-digit",
                                  })}
                                </td>
                                <td
                                  title="Task Title"
                                  onClick={() => ViewPage(index)}
                                >
                                  {item.task_title}
                                </td>
                                <td
                                  title="Assigned By"
                                  onClick={() => ViewPage(index)}
                                >
                                  {item.name}
                                </td>
                                <td
                                  title="Assigned To"
                                  onClick={() => ViewPage(index)}
                                >
                                  {assignedByData != null && item.assigned_by
                                    ? assignedByData.map((e) =>
                                      JSON.parse(item.assigned_by).includes(
                                        e.uid
                                      ) ? (
                                        <div key={e.uid} className="p-1">
                                          <button className="bg-primary fw-bold text-white border border-primary">
                                            {e.name}
                                          </button>
                                        </div>
                                      ) : null
                                    )
                                    : "Loading..."}
                                </td>
                                {/* <td title="Tags" onClick={() => ViewPage(index)}> */}

                                {/* {tagsData != null && item.tags
                                  ? (() => {
                                    let parsedTags;
                                    try {
                                      parsedTags = JSON.parse(item.tags);
                                    } catch (error) {
                                      console.error("Failed to parse tags:", error);
                                      return "Error parsing tags";
                                    }

                                    if (!Array.isArray(parsedTags)) {
                                      console.error("Parsed tags is not an array:", parsedTags);
                                      return "Tags are not in array format";
                                    }

                                    return tagsData.map((e) =>
                                      parsedTags.includes(e.tag_id) ? (
                                        <Col key={e.tag_id} lg={6}>
                                          <Badge className="badge-gradient-info ">
                                            {e.tag_name}
                                          </Badge>
                                        </Col>
                                      ) : null
                                    );
                                  })()
                                  : "Loading..."} */}

                                {/* {tagsData != null && item.tags
                                  ? tagsData.map((e) =>
                                    JSON.parse(item.tags).includes(e.tag_id) ? (
                                      <Col key={e.tag_id} lg={6}>
                                        <Badge className="badge-gradient-info ">
                                          {e.tag_name}
                                        </Badge> */}
                                {/* </Col> */}
                                {/* ) : null */}
                                {/* ) */}
                                {/* : "Loading..."} */}

                                {/* </td> */}
                                <td
                                  title="Priority"
                                  onClick={() => ViewPage(index)}
                                >
                                  {item.priority === 1
                                    ? "Low"
                                    : item.priority === 2
                                      ? "Medium"
                                      : item.priority === 3
                                        ? "High"
                                        : item.priority === 4
                                          ? "Urgent"
                                          : ""}
                                </td>

                                <td>
                                  <div>
                                    <div className="hstack flex-wrap gap-2">
                                      <Button
                                        color={item.lead_status_color}
                                        className=" "
                                      >
                                        {item.task_redo_status == 1 && "Re-"}
                                        {item.lead_status_name}
                                      </Button>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <ul className="list-inline hstack gap-2 mb-0">
                                    <li className="list-inline-item edit">
                                      <button
                                        className="btn btn-info  border-0"
                                        onClick={() => {
                                          transeferData(index);
                                        }}
                                        style={{
                                          background:
                                            "linear-gradient(to right, #ff7e5f, #feb47b)", // Adjust colors as needed
                                        }}
                                      >
                                        Shift{" "}
                                      </button>
                                    </li>
                                  </ul>
                                </td>
                                <td>
                                  <ul className="list-inline hstack gap-2 d-flex justify-content-center mb-0">
                                    <li className="list-inline-item edit">
                                      {permission.find(
                                        (permission) =>
                                          permission.permission_category ===
                                          "TASK" &&
                                          permission.permission_path === "3"
                                      ) && (
                                          <button
                                            className="text-primary  border-0 bg-transparent"
                                            onClick={() => EditUpdate(index)}
                                          >
                                            <i className="ri-pencil-fill fs-16" />
                                          </button>
                                        )}
                                    </li>
                                  </ul>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                </div>

                {modalStates === true ? (
                  <TaskAdd
                    modalStates={modalStates}
                    setModalStates={() => {
                      setModalStates(false);
                    }}
                    checkchang={handleCallback}
                  />
                ) : (
                  ""
                )}
                {UpdatemodalStates === true ? (
                  <TaskUpdate
                    modalStates={UpdatemodalStates}
                    setModalStates={() => {
                      setUpdateModalStates(false);
                    }}
                    status={1}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {viewModalaStates === true ? (
                  <TaskView
                    modalStates={viewModalaStates}
                    setModalStates={() => {
                      setviewModalaStates(false);
                    }}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {shiftModal === true ? (
                  <ShiftLeadAdd
                    modalStates={shiftModal}
                    setModalStates={() => {
                      setShiftModal(false);
                    }}
                    status={1}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {followUpStates === true ? (
                  <FollowUpAdd
                    modalStates={followUpStates}
                    setModalStates={() => {
                      setfollowUpStates(false);
                    }}
                    status={1}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {followUpList === true ? (
                  <FollowUpList
                    modalStates={followUpList}
                    setModalStates={() => {
                      setfollowUpList(false);
                    }}
                    status={1}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}

                {shiftListModal === true ? (
                  <ShiftLeadList
                    modalStates={shiftListModal}
                    setModalStates={() => {
                      setshiftListModal(false);
                    }}
                    status={1}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {followUpDetails === true ? (
                  <FolllowUpDetails
                    modalStates={followUpDetails}
                    setModalStates={() => {
                      setfollowUpDetails(!followUpDetails);
                    }}
                    status={1}
                    checkchang={handleCallback}
                    edit_data={FindData}
                  />
                ) : (
                  ""
                )}
                {modalStatesimport === true ? (
                  <Import
                    modalStates={modalStatesimport}
                    setModalStates={() => {
                      setModalStatesimport(false);
                    }}
                    checkchang={handleCallback}
                  />
                ) : (
                  ""
                )}
                {/* <ToastContainer closeButton={false} limit={1} /> */}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TaskList;
