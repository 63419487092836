import React, { useEffect, useState } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Row,
  Col,
  Button,
  Form,
  Input,
  FormGroup,
} from "reactstrap";
import CustomInput from "../Unit/Input";
import Select from "react-select";
import AuthUser from "../../helpers/Authuser";

const FormFiledEdit = (props) => {
  const { http } = AuthUser();
  const [fieldData, SetFieldData] = useState(props.edit_data || {});

  useEffect(() => {
    SetFieldData(props.edit_data);
  }, [props.edit_data]);

  const [isToggled, setIsToggled] = useState(() =>
    props.edit_data.formdynamic_status == 2 || props.edit_data.formdynamic_status == 4
  );

  const [isToggledTable, setIsToggledTable] = useState(() =>
    props.edit_data.formdynamic_status == 3 || props.edit_data.formdynamic_status == 4
  );


  const commonInputTypes = [
    { id: 1, typeame: "Text" },
    { id: 2, typeame: "Date" },
    { id: 3, typeame: "Description" },
  ];

  const Close = () => {
    props.formmodalEdit();
  };

  const handleToggle = () => {
    setIsToggled((prev) => {
      const newState = !prev;
      updateFormDynamicStatus(newState, isToggledTable);
      return newState;
    });
  };

  const handleToggleTable = () => {
    setIsToggledTable((prev) => {
      const newState = !prev;
      updateFormDynamicStatus(isToggled, newState);
      return newState;
    });
  };

  const updateFormDynamicStatus = (isRequired, isTableVisible) => {
    if (isRequired && isTableVisible) {
      SetFieldData((prev) => ({ ...prev, formdynamic_status: 4 }));
    } else if (isRequired && !isTableVisible) {
      SetFieldData((prev) => ({ ...prev, formdynamic_status: 2 }));
    } else if (!isRequired && isTableVisible) {
      SetFieldData((prev) => ({ ...prev, formdynamic_status: 3 }));
    } else {
      SetFieldData((prev) => ({ ...prev, formdynamic_status: 1 }));
    }
  };

  const SubmitData = (e) => {
    e.preventDefault();
    if (
      fieldData?.formdynamic_name &&
      fieldData?.formdynamic_path &&
      fieldData?.formdynamic_type
    ) {
      http
        .put("/formdynamic/update", {
          formdynamic_id: fieldData?.formdynamic_id,
          formdynamic_name: fieldData?.formdynamic_name,
          formdynamic_path: fieldData?.formdynamic_path,
          formdynamic_type: fieldData?.formdynamic_type,
          formdynamic_category: props.status || 1,
          formdynamic_status: fieldData?.formdynamic_status, // Ensure status is sent
        })
        .then((response) => {
          props.checkchang(response.data.message, response.data.status);
          Close();
        })
        .catch((error) => {
          console.error("Error updating data:", error);
        });
    } else {
      alert("Please fill all the required fields before submitting.");
    }
  };

  return (
    <div>
      <Modal id="showModal" isOpen={props.modalStates} centered>
        <ModalHeader className="bg-light p-3" toggle={props.formmodalEdit}>
          Update Field
        </ModalHeader>

        <span className="tablelist-form">
          <Form>
            <ModalBody>
              <Card className="border card-border-success p-3 shadow-lg">
                <Row>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="field-name"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Field Name<span style={{ color: "red" }}> *</span>
                        </div>
                        <div>
                          <small
                            id="field-name-error"
                            className="text-danger d-block"
                          ></small>
                        </div>
                      </Label>
                      <CustomInput
                        id="field-name"
                        className="form-control fw-bold"
                        placeholder="Field Name"
                        type="text"
                        aria-describedby="field-name-error"
                        value={fieldData?.formdynamic_name || ""}
                        onChange={(e) =>
                          SetFieldData({
                            ...fieldData,
                            formdynamic_name: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                  <Col lg={12}>
                    <div className="mb-3">
                      <Label
                        htmlFor="field-type"
                        className="form-label fw-bold d-flex justify-content-between"
                      >
                        <div>
                          Field Type<span style={{ color: "red" }}> *</span>
                        </div>
                        <div>
                          <small
                            id="field-type-error"
                            className="text-danger d-block"
                          ></small>
                        </div>
                      </Label>

                      <Select
                        id="field-type"
                        options={commonInputTypes.map((e) => ({
                          label: e.typeame,
                          value: e.id,
                        }))}
                        onChange={(e) =>
                          SetFieldData({
                            ...fieldData,
                            formdynamic_type: e.value,
                          })
                        }
                        aria-describedby="field-type-error"
                      />
                    </div>
                  </Col>
                  <Col
                    lg={6}
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    <Label className="form-label fw-bold mt-2">
                      Field Required
                    </Label>
                    <FormGroup switch className="mb-3">
                      <Input
                        type="switch"
                        checked={isToggled}
                        onChange={handleToggle}
                        style={{
                          width: "60px",
                          height: "25px",
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col
                    lg={6}
                    className="d-flex flex-column justify-content-center align-items-center"
                  >
                    <Label className="form-label fw-bold mt-2">
                      Show Table
                    </Label>
                    <FormGroup switch className="mb-3">
                      <Input
                        type="switch"
                        checked={isToggledTable}
                        onChange={handleToggleTable}
                        style={{
                          width: "60px",
                          height: "25px",
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <Button type="submit" color="primary" onClick={(e) => SubmitData(e)}>
                <i className="ri-save-3-line align-bottom me-1"></i>
                Update
              </Button>
              <Button color="danger" onClick={Close}>
                <i className="ri-close-line me-1 align-middle" />
                Close
              </Button>
            </div>
          </Form>
        </span>
      </Modal>
    </div>
  );
};

export default FormFiledEdit;
