import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Row,
  Col,
  Button,
  Form,
  CardHeader,
  CardBody,
  Badge,
} from "reactstrap";
import StatusHandle from "./TaskStatus/StatusHandle";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import { toast } from "react-toastify";
import LeadStatusAdd from "../LeadStatus/LeadStatusAdd";
import SourceAdd from "../Source/SourceAdd";
import CustomerAdd from "../Customers/CustomerAdd";
import RefrencesAdd from "../Refrences/RefrencesAdd";
import ProductAdd from "../Product/ProductAdd";
import { IMG_API_URL } from "../../helpers/url_helper";
import D_img from "../D_img";
import ImgCom from "../Fullscreenimg/ImgCom";

const TaskView = (props) => {
  const [FindData, SetFind] = useState(props.edit_data);

  const [taskStatusModel, settaskStatusModel] = useState(false);
  const { http, https, user } = AuthUser();
  const [tempValid, settempValid] = useState(0);
  const [modal, setModal] = useState(false);
  const [taskData, setTask] = useState(props.edit_data);
  const [tempStatus, setTempStatus] = useState(props.edit_data.lead_status_id);
  const [followDate, setfollowDate] = useState(true);
  const [manageSource, setmanageSource] = useState(0);
  const [manageCustomer, setmanageCustomer] = useState(0);
  const [LateComplate, setLateComplate] = useState(0);
  const [Complate, setComplate] = useState(0);

  const [statusModal, setstatusModal] = useState(false);
  const [productModal, setproductModal] = useState(false);
  const [sourceModal, setsourceModal] = useState(false);
  const [customerModal, setcustomerModal] = useState(false);
  const [referBy, setreferBy] = useState(false);
  const [FollowUpListData, setFollowUpListData] = useState([]);

  const [StatusDetails, setStatusDetails] = useState([]);
  const getStatusDetails = async () => {
    http
      .get("/task_status/list")
      .then(function (response) {
        setStatusDetails(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getFollowUpListData = async () => {
    http
      .post("/follow_up/list", {
        leadOrTask: 1,
        follow_up_send_by:
          taskData.task_id != undefined ? taskData.task_id : "",
      })
      .then(function (response) {
        if (response.data.length > 0) {
          setFollowUpListData(response.data);
        } else {
          setFollowUpListData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [count, setCount] = useState(0);
  const handleCallback = (data, status, check) => {
    if (check == "status") {
      props.checkchang(data, status);
      props.setModalStates();
    } else if (status === 1) {
      toast.success(data);
      setCount(count + 1);
    } else {
      toast.warn(data);
      setCount(count + 1);
    }
    getStatusDetails();
    getstatusData();
    gettagData();
    getassignedBy();
    getcustomerData();
    getsourceData();
  };

  const [tagData, settagData] = useState([]);
  const gettagData = async () => {
    http
      .get("/tags/list")
      .then((res) => {
        if (res.data.length > 0) {
          settagData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [statusData, setstatusData] = useState([]);
  const getstatusData = async () => {
    http
      .get("/lead/list")
      .then((res) => {
        if (res.data.length > 0) {
          setstatusData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [sourceData, setsourceData] = useState([]);
  const getsourceData = async () => {
    http
      .get("/source/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageSource === 1) {
            setTask({
              ...taskData,
              source: res.data[res.data.length - 1].source_id,
            });
          }
          setsourceData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [customerData, setcustomerData] = useState([]);
  const getcustomerData = async () => {
    https
      .get("/customer/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageCustomer === 1) {
            setTask({
              ...taskData,
              customer: res.data[res.data.length - 1].customer_id,
            });
          }
          setcustomerData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [assignedBy, setassignedBy] = useState([]);
  const getassignedBy = async () => {
    https
      .get("/franchisebusiness/list")
      .then((res) => {
        if (res.data.length > 0) {
          setassignedBy(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getStatusDetails();
    // getcustomerData();
    getstatusData();
    // gettagData();
    getassignedBy();
    // getsourceData();
    getFollowUpListData();
  }, [count]);
  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const SubmitData = () => {
    if (taskData.assigned_by === "[]") {
      settempValid(1);
    } else if (taskData.customer === "") {
      settempValid(2);
    } else if (taskData.description === "") {
      settempValid(3);
    } else {
      https
        .put("/leads/update", taskData)
        .then(function (response) {
          props.checkchang(response.data.message, response.data.status);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // shortcuts for save and close
  const submitButtonRef = useRef();
  useEffect(() => {
    document.title = "View Task | CRM";
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const [tempCustomer, settempCustomer] = useState(0);
  const [assignedByData, setassignedByData] = useState([]);
  const [EmployeeProfile, SetEmployeeProfile] = useState("");
  const [EmployeeDoc, SetEmployeeDoc] = useState("");
  const [EmployeeBlob, setEmployeeBlob] = useState("");
  const [TaskVideo, setTaskVideo] = useState("");
  const getassignedByData = async () => {
    https
      .get("/franchisebusiness/list")
      .then((response) => {
        if (response.data.length > 0) {
          const filteredemployee = response.data.filter(
            (e) => e.u_type !== 3 && e.u_type !== 5
          );
          setassignedByData(filteredemployee);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getassignedByData();
  }, []);

  const openFileInNewTab = (fileType) => {
    let fileUrl;

    if (fileType === "audio" && taskData.task_audio_file) {
      fileUrl = `${IMG_API_URL}/task/audio/${taskData.task_audio_file
        .replace(/[\[\]']+/g, "")
        .trim()}`;
    } else if (fileType === "document" && taskData.task_document) {
      fileUrl = `${IMG_API_URL}/task/${taskData.task_document
        .replace(/[\[\]']+/g, "")
        .trim()}`;
    } else if (fileType === "image" && taskData.image) {
      fileUrl = `${IMG_API_URL}/task/${taskData.image
        .replace(/[\[\]']+/g, "")
        .trim()}`;
    } else if (fileType === "video" && taskData.task_video) {
      fileUrl = `${IMG_API_URL}/task/${taskData.task_video
        .replace(/[\[\]']+/g, "")
        .trim()}`;
    } else {
      alert("File not available or invalid file URL.");
      return;
    }

    if (!fileUrl || fileUrl === `${IMG_API_URL}/task/`) {
      alert("File not available or invalid file URL.");
      return;
    }

    // Open the file in a new tab instead of downloading
    window.open(fileUrl, "_blank");
  };

  const handleDownload = async (imageName) => {
    const imageUrl =
      IMG_API_URL + "/task/" + imageName.split(",")[0].replace(/[\[\]]/g, "");

    try {
      // Fetch the image as a blob
      const response = await fetch(imageUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch image");
      }

      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      // Extract the image filename and set it for the download
      const filename = imageName.split(",")[0].replace(/[\[\]]/g, "");
      link.download = filename;

      // Trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up the URL object and link element
      window.URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };

  const handleDownloadVideo = async (videoName) => {
    const videoUrl =
      IMG_API_URL + "/task/" + videoName.split(",")[0].replace(/[\[\]]/g, "");

    try {
      // Fetch the video as a blob
      const response = await fetch(videoUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch video");
      }

      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      // Extract the video filename and set it for the download
      const filename = videoName.split(",")[0].replace(/[\[\]]/g, "");
      link.download = filename;

      // Trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up the URL object and link element
      window.URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the video:", error);
    }
  };
  const handleDownloadAudio = async (audioFileName) => {
    const audioUrl =
      IMG_API_URL +
      "/task/audio/" +
      audioFileName.split(",")[0].replace(/[\[\]]/g, "");
    console.log("FSH", audioUrl);

    try {
      // Fetch the audio file as a blob
      const response = await fetch(audioUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch audio file");
      }

      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);

      // Extract the audio filename
      const filename = audioFileName.split(",")[0].replace(/[\[\]]/g, "");
      link.download = filename;

      // Trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up
      window.URL.revokeObjectURL(link.href);
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading the audio:", error);
    }
  };
  const handleDownloadDocument = (doc) => {
    if (!doc) return;

    // Construct the image URL
    const imageUrl =
      IMG_API_URL + "/task/" + doc.split(",")[0].replace(/[\[\]]/g, "");
    console.log("Downloading from URL:", imageUrl); // Log the URL for debugging

    fetch(imageUrl)
      .then((response) => {
        if (!response.ok) throw new Error("Network response was not ok");
        return response.blob(); // Get the file as a blob
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob); // Create a URL for the blob
        const link = document.createElement("a"); // Create an anchor element
        link.href = url;
        link.download = doc.split(",")[0]; // Set the default filename from the document
        document.body.appendChild(link); // Append the anchor to the DOM
        link.click(); // Programmatically click the link to start download
        document.body.removeChild(link); // Remove the anchor from the DOM
        window.URL.revokeObjectURL(url); // Revoke the URL to free memory
      })
      .catch((error) => console.error("Download failed:", error));
  };
  useEffect(() => {
    let PendingStatus = statusData.length > 0 ? statusData[0].lead_status_id : 0;
    let In_ProsessStatus = statusData.length > 0 ? statusData[1].lead_status_id : 0;
  }, [])
  return (
    <div>
      <Modal
        className="modal-xl" // Change size class to smaller one
        id="fullscreeexampleModal"
        isOpen={modal}
        size={"xl"} // Change size from "xl" to "md" for medium size
        toggle={toggle}
        centered
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          <h5 className="modal-title">View Task</h5>
        </ModalHeader>
        <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
          <Card className="border shadow-sm p-3">
            <div className="task-details">
              <div className="d-flex justify-content-between align-items-center mb-3">
                {/* <Label className="form-label fw-bold"><h3>Task Title:</h3></Label> */}
                <span>
                  <h3>{taskData.task_title}</h3>
                </span>
              </div>

              <Col lg={6}>
                <div className="d-flex justify-content-between align-items-center mb-3 mt-4">
                  <Label>Assigned By: </Label>
                  <span className="form-label fw-bold">{taskData.name}</span>
                </div>
              </Col>
              <Col lg={6}>
                <div className="d-flex justify-content-between align-items-center mb-3 mt-3">
                  <Label>Assigned To:</Label>
                  <span className="form-label fw-bold">
                    {" "}
                    <span>
                      {assignedByData && taskData.assigned_by
                        ? assignedByData.map((e) =>
                          JSON.parse(taskData.assigned_by).includes(e.uid) ? (
                            <div key={e.uid} className="p-1">
                              {e.name}
                            </div>
                          ) : null
                        )
                        : "Loading..."}
                    </span>
                  </span>
                </div>
              </Col>

              {/* 
              <div className="d-flex justify-content-between align-items-center mb-3">
                <Label className="form-label fw-bold">Team Member:</Label>
                <span>
                  {assignedByData && taskData.assigned_by
                    ? assignedByData.map((e) =>
                        JSON.parse(taskData.assigned_by).includes(e.uid) ? (
                          <div key={e.uid} className="p-1">
                            {e.name}
                          </div>
                        ) : null
                      )
                    : "Loading..."}
                </span>
              </div> */}
              <Col lg={6}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Label>Created At:</Label>
                  <span className="form-label fw-bold">
                    {new Date(taskData.minimum_due_date).toLocaleDateString("en-GB")}
                    <br />
                    {new Date(taskData.minimum_due_date).toLocaleTimeString("en-US", {
                      hour12: true,
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
              </Col>
              <Col lg={6}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Label>Due Date:</Label>
                  <span className="form-label fw-bold">
                    {new Date(taskData.maximum_due_date).toLocaleDateString("en-GB")}
                    <br />
                    {new Date(taskData.maximum_due_date).toLocaleTimeString("en-US", {
                      hour12: true,
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </span>
                </div>
              </Col>

              {taskData.repeat_category == null ? (
                ""
              ) : (
                <>
                  <Col lg={6}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Label> Frequency:</Label>
                      <span className="form-label fw-bold">
                        {taskData.repeat_category
                          .split(",")[0]
                          .replace(/[\[\]]/g, "")}
                        <br />
                        {taskData.repeat_every_day}
                      </span>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="d-flex justify-content-between align-items-center mb-3">
                      <Label> Frequency Date :</Label>
                      <span className="form-label fw-bold">
                        {new Date(taskData.repeat_startDate).toLocaleDateString("en-GB")}
                        <br />
                        {new Date(taskData.repeat_startDate).toLocaleTimeString("en-US", {
                          hour12: true,
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>
                    </div>
                  </Col>
                </>
              )}
              <Col lg={6}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Label> Status:</Label>
                  <Badge
                    color={taskData.lead_status_color}
                  // style={{ padding: '10px 20px', fontSize: '16px' }}
                  >
                    {taskData.lead_status_name}
                  </Badge>
                </div>
              </Col>

              <Col lg={6}>
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <Label>Priority:</Label>
                  <span className="form-label fw-bold">
                    {taskData.priority === 1 ? (
                      <>
                        <i className="ri-flag-2-fill fs-16 text-dark"></i> {" "}
                        <span>Low </span>
                      </>
                    ) : taskData.priority === 2 ? (
                      <>
                        <i className="ri-flag-2-fill fs-16 text-warning"></i>
                        <span>Medium </span>
                      </>
                    ) : taskData.priority === 3 ? (
                      <>
                        <i className="ri-flag-2-fill fs-16 text-danger"></i>
                        <span>High </span>
                      </>
                    ) : taskData.priority === 4 ? (
                      <>
                        <i className="ri-flag-2-fill fs-16 text-info"></i>
                        <span>Medium </span>
                      </>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </Col>
              {taskData.description && (
                <Col lg={6}>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <Label>Task Description:</Label>
                    <span className="form-label fw-bold">
                      {taskData.description}
                    </span>
                  </div>
                </Col>
              )}

              <Row>
                <div className="d-flex justify-content-between align-items-center w-100">
                  {/* Image Column */}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <input
                      className="form-control d-none"
                      id="image"
                      type="file"
                      accept="image/png, image/gif, image/jpeg"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        console.log(file);
                        setTask({
                          ...taskData,
                          image: file,
                        });
                        SetEmployeeProfile(file);
                      }}
                      readOnly
                    />
                    <div className="avatar-lg">
                      {EmployeeProfile ? (
                        <img
                          src={URL.createObjectURL(EmployeeProfile)}
                          alt=""
                          className="h-auto"
                          width={"100px"}
                          onClick={() => openFileInNewTab("image")}
                        />
                      ) : taskData.image && taskData.image !== "/user/null" ? (
                        <img
                          src={`${IMG_API_URL}/task/${taskData.image
                            ? taskData.image.replace(/[\[\]']+/g, "").trim()
                            : ""
                            }`}
                          alt=""
                          className="h-auto"
                          width={"100px"}
                          onClick={() => openFileInNewTab("image")}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  {/* Video Column */}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <input
                      className="form-control d-none"
                      id="task_video"
                      type="file"
                      accept="video/mp4, video/avi, video/mkv"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        console.log(file);
                        setTask({
                          ...taskData,
                          task_video: file,
                        });
                        setTaskVideo(file);
                      }}
                      readOnly
                    />
                    <div className="avatar-lg">
                      {TaskVideo ? (
                        <video
                          src={URL.createObjectURL(TaskVideo)}
                          height={"100px"}
                          width={"100px"}
                          controls
                          onClick={() => openFileInNewTab("video")}
                        />
                      ) : taskData.task_video &&
                        taskData.task_video !== "/user/null" ? (
                        <video
                          src={`${IMG_API_URL}/task/${taskData.task_video
                            ? taskData.task_video
                              .replace(/[\[\]']+/g, "")
                              .trim()
                            : ""
                            }`}
                          height={"100px"}
                          width={"100px"}
                          controls
                          onClick={() => openFileInNewTab("video")}
                        />
                      ) : (
                        " "
                      )}
                    </div>
                  </div>

                  {/* Document Column */}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="position-relative mx-2">
                      <div className="avatar-title bg-light rounded">
                        {EmployeeDoc ? (
                          <div
                            className="d-flex align-items-center"
                            onClick={() => openFileInNewTab("document")}
                          >
                            <i
                              className="ri-file-fill"
                              style={{
                                fontSize: "30px",
                                marginRight: "10px",
                                color: "#000",
                                cursor: "pointer",
                              }}
                            ></i>
                            <span style={{ cursor: "pointer" }}>
                              {EmployeeDoc.name}
                            </span>
                          </div>
                        ) : taskData.task_document ? (
                          <div
                            className="d-flex align-items-center"
                            onClick={() => openFileInNewTab("document")}
                          >
                            <i
                              className="ri-file-fill"
                              style={{
                                fontSize: "30px",
                                marginRight: "10px",
                                color: "#000",
                                cursor: "pointer",
                              }}
                            ></i>
                            <span style={{ color: "#000", cursor: "pointer" }}>
                              {taskData.task_document.split("/").pop()}
                            </span>
                          </div>
                        ) : (
                          " "
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Audio Column */}
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <div className="position-relative mx-2">
                      <div className="avatar-title bg-light rounded">
                        {EmployeeBlob ? (
                          <div className="d-flex align-items-center mb-2">
                            <audio controls style={{ marginRight: "10px" }}>
                              <source
                                src={URL.createObjectURL(EmployeeBlob)}
                                type="audio/wav"
                              />
                              Your browser does not support the audio element.
                            </audio>
                            <span>{EmployeeBlob.name}</span>
                          </div>
                        ) : taskData.task_audio_file &&
                          taskData.task_audio_file !== "/user/null" ? (
                          <div className="d-flex align-items-center">
                            <i
                              className="ri-file-fill"
                              style={{
                                fontSize: "30px",
                                marginRight: "10px",
                                color: "#000",
                                cursor: "pointer",
                              }}
                              onClick={() => openFileInNewTab("audio")}
                            ></i>
                            <span style={{ color: "#000" }}>
                              {taskData.task_audio_file
                                ? taskData.task_audio_file
                                  .split("/")
                                  .pop()
                                  .substring(0, 15) + "..."
                                : ""}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
              <Row>
                <Col lg={12} className="mb-3 ">
                  <div className="d-flex justify-content-start gap-3">
                    {(statusData.length > 0 && statusData[3].lead_status_id != taskData.lead_status_id && statusData[2].lead_status_id != taskData.lead_status_id && statusData[4].lead_status_id != taskData.lead_status_id) &&
                      <>
                        {
                          statusData
                            .filter(
                              (_, index) => index != 3 && index != 4 && index != 0
                            ) // Filter out elements at index 3 and 4
                            .map((data, index) => {
                              return (
                                <div key={index}>
                                  <div
                                    onClick={() => {
                                      if (index == 1) {
                                        setfollowDate(false);
                                      } else {
                                        setfollowDate(true);
                                      }
                                      setTempStatus(data.lead_status_id);
                                      settaskStatusModel(!taskStatusModel);
                                    }}
                                    className={
                                      `rounded fw-bold btn btn-outline-${data.lead_status_color}`
                                    }
                                  >
                                    {data.lead_status_name}
                                  </div>
                                </div>
                              );
                            })
                        }
                      </>
                    }
                    {(statusData.length > 0 && (statusData[2].lead_status_id == taskData.lead_status_id || statusData[4].lead_status_id == taskData.lead_status_id)) &&
                      <div>
                        <Button
                          onClick={() => {
                            setTempStatus(0);
                            settaskStatusModel(!taskStatusModel);
                          }}
                          className={
                            "rounded fw-bold btn-outline-warning"
                          }
                        >
                          Re-Open
                        </Button>
                      </div>}
                    {(statusData.length > 0 && statusData[3].lead_status_id != taskData.lead_status_id) &&
                      <div
                        className="shadow d-flex justify-content-center align-items-center p-2 rounded-circle"
                        style={{
                          width: "40px",
                          height: "40px",
                          backgroundColor: "#f0f0f0",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          settaskStatusModel(!taskStatusModel);
                        }}
                      >
                        <i
                          className="ri-chat-3-line align-middle fs-16 text-primary"
                          style={{ transition: "color 0.3s ease" }}
                        ></i>
                      </div>
                    }
                  </div>
                </Col>
                {(statusData.length > 0 && statusData[3].lead_status_id == taskData.lead_status_id) ?
                  <Col lg={12} className="mb-3 ">
                    <div className="d-flex justify-content-start gap-3">

                      <div  >
                        <div
                          onClick={() => {
                            setfollowDate(false);
                            setTempStatus(statusData[4].lead_status_id);
                            settaskStatusModel(!taskStatusModel);
                          }}
                          className={
                            `rounded fw-bold btn btn-outline-${statusData[4].lead_status_color}`
                          }
                        >
                          {statusData[4].lead_status_name}
                        </div>
                      </div>


                      <div
                        className="shadow d-flex justify-content-center align-items-center p-2 rounded-circle"
                        style={{
                          width: "40px",
                          height: "40px",
                          backgroundColor: "#f0f0f0",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          settaskStatusModel(!taskStatusModel);
                        }}
                      >
                        <i
                          className="ri-chat-3-line align-middle fs-16 text-primary"
                          style={{ transition: "color 0.3s ease" }}
                        ></i>
                      </div>

                      <div></div>
                    </div>
                  </Col> : ""}

                <Col lg={12}>
                  <div className="table-responsive">
                    <table
                      role="table"
                      className="align-middle table-nowrap text-center table table-hover"
                    >
                      <thead className="table-light text-muted text-uppercase">
                        <tr>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            #
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Image
                          </th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Date & Time
                          </th>
                          <th>Video</th>
                          <th>Audio</th>
                          <th>Document</th>
                          <th>Status</th>
                          <th
                            title="Toggle SortBy"
                            style={{ cursor: "pointer" }}
                          >
                            Description
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {StatusDetails.filter(
                          (temp) => temp.status_task_id == taskData.task_id
                        ).map((item, index) => (
                          <tr key={index} className="p-2">
                            <td>{index + 1}</td>
                            <td>
                              {item.status_image &&
                                item.status_image !== "[]" ? (
                                <>
                                  <ImgCom
                                    imageUrl={
                                      IMG_API_URL +
                                      "/task/" +
                                      item.status_image
                                        .split(",")[0]
                                        .replace(/[\[\]]/g, "")
                                    }
                                    style={{ height: "100px", width: "100px" }}
                                  />
                                  <br />
                                  <button
                                    className="btn btn-info shadow-lg btn-sm d-flex align-items-center justify-content-center"
                                    onClick={() =>
                                      handleDownload(item.status_image)
                                    }
                                  >
                                    <span className="me-1">Download</span>{" "}
                                    {/* Margin to space text and icon */}
                                    <i className="ri-download-2-fill fs-16 text-white" />
                                  </button>
                                </>
                              ) : (
                                <p>
                                  No image <br /> available
                                </p>
                              )}
                            </td>
                            
                            <td>
                              {new Date(item.status_date).toLocaleDateString(
                                "en-GB"
                              )}
                              <br />
                              {
                                new Date(item.status_date).toLocaleString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true })
                              }
                            </td>
                            <td>
                              {item.status_video &&
                                item.status_video !== "/user/null" ? (
                                <div className="d-flex flex-column align-items-center">
                                  <video
                                    width="100" // Adjust the width as needed
                                    height="100" // Adjust the height as needed
                                    controls // Adds video controls like play, pause, etc.
                                  >
                                    <source
                                      src={
                                        IMG_API_URL +
                                        "/task/" +
                                        item.status_video
                                          .split(",")[0]
                                          .replace(/[\[\]]/g, "")
                                      }
                                      type="video/mp4" // Make sure to specify the correct type (e.g., video/mp4)
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                  <div className="mt-2">
                                    {" "}
                                    {/* This div wraps the button for spacing */}
                                    <button
                                      className="btn btn-info shadow-lg btn-sm d-flex align-items-center justify-content-center"
                                      onClick={() =>
                                        handleDownloadVideo(item.status_video)
                                      }
                                    >
                                      <span className="me-1">Download</span>{" "}
                                      {/* Margin to space text and icon */}
                                      <i className="ri-download-2-fill fs-16 text-white" />
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <p>
                                  No video <br /> available
                                </p>
                              )}
                            </td>

                            <td
                              style={{
                                position: "relative",
                                paddingBottom: "50px",
                              }}
                            >
                              {" "}
                              {/* Added padding for space */}
                              {item.status_document &&
                                item.status_document !== "/user/null" ? (
                                <div className="d-flex flex-column align-items-center">
                                  <audio
                                    controls
                                    style={{
                                      width: "250px",
                                      paddingBottom: "30px",
                                    }}
                                  >
                                    <source
                                      src={
                                        IMG_API_URL +
                                        "/task/audio/" +
                                        item.status_audio_file
                                          .split(",")[0]
                                          .replace(/[\[\]]/g, "")
                                      }
                                      type="audio/mpeg" // Ensure this matches the actual file type
                                    />
                                    Your browser does not support the audio
                                    element.
                                  </audio>
                                  {/* Button now fixed at the bottom of the <td> */}
                                  <button
                                    className="btn btn-info shadow-lg btn-sm d-flex align-items-center justify-content-center mt-2" // Remove margin-top for spacing
                                    style={{
                                      position: "absolute",
                                      bottom: "10px",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                    }} // Center the button
                                    onClick={() =>
                                      handleDownloadAudio(
                                        item.status_audio_file
                                      )
                                    } // Pass the audio file to the download function
                                  >
                                    <span className="me-1">Download</span>
                                    <i className="ri-download-2-fill fs-16 text-white" />
                                  </button>
                                </div>
                              ) : (
                                <p>
                                  No Document <br /> available
                                </p>
                              )}
                            </td>

                            <td
                              className="text-center"
                              style={{
                                position: "relative",
                                paddingBottom: "50px",
                              }}
                            >
                              {/* Added padding for space */}
                              {item.status_document &&
                                item.status_document !== "/user/null" ? (
                                <div className="d-flex flex-column align-items-center">
                                  {/* Display document name */}
                                  <p className="mb-2">
                                    {item.status_document.split("/").pop()}{" "}
                                    {/* Extract file name */}
                                  </p>
                                  <button
                                    className="btn btn-info shadow-lg btn-sm d-flex align-items-center justify-content-center"
                                    style={{
                                      position: "absolute",
                                      bottom: "10px",
                                      left: "50%",
                                      transform: "translateX(-50%)",
                                    }} // Center the button
                                    onClick={() => {
                                      console.log("Download button clicked"); // Log when button is clicked
                                      console.log(item.status_document); // Log the document status
                                      handleDownloadDocument(
                                        item.status_document
                                      );
                                    }}
                                  >
                                    <span className="me-1">Download</span>
                                    <i className="ri-download-2-fill fs-16 text-white" />
                                  </button>
                                </div>
                              ) : (
                                <p>No Document available</p>
                              )}
                            </td>

                            <td>{item.lead_status_name}</td>
                            <td>{item.status_description}</td>
                          </tr>
                        ))}
                        {StatusDetails.filter(
                          (temp) => temp.status_task_id == taskData.task_id
                        ).length === 0 ? (
                          <tr>
                            <td colSpan={8} align="center">
                              <div className="fw-bold text-danger">
                                No Status Changes yet.
                              </div>
                            </td>
                          </tr>
                        ) : (
                          ""
                        )}
                      </tbody>
                    </table>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </ModalBody>
        <div className="modal-footer">
          <Button color="danger" onClick={Close}>
            <i className="ri-close-line me-1" /> Close
          </Button>
        </div>
      </Modal>

      {statusModal === true ? (
        <LeadStatusAdd
          modalStates={statusModal}
          setModalStates={() => {
            setstatusModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {productModal === true ? (
        <ProductAdd
          modalStates={productModal}
          setModalStates={() => {
            setproductModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {referBy === true ? (
        <RefrencesAdd
          modalStates={referBy}
          setModalStates={() => {
            setreferBy(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {customerModal === true ? (
        <CustomerAdd
          modalStates={customerModal}
          setModalStates={() => {
            setcustomerModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {sourceModal === true ? (
        <SourceAdd
          modalStates={sourceModal}
          setModalStates={() => {
            setsourceModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {taskStatusModel === true ? (
        <StatusHandle
          modalStates={taskStatusModel}
          setModalStates={() => {
            settaskStatusModel(!taskStatusModel);
          }}
          follow={followDate}
          status={tempStatus}
          checkchang={handleCallback}
          edit_data={FindData}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default TaskView;
