import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Button,
  Row,
  Col,
  Form,
} from "reactstrap";

import Flatpickr from "react-flatpickr";

import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import axios from "axios";
import { toast } from "react-toastify";
import Select from "react-select";
import LeadStatusAdd from "../LeadStatus/LeadStatusAdd";
import SourceAdd from "../Source/SourceAdd";
import CustomerAdd from "../Customers/CustomerAdd";
import RefrencesAdd from "../Refrences/RefrencesAdd";
import ProductAdd from "../Product/ProductAdd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import D_img from "../D_img";
import { API_URL } from "../../helpers/url_helper";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import FormFiledAdd from "../Product/FormFiledAdd";
import FormFiledEdit from "../Product/FormFiledEdit";
import PipeLineCreate from "../PipeLine/PipeLineCreate";
import { color } from "echarts";
import CreateProduct from "../Product/CreateProduct";

const LeadsAdd = (props) => {
  const { http, https, user } = AuthUser();
  const [smsStatus, SetsmsStatus] = useState(false);
  const [filedList, setFiledList] = useState([]);
  const [count, setCount] = useState(0);
  const [pipelineData, SetPipelineData] = useState([]);
  const [pipelineStageData, SetPipelineStageData] = useState([]);
  const [tempValid, settempValid] = useState(0);
  const [modal, setModal] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const recorderControls = useAudioRecorder();
  const [formmodalStates, setformModalStates] = useState(false);
  const [formmodalEdit, setformModalEdit] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [fieldValues, setFieldValues] = useState({});
  const [valid, setValid] = useState(null);
  const [filedListvalid, setFiledListvalid] = useState([]);
  const handleFieldChange = (e, fieldId) => {
    const { value } = e.target;
    setFieldValues((prevState) => ({
      ...prevState,
      [fieldId]: value,
    }));
  };
  const [formData, setFormData] = useState(() => {
    const safeFiledList = Array.isArray(filedList) ? filedList : [];
    return safeFiledList.reduce((acc, temp) => {
      acc[temp.formdynamic_path] = "";
      return acc;
    }, {});
  });
  useEffect(() => {
    if (Array.isArray(filedList)) {
      const initialFieldValues = filedList.reduce((acc, temp) => {
        acc[temp.formdynamic_id] = ""; // Initialize all fields with empty values
        return acc;
      }, {});
      setFieldValues(initialFieldValues);
    }
  }, [filedList]);

  const formfiledList = () => {
    http
      .get("/formdynamic/list")
      .then((res) => {
        const filterData = res.data.filter(
          (temp) => temp.formdynamic_category == 3
        );
        setFiledList(filterData);
        const filterDataValid = res.data.filter(
          (temp) => temp.formdynamic_category == 3 && temp.formdynamic_status == 2 || temp.formdynamic_status == 4
        );
        setFiledListvalid(filterDataValid)
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    formfiledList();
  }, [count]);
  const [FindData, SetFind] = useState([]);

  const EditUpdate = (ID) => {
    const FindArray = filedList.find((item) => item.formdynamic_id === ID);
    SetFind(FindArray);
    setformModalEdit(!formmodalEdit);
  };
  const HandleDelete = (formdynamic_id) => {
    setDeleteId(formdynamic_id);
    setConfirmDeleteModal(true);
  };
  const confirmDelete = async () => {
    try {
      const response = await https.delete(`/formdynamic/delete/${deleteId}`);
      toast.success("Field  deleted successfully.");
      setConfirmDeleteModal(false);
      formfiledList();
    } catch (error) {
      console.error("Error deleting Field :", error);
      toast.error("Failed to delete Field .");
      setConfirmDeleteModal(false);
    }
  };

  const [leadsData, setLeads] = useState({
    customer: "",
    product: 1,
    department:
      user.user.u_type != 1 || user.user.u_type != 3 || user.user.u_type != 5
        ? user.user.user_role_id
        : 0,
    value: 0,
    today_date: new Date(),
    minimum_due_date: new Date(),
    ref_by: 1,
    maximum_due_date: new Date(),
    source: 1,
    priority: 1,
    description: "",
    assigned_by:
      user.user.u_type == 4 || user.user.u_type == 5 || user.user.u_type == 2
        ? `[${user.user.uid}]`
        : "[]",
    tags: "[]",
    status: 1,
    image: [],
    lead_pipeline_id: "",
    lead_pipeline_stage_id: "",
    repeat_every_day: "",
    next_follow_up: new Date(),
    total_cycles: "",
    lead_created_by: user.user.uid,
  });

  const [isRecording, setIsRecording] = useState(false);
  const handleRecorderClick = () => {
    setIsRecording(true);
  };
  const addAudioElement = (blob) => {
    const uniqueFileName = `recording_${Date.now()}_${Math.floor(Math.random() * 10000)}.mp3`;

    const mp3File = new File([blob], uniqueFileName, { type: 'audio/mp3' });

    setAudioBlob(mp3File);
    setLeads({
      ...leadsData,
      audio_file: mp3File
    });
    setIsRecording(false)
  };

  const handleImageChange = (e) => {
    const selectedImages = Array.from(e.target.files);

    setLeads({
      ...leadsData,
      image: [...leadsData.image, ...selectedImages],
    });
  };

  const handleRemoveImage = (index) => {
    const updatedImages = [...leadsData.image];
    updatedImages.splice(index, 1);

    setLeads({
      ...leadsData,
      image: updatedImages,
    });
  };
  const [EmployeeDoc, SetEmployeeDoc] = useState("");
  const [EmployeeVideo, SetEmployeeVideo] = useState("");
  const [manageSource, setmanageSource] = useState(0);
  const [manageProduct, setmanageProduct] = useState(0);
  const [manageCustomer, setmanageCustomer] = useState(0);
  const [manageLeadStatus, setmanageLeadStatus] = useState(0);
  const [manageReferBy, setmanageReferBy] = useState(0);

  const [statusModal, setstatusModal] = useState(false);
  const [productModal, setproductModal] = useState(false);
  const [sourceModal, setsourceModal] = useState(false);
  const [customerModal, setcustomerModal] = useState(false);
  const [referBy, setreferBy] = useState(false);

  const priorityData = [
    { label: "Low", value: "1" },
    { label: "Medium", value: "2" },
    { label: "High", value: "3" },
    { label: "Urgent", value: "4" },
  ];

  const handleCallback = (data, status) => {


    if (status == 1) {

      toast.success(data);
      setCount(count + 1);
    } else {
      toast.warn(data);
      setCount(count + 1);
    }
    getcustomerData();
    getproductData();
    getreferByData();
    getstatusData();
    gettagData();
    getAssignedBy();
    getcustomerData();
    getsourceData();
    setstatusModal(false);
  };

  const [tagData, settagData] = useState([]);
  const gettagData = async () => {
    http
      .get("/tags/list")
      .then((res) => {
        if (res.data.length > 0) {
          settagData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [statusData, setstatusData] = useState([]);
  const getstatusData = async () => {
    http
      .get("/lead/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageLeadStatus === 1) {
            setLeads({
              ...leadsData,
              status: res.data[res.data.length - 1].lead_status_id,
            });
          }
          setstatusData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [referByData, setreferByData] = useState([]);
  const getreferByData = async () => {
    http
      .get("/reference/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageReferBy === 1) {
            setLeads({
              ...leadsData,
              ref_by: res.data[res.data.length - 1].reference_id,
            });
          }
          setreferByData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [productData, setproductData] = useState([]);
  const getproductData = async () => {
    http
      .get("/product/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageProduct === 1) {
            setLeads({
              ...leadsData,
              product: res.data[res.data.length - 1].product_id,
            });
          }
          setproductData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const fetchData = () => {
    https
      .get(`/pipeline/list`)
      .then(function (response) {
        const filterData = response.data
        SetPipelineData(filterData);
      })
      .catch(function (error) {
        console.log(error);
      });

    https
      .get(`/pipeline/stage/list`)
      .then(function (response) {
        SetPipelineStageData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const [sourceData, setsourceData] = useState([]);
  const getsourceData = async () => {
    http
      .get("/source/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageSource === 1) {
            setLeads({
              ...leadsData,
              source: res.data[res.data.length - 1].source_id,
            });
          }
          setsourceData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [customerData, setcustomerData] = useState([]);
  const getcustomerData = async () => {
    http
      .get("/customer/list")
      .then((res) => {
        if (res.data.length > 0) {
          if (manageCustomer === 1) {
            setLeads({
              ...leadsData,
              customer: res.data[res.data.length - 1].customer_id,
            });
          }
          setcustomerData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [assignedBy, setAssignedBy] = useState([]);
  const getAssignedBy = async () => {
    try {
      const res = await http.get("/franchisebusiness/list");
      const filteredEmployee = res.data.filter(
        (e) => e.u_type !== 3 && e.u_type !== 5
      );
      if (selectedRoleIds !== null) {
        const filteredEmployee = res.data.filter(
          (e) =>
            e.u_type !== 3 &&
            e.u_type !== 5 &&
            (e.user_role_id === selectedRoleIds ||
              e.role_created_for == selectedRoleIds)
        );
        setAssignedBy(filteredEmployee);
      } else {
        setAssignedBy(filteredEmployee);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [selectedRoleIds, setSelectedRoleIds] = useState(
    user.user.u_type == 2 && user.user.user_behave == 1
      ? user.user.user_role_id
      : null
  );
  const [selectedRoleUserIds, setSelectedRoleUserIds] = useState([]);
  const [statuscheckforAssignedBy, setstatuscheckforAssignedBy] = useState(0);
  const [role, setRole] = useState([]);

  useEffect(() => {
    fetchData();
    getAssignedBy();
  }, [statuscheckforAssignedBy, count]);
  const getRole = async () => {
    try {
      const response = await http.get("/role/list");
      const filteredData =
        user && (user.user.u_type === 1 || user.user.u_type === 2)
          ? response.data.filter(
            (role) =>
              parseInt(role.role_created_user_id) ===
              user.user.user_user_id ||
              parseInt(role.role_created_user_id) === user.user.uid
          )
          : response.data.filter(
            (role) => parseInt(role.role_created_user_id) === user.user.uid
          );
      setRole(filteredData);
    } catch (error) {
      console.log(error);
    }
  };

  const [message, setmessage] = useState([]);
  const getMessageList = async () => {
    http
      .get("/message/list")
      .then((res) => {
        if (res.data.length > 0) {
          setmessage(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getcustomerData();
    getproductData();
    getreferByData();
    getMessageList();
    getstatusData();
    gettagData();
    getRole();
    getsourceData();
  }, [count]);

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };
  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const [Selecteduser, SetSelectedUser] = useState([]);
  useEffect(() => {
    const assignedByArray = JSON.parse(leadsData.assigned_by);

    const users = assignedByArray.map((userId) =>
      assignedBy.find((user) => user.uid === userId)
    );

    SetSelectedUser(users.filter(Boolean));
  }, [leadsData.assigned_by, assignedBy]);

  const [loading, setLoading] = useState(false);

  const validateFields = (fields, values) =>
    fields.filter(
      (field) => field.formdynamic_status === 2 && !values[field.formdynamic_id]
    );





  const SubmitData = async () => {
    setValid(null);

    // return console.log(leadsData);


    const structuredData = filedList.reduce((acc, field) => {
      let value = fieldValues[field.formdynamic_id] || "";
      if (!field.formdynamic_path || typeof field.formdynamic_path !== "string") {
        console.error(
          `Invalid formdynamic_path for field: ${JSON.stringify(field)}`
        );
        return acc;
      }
      const keys = field.formdynamic_path.split(".");
      let currentLevel = acc;
      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          currentLevel[key] = value;
        } else {
          currentLevel[key] = currentLevel[key] || {};
          currentLevel = currentLevel[key];
        }
      });

      return acc;
    }, {});




    if (loading) return;
    setLoading(true);

    try {
      
      if (!leadsData.assigned_by || leadsData.assigned_by === "[]") return settempValid(1);
      if (!leadsData.customer) { return settempValid(2) }
      else {

        let isValidSet = false;
        Object.entries(structuredData).forEach(([key, value], index) => {
          for (const temp of filedListvalid) {
            if (temp.formdynamic_path === key) {
              if (value == null || value === "") {
                if (!isValidSet) {
                  setValid(key);
                  isValidSet = true;
                }
                return;
              }
            }
          }
        });

        Object.entries(structuredData).forEach(([key, value], index) => {
          if (value == null || value === "") {

            value = null

          }

        });

        if (!isValidSet) {

          const response = await https.post(
            "/leads/store",
            {
              ...leadsData,
              lead_fields: structuredData,
            }
          );

          props.setModalStates(false);
          props.checkchang(response.data.message, 1);
        }
      }

    } catch (error) {
      const errorMessage =
        error.response?.data?.message || "Failed to save the Lead.";
      console.error("Lead API Call Error:", error.response?.data || error.message);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };


  const sendSMS = async () => {
    if (Selecteduser.length === 0) {
      console.error("No selected user to send SMS");
      return;
    }

    const url = `${API_URL}/send-msg`;
    try {
      const filteredCustomerData = customerData.filter(
        (customer) => customer.customer_id === leadsData.customer
      );
      const number = filteredCustomerData[0].customer_whatsapp_no;
      const data = new URLSearchParams();
      data.append("number", number);
      data.append("type", 2);
      const response = await axios.post(url, data, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        timeout: 100000,
      });
    } catch (e) {
      console.log(e);
    }
  };
  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  const [tempCustomer, settempCustomer] = useState(0);
  const [isDescriptionListening, setIsDescriptionListening] = useState(false);
  const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
    useSpeechRecognition();

  const numberAndSymbolMapping = {
    zero: "0",
    one: "1",
    two: "2",
    three: "3",
    four: "4",
    five: "5",
    six: "6",
    seven: "7",
    eight: "8",
    nine: "9",
    dot: ".",
    comma: ",",
    dash: "-",
    plus: "+",
    at: "@",
    hashtag: "#",
    dollar: "$",
    percent: "%",
    ampersand: "&",
    asterisk: "*",
    exclamation: "!",
    question: "?",
    slash: "/",
    backslash: "\\",
  };

  const convertSpokenNumbersToDigits = (text) => {
    return text
      .split(" ")
      .map((word) => numberAndSymbolMapping[word.toLowerCase()] || word)
      .join(" ");
  };

  useEffect(() => {
    if (isDescriptionListening && transcript) {
      const convertedTranscript = convertSpokenNumbersToDigits(transcript);
      setLeads((prevData) => ({
        ...prevData,
        description: prevData.description + " " + convertedTranscript,
      }));
      resetTranscript();
    }
  }, [transcript, isDescriptionListening]);

  useEffect(() => {
    if (isDescriptionListening) {
      SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
    } else {
      SpeechRecognition.stopListening();
    }
  }, [isDescriptionListening]);

  const handleDescriptionMicClick = () => {
    setIsDescriptionListening(!isDescriptionListening);
  };

  if (!browserSupportsSpeechRecognition) {
    return <div>Your browser does not support speech recognition.</div>;
  }

  return (
    <div>
      <Modal
        className="modal-xl"
        id="fullscreeexampleModal"
        isOpen={modal}
        size={"xl"}
        toggle={toggle}
        centered
      >
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Create Leads
        </ModalHeader>
        <span className="tablelist-form">
          <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                SubmitData();
                return false;
              }}
              action="#"
            >
              <Card className="border card-border-success p-3 shadow-lg">
                <div className="mb-3">
                  <Row>
                    {user.user.u_type === 1 || user.user.u_type === 3 ? (
                      <>
                        <Col lg={3}>
                          <Label
                            htmlFor="categoryname-field"
                            className="form-label fw-bold d-flex justify-content-between  "
                          >
                            <div>
                              Department<span className="text-danger"> *</span>
                            </div>
                            <div style={{ color: "red" }}>
                              {tempValid === 1
                                ? "Please select the assigned!"
                                : ""}
                            </div>
                          </Label>
                          <Select
                            styles={{
                              control: (provided, state) => ({
                                ...provided,
                                backgroundColor: state.isFocused
                                  ? "white"
                                  : "white",
                              }),
                            }}
                            className={
                              tempValid === 1 ? "border border-danger" : ""
                            }
                            options={role.map((e) => ({
                              value: e.role_id,
                              label: e.role_name,
                            }))}
                            onChange={(e) => {
                              setLeads({
                                ...leadsData,
                                department: e.value,
                              });
                              setSelectedRoleIds(e.value);
                              setstatuscheckforAssignedBy(
                                statuscheckforAssignedBy + 1
                              );
                            }}
                          />
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  "
                      >
                        <div>
                          Team Member<span className="text-danger"> *</span>
                        </div>
                        <div style={{ color: "red" }}>
                          {tempValid === 1 ? "Please select the assigned!" : ""}
                        </div>
                      </Label>
                      {user.user.u_type === 1 ||
                        user.user.u_type === 3 ||
                        (user.user.u_type === 2 && user.user.user_behave == 1) ? (
                        <Select
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused
                                ? "white"
                                : "white",
                            }),
                          }}
                          className={
                            tempValid === 1 ? "border border-danger" : ""
                          }
                          options={[
                            {
                              label: "Managers",
                              options: assignedBy
                                .filter(
                                  (e) =>
                                    e.user_behave === 1 &&
                                    (selectedRoleUserIds.length === 0 ||
                                      selectedRoleUserIds.includes(
                                        e.uid.toString()
                                      ))
                                )
                                .map((e, i) => ({
                                  value: e.uid,
                                  label: e.name,
                                  index: i,
                                  behaveType: e.user_behave,
                                })),
                            },
                            {
                              label: "Employees",
                              options: assignedBy
                                .filter(
                                  (e) =>
                                    e.user_behave === 0 &&
                                    (selectedRoleUserIds.length === 0 ||
                                      selectedRoleUserIds.includes(
                                        e.uid.toString()
                                      ))
                                )
                                .map((e, i) => ({
                                  value: e.uid,
                                  label: e.name,
                                  index: i,
                                  behaveType: e.user_behave,
                                })),
                            },
                          ]}
                          isMulti
                          onChange={(e) => {
                            if (e) {
                              const selectedValues = e.map(
                                (option) => option.value
                              );
                              setLeads({
                                ...leadsData,
                                assigned_by: `[${selectedValues.join(",")}]`,
                              });
                            }
                            settempValid(0);
                          }}
                        />
                      ) : (
                        <Input
                          value={user.user.name}
                          disabled
                          className="fw-bold"
                        />
                      )}
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  "
                      >
                        <div>Pipe Line</div>
                        <button
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageLeadStatus(1);
                            setstatusModal(!statusModal);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused
                              ? "white"
                              : "white",
                          }),
                        }}
                        options={pipelineData.map((e, i) => ({
                          value: e.pipeline_id,
                          label: e.pipeline_name,
                        }))}
                        className="fw-bold"
                        name="leads_status"
                        id="leads_status"
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            lead_pipeline_id: e.value,
                          });
                        }}
                        placeholder={
                          pipelineData.length != 0
                            ? manageLeadStatus == 0
                              ? pipelineData[0].pipeline_name
                              : pipelineData[pipelineData.length - 1]
                                .pipeline_name
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  "
                      >
                        <div>Stage</div>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused
                              ? "white"
                              : "white",
                          }),
                        }}
                        options={pipelineStageData.filter((temp) => temp.pipeline_master_id == leadsData.lead_pipeline_id).map((e, i) => ({
                          value: e.pipeline_child_id,
                          label: e.pipeline_stages,
                        }))}
                        className="fw-bold"
                        name="leads_status"
                        id="leads_status"
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            lead_pipeline_stage_id: e.value,
                          });
                        }}
                        placeholder={
                          pipelineStageData.length != 0
                            ? manageLeadStatus == 0
                              ? pipelineStageData[0].lead_status_name
                              : pipelineStageData[pipelineStageData.length - 1]
                                .lead_status_name
                            : ""
                        }
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  "
                      >
                        <div>Priority</div>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused
                              ? "white"
                              : "white",
                          }),
                        }}
                        className="fw-bold"
                        name="priority"
                        id="priority"
                        options={priorityData.map((e, i) => ({
                          value: e.value,
                          label: e.label,
                        }))}
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            priority: e.value,
                          });
                        }}
                        placeholder={priorityData[0].label}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Source</div>
                        <button
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageSource(1);
                            setsourceModal(!sourceModal);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused
                              ? "white"
                              : "white",
                          }),
                        }}
                        placeholder={
                          sourceData.length !== 0
                            ? manageSource == 0
                              ? sourceData[0].source_name
                              : sourceData[sourceData.length - 1].source_name
                            : ""
                        }
                        options={sourceData.map((e, i) => ({
                          value: e.source_id,
                          label: e.source_name,
                        }))}
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            source: e.value,
                          });
                        }}
                        className="fw-bold"
                        name="source"
                        id="source"
                      />
                    </Col>

                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>
                          Customer<span className="text-danger"> *</span>
                        </div>
                        <div style={{ color: "red" }}>
                          {tempValid === 2 ? "Select the customer!" : ""}
                          <button
                            className="btn btn-success btn-sm px-1"
                            style={{ padding: "0px" }}
                            onClick={() => {
                              setcustomerModal(!customerModal);
                            }}
                          >
                            <i className="ri-add-line align-bottom"></i>
                          </button>
                        </div>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused
                              ? "white"
                              : "white",
                          }),
                        }}
                        className={
                          tempValid === 2
                            ? "fw-bold border border-danger"
                            : "fw-bold"
                        }
                        name="customer"
                        id="customer"
                        placeholder={"Customer "}
                        onChange={(e) => {
                          settempValid(0);
                          setmanageCustomer(1);
                          settempCustomer(e.index);
                          setLeads({
                            ...leadsData,
                            customer: e.value,
                          });
                        }}
                        options={customerData.map((e, i) => ({
                          value: e.customer_id,
                          label: e.customer_name,
                          index: i,
                        }))}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Phone</div>
                      </Label>
                      <Input
                        readOnly
                        name="phone"
                        id="phone"
                        className="form-control fw-bold"
                        value={
                          manageCustomer !== 0
                            ? customerData[tempCustomer].customer_whatsapp_no
                            : ""
                        }
                        type="text"
                        disabled
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Email</div>
                      </Label>
                      <Input
                        readOnly
                        name="email"
                        id="email"
                        className="form-control fw-bold"
                        placeholder="Email"
                        type="text"
                        value={
                          manageCustomer !== 0
                            ? customerData[tempCustomer].customer_email
                            : ""
                        }
                        disabled
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>City</div>
                      </Label>
                      <Input
                        readOnly
                        name="city"
                        id="city"
                        className=" fw-bold "
                        placeholder="City"
                        type="text"
                        value={
                          manageCustomer !== 0
                            ? customerData[tempCustomer].city_name
                            : ""
                        }
                        disabled
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Refer By</div>
                        <button
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageReferBy(1);
                            setreferBy(!referBy);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused
                              ? "white"
                              : "white",
                          }),
                        }}
                        className="fw-bold"
                        name="ref_by"
                        id="ref_by"
                        placeholder={
                          referByData.length != 0
                            ? manageReferBy == 0
                              ? referByData[0].reference_name
                              : referByData[referByData.length - 1]
                                .reference_name
                            : ""
                        }
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            ref_by: e.value,
                          });
                        }}
                        options={referByData.map((e, i) => ({
                          value: e.reference_id,
                          label: e.reference_name,
                        }))}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Product</div>
                        <button
                          className="btn btn-success btn-sm px-1"
                          style={{ padding: "0px" }}
                          onClick={() => {
                            setmanageProduct(1);
                            setproductModal(!productModal);
                          }}
                        >
                          <i className="ri-add-line align-bottom"></i>
                        </button>
                      </Label>
                      <Select
                        styles={{
                          control: (provided, state) => ({
                            ...provided,
                            backgroundColor: state.isFocused
                              ? "white"
                              : "white",
                          }),
                        }}
                        className="fw-bold"
                        name="product"
                        id="product"
                        placeholder={
                          productData != 0
                            ? manageProduct == 0
                              ? productData[0].product_name
                              : productData[productData.length - 1].product_name
                            : ""
                        }
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            product: e.value,
                          });
                        }}
                        options={productData.map((e, i) => ({
                          value: e.product_id,
                          label: e.product_name,
                        }))}
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Value</div>
                      </Label>
                      <Input
                        required
                        name="value"
                        id="value"
                        className="form-control fw-bold"
                        placeholder="0"
                        onChange={(e) => {
                          setLeads({
                            ...leadsData,
                            value: e.target.value,
                          });
                        }}
                        type="text"
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Tags</div>
                      </Label>
                      {tagData.length !== 0 ? (
                        <Select
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: state.isFocused
                                ? "white"
                                : "white",
                            }),
                          }}
                          options={tagData.map((e, i) => ({
                            label: e.tag_name,
                            value: e.tag_id,
                            index: i,
                          }))}
                          isMulti
                          onChange={(e) => {
                            if (e) {
                              const selectedValues = e.map(
                                (option) => option.value
                              );
                              setLeads({
                                ...leadsData,
                                tags: `[${selectedValues.join(",")}]`,
                              });
                            }
                          }}
                        />
                      ) : (
                        <span className="fw-bold text-danger">
                          No tags added
                        </span>
                      )}
                    </Col>

                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Start Date</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "Y-m-d",
                          defaultDate: new Date(),
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];
                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = selectedDate.getFullYear();
                          const formattedDate = `${year}-${month}-${day}`;
                          setLeads({
                            ...leadsData,
                            minimum_due_date: formattedDate,
                          });
                        }}
                        name="date"
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>End Date</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold"
                        options={{
                          dateFormat: "Y-m-d",
                          defaultDate: new Date(),
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];
                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = selectedDate.getFullYear();
                          const formattedDate = `${year}-${month}-${day}`;
                          setLeads({
                            ...leadsData,
                            maximum_due_date: formattedDate,
                          });
                        }}
                        name="date"
                      />
                    </Col>
                    <Col lg={3}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>Next Follow Up Date</div>
                      </Label>
                      <Flatpickr
                        className="form-control fw-bold  shadow-sm "
                        options={{
                          dateFormat: "Y-m-d h:i K",
                          enableTime: true,
                          time_12hr: true,
                          disableMobile: true,
                          defaultDate: leadsData.next_follow_up,
                        }}
                        onChange={(selectedDates) => {
                          const selectedDate = selectedDates[0];

                          const day = selectedDate
                            .getDate()
                            .toString()
                            .padStart(2, "0");
                          const month = (selectedDate.getMonth() + 1)
                            .toString()
                            .padStart(2, "0");
                          const year = selectedDate.getFullYear();

                          const hours = selectedDate.getHours() % 12 || 12;
                          const minutes = selectedDate
                            .getMinutes()
                            .toString()
                            .padStart(2, "0");
                          const ampm =
                            selectedDate.getHours() >= 12 ? "PM" : "AM";

                          const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes} ${ampm}`;

                          setLeads({
                            ...leadsData,
                            next_follow_up: formattedDateTime,
                          });
                        }}
                        name="date"
                      />
                    </Col>
                    {filedList.filter((temp) => temp.formdynamic_type == 1 || temp.formdynamic_type == 2).map((item, index) => {
                      const inputFieldMap = {
                        1: (
                          <Input
                            placeholder="Enter product name"
                            className="form-control"
                            value={fieldValues[item.formdynamic_id] || ""}
                            onChange={(e) => {
                              if (valid == item.formdynamic_path && item.formdynamic_status == 2 || item.formdynamic_status == 4) {
                                setValid(null);
                              }
                              handleFieldChange(e, item.formdynamic_id)
                            }}
                          />
                        ),
                        2: (
                          <Flatpickr
                            placeholder="Select a date"
                            className="form-control fw-bold"
                            value={fieldValues[item.formdynamic_id] || ""}
                            onChange={(date) => {
                              if (valid == item.formdynamic_path && item.formdynamic_status == 2 || item.formdynamic_status == 4) {
                                setValid(null);
                              }
                              setFieldValues((prevState) => ({
                                ...prevState,
                                [item.formdynamic_id]: date[0],
                              }));
                            }}
                          />
                        ),

                      };

                      const inputField = inputFieldMap[item.formdynamic_type] || null;

                      return (
                        <Col lg={3} key={index} className="mt-3">
                          <div className="">
                            <Label
                              htmlFor={`${item.formdynamic_type}-field-${item.formdynamic_id}`}
                              className="form-label fw-bold d-flex justify-content-between"
                            >
                              <div>
                                {item.formdynamic_name}
                                {(item.formdynamic_status == 2 || item.formdynamic_status == 4) && (
                                  <span style={{ color: "red" }}> *</span>
                                )}
                              </div>
                              <div>
                                {valid === item.formdynamic_path && (
                                  <small style={{ color: "red", fontSize: "0.6rem" }}>
                                    {item.formdynamic_name} required
                                  </small>
                                )}

                              </div>
                              {valid == item.formdynamic_path ? "" : <div>
                                <button
                                  className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                  onClick={() => EditUpdate(item.formdynamic_id)}
                                >
                                  <i className="ri-pencil-fill fs-16" />
                                </button>
                                <button
                                  onClick={() => HandleDelete(item.formdynamic_id)}
                                  className="text-danger d-inline-block remove-item-btn border-0 bg-transparent"
                                >
                                  <i className="ri-delete-bin-5-fill fs-16" />
                                </button>
                              </div>}
                            </Label>
                            {inputField}
                          </div>
                        </Col>
                      );
                    })}


                    <Col lg={12}>
                      <Label
                        htmlFor="categoryname-field"
                        className="form-label fw-bold d-flex justify-content-between  mt-3 "
                      >
                        <div>
                          Description
                        </div>
                        <div className="text-danger">
                          {tempValid === 3
                            ? "Description cannot be empty!"
                            : ""}
                        </div>
                      </Label>
                      <Button
                        type="button"
                        color="none"
                        style={{
                          position: "absolute",
                          top: "70%",
                          right: "10px",
                          transform: "translateY(-50%)",
                          width: "50px",
                          height: "50px",
                          padding: "0",
                          borderRadius: "50%",
                          zIndex: "10",
                          background: "none",
                          border: "none",
                          boxShadow: "none",
                        }}
                        onClick={handleDescriptionMicClick}
                      >
                        <i
                          className={
                            isDescriptionListening
                              ? "ri-stop-circle-fill align-bottom"
                              : "ri-mic-fill align-bottom"
                          }
                        ></i>
                      </Button>
                      <CKEditor
                        rows={1}
                        editor={ClassicEditor}
                        data={leadsData.description}
                        onChange={(e, editor) => {
                          settempValid(0);
                          setLeads({
                            ...leadsData,
                            description: editor.getData(),
                          });
                        }}
                      />
                    </Col>
                    <div className="mt-2">
                      {filedList
                        .filter((item) => item.formdynamic_type == 3)
                        .map((item, index) => (
                          <Col lg={12} key={index} className="">
                            <div className="">
                              <Label
                                htmlFor={`3-field-${item.formdynamic_id}`}
                                className="form-label fw-bold d-flex justify-content-between"
                              >
                                <div>
                                  {item.formdynamic_name}
                                  {(item.formdynamic_status == 2 || item.formdynamic_status == 4) && (
                                    <span style={{ color: "red" }}> *</span>
                                  )}
                                </div>
                                <div>
                                  {valid == item.formdynamic_path && (
                                    <span className="text-danger">
                                      {item.formdynamic_name} is required
                                    </span>
                                  )}
                                </div>
                                {valid !== item.formdynamic_path ? (
                                  <div>
                                    <button
                                      className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                      onClick={() => EditUpdate(item.formdynamic_id)}
                                    >
                                      <i className="ri-pencil-fill fs-16" />
                                    </button>
                                    <button
                                      onClick={() => HandleDelete(item.formdynamic_id)}
                                      className="text-danger d-inline-block remove-item-btn border-0 bg-transparent"
                                    >
                                      <i className="ri-delete-bin-5-fill fs-16" />
                                    </button>
                                  </div>
                                ) : null}
                              </Label>
                              <CKEditor
                                editor={ClassicEditor}
                                data={fieldValues[item.formdynamic_id] || ""}
                                onChange={(event, editor) => {
                                  setFieldValues((prevState) => ({
                                    ...prevState,
                                    [item.formdynamic_id]: editor.getData(),
                                  }));
                                }}
                              />
                            </div>
                          </Col>
                        ))}
                    </div >
                    <Col lg={12}>
                      <div className="mt-3">
                        <h5 className="fs-15 mb-3 text-center text-primary">Upload Images</h5>
                        <div className="text-center">
                          <div className="position-relative d-inline-block">
                            <div className="d-flex flex-wrap justify-content-center align-items-center gap-3">
                              {leadsData.image.length > 0 ? (
                                leadsData.image.map((image, index) => (
                                  <div
                                    key={index}
                                    className="position-relative border rounded shadow-sm"
                                    style={{
                                      width: "150px",
                                      height: "100px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <button
                                      type="button"
                                      className="position-absolute top-0 end-0 btn-close bg-light p-1"
                                      style={{ zIndex: 2 }}
                                      onClick={() => handleRemoveImage(index)}
                                    ></button>
                                    <img
                                      src={URL.createObjectURL(image)}
                                      alt={`product-img-${index}`}
                                      className="img-fluid rounded"
                                      style={{ width: "100%", height: "100%", objectFit: "cover" }}
                                    />
                                  </div>
                                ))
                              ) : (
                                ""
                              )}
                              <div
                                className="mt-2 position-relative"
                                style={{ display: "inline-block" }}
                              >
                                <label
                                  htmlFor="taskImages"
                                  className="cursor-pointer"
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Select Images"
                                >
                                  <div className="avatar-xs">
                                    <div
                                      className="avatar-title bg-light border rounded-circle shadow-sm text-muted hover-bg-primary"
                                      style={{ width: "50px", height: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}
                                    >
                                      <i className="ri-image-fill fs-18"></i>
                                    </div>
                                  </div>
                                </label>
                                <input
                                  className="form-control d-none"
                                  id="taskImages"
                                  type="file"
                                  accept="image/png, image/gif, image/jpeg"
                                  multiple
                                  onChange={handleImageChange}
                                />
                              </div>

                            </div>

                          </div>
                        </div>
                      </div>
                    </Col>

                    <Row className="gy-4">
                      <Col lg={4}>
                        <Card className="shadow-sm border-0" style={{ height: "200px", overflow: "hidden" }}>
                          <div className="card-body text-center p-2 d-flex flex-column">
                            <h5 className="fs-14 mb-2 text-primary">Audio Recorder</h5>
                            <div
                              className={`position-relative w-100 d-flex flex-column ${isRecording == true ? "justify-content-start" : "align-items-center"
                                }`}
                              style={{ maxWidth: "100%", overflow: "hidden" }}
                            >
                              <div
                                className="avatar-md d-inline-block align-top mb-2"
                                style={{ maxWidth: "100%" }}
                              >
                                <div
                                  className="avatar-md d-inline-block align-top mb-2"
                                  style={{ maxWidth: "100%" }}
                                  onClick={handleRecorderClick}
                                >
                                  <AudioRecorder
                                    
                                    onRecordingComplete={addAudioElement}
                                    recorderControls={recorderControls}
                                    style={{ maxWidth: "100%" , color:"#009CA4" }}
                                  />
                                </div>
                              </div>
                              {!isRecording && !audioBlob && (
                                <p className="text-muted">Click the recorder to start recording</p>
                              )}
                              {isRecording && (
                                <p className="text-danger">Recording in progress...</p>
                              )}
                              {audioBlob && (
                                <audio controls className="w-100" style={{ height: "30px" }}>
                                  <source src={URL.createObjectURL(audioBlob)} type="audio/wav" />
                                  Your browser does not support the audio element.
                                </audio>
                              )}
                            </div>
                          </div>
                        </Card>
                      </Col>





                      <Col lg={4}>
                        <Card className="shadow-sm border-0" style={{ height: "200px" }}>
                          <div className="card-body text-center p-2">
                            <h5 className="fs-14 mb-2 text-primary">Video Upload</h5>
                            <div className="position-relative">
                              <label
                                htmlFor="employeeVideo"
                                className="d-inline-block mb-2"
                                title="Select Video"
                              >
                                <div className="avatar-md bg-light rounded-circle d-flex justify-content-center align-items-center">
                                  <i
                                    className="ri-video-fill"
                                    style={{
                                      color: "#009CA4",
                                      fontSize: "30px",
                                    }}
                                  ></i>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeVideo"
                                type="file"
                                accept="video/mp4, video/avi, video/mkv"
                                onChange={(e) => {
                                  setLeads({
                                    ...leadsData,
                                    video: e.target.files[0],
                                  });
                                  SetEmployeeVideo(e.target.files[0]);
                                }}
                              />
                              {EmployeeVideo && (
                                <div className="mt-2">
                                  <i
                                    className="ri-video-fill"
                                    style={{
                                      fontSize: "30px",
                                      color: "#007bff",
                                    }}
                                  ></i>
                                  <p
                                    className="text-primary"
                                    style={{ fontSize: "12px", marginTop: "5px" }}
                                  >
                                    {EmployeeVideo.name
                                      ? `${EmployeeVideo.name.substring(0, 5)}.${EmployeeVideo.name
                                        .split(".")
                                        .pop()}`
                                      : ""}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </Card>
                      </Col>


                      <Col lg={4}>
                        <Card className="shadow-sm border-0" style={{ height: "200px" }}>
                          <div className="card-body text-center p-2">
                            <h5 className="fs-14 mb-2 text-primary">Document Upload</h5>
                            <div className="position-relative">
                              <label
                                htmlFor="employeeDocument"
                                className="d-inline-block mb-2"
                                title="Select Document"
                              >
                                <div className="avatar-md bg-light rounded-circle d-flex justify-content-center align-items-center">
                                  <i
                                    className="ri-file-upload-fill"
                                    style={{
                                      color: "#009CA4",
                                      fontSize: "30px",
                                    }}
                                  ></i>
                                </div>
                              </label>
                              <input
                                className="form-control d-none"
                                id="employeeDocument"
                                type="file"
                                accept=".pdf,.doc,.docx,.txt,.xlsx"
                                onChange={(e) => {
                                  setLeads({
                                    ...leadsData,
                                    document: e.target.files[0],
                                  });
                                  SetEmployeeDoc(e.target.files[0]);
                                }}
                              />
                              {EmployeeDoc && (
                                <div className="mt-2">
                                  {EmployeeDoc.type.startsWith("image/") ? (
                                    <img
                                      src={URL.createObjectURL(EmployeeDoc)}
                                      alt="Selected file preview"
                                      height="30px"
                                      width="30px"
                                      className="rounded"
                                    />
                                  ) : (
                                    <div>
                                      {EmployeeDoc.type.includes("pdf") && (
                                        <i
                                          className="ri-file-pdf-fill"
                                          style={{
                                            fontSize: "30px",
                                            color: "#FF0000",
                                          }}
                                        ></i>
                                      )}
                                      {EmployeeDoc.type.includes("word") && (
                                        <i
                                          className="ri-file-word-fill"
                                          style={{
                                            fontSize: "30px",
                                            color: "#1E90FF",
                                          }}
                                        ></i>
                                      )}
                                      {EmployeeDoc.type.includes("excel") && (
                                        <i
                                          className="ri-file-excel-fill"
                                          style={{
                                            fontSize: "30px",
                                            color: "#28A745",
                                          }}
                                        ></i>
                                      )}
                                      {!EmployeeDoc.type.includes("pdf") &&
                                        !EmployeeDoc.type.includes("word") &&
                                        !EmployeeDoc.type.includes("excel") && (
                                          <i
                                            className="ri-file-fill"
                                            style={{
                                              fontSize: "30px",
                                              color: "#6c757d",
                                            }}
                                          ></i>
                                        )}
                                    </div>
                                  )}
                                  <p
                                    className="text-primary"
                                    style={{ fontSize: "12px", marginTop: "5px" }}
                                  >
                                    {EmployeeDoc.name
                                      ? `${EmployeeDoc.name.substring(0, 5)}.${EmployeeDoc.name
                                        .split(".")
                                        .pop()}`
                                      : ""}
                                  </p>
                                </div>
                              )}
                            </div>
                          </div>
                        </Card>
                      </Col>
                    </Row>

                  </Row>
                  {/* <Row>
                  
                  </Row> */}
                  <Row>
                    <div className="mt-3">
                      <Input
                        type="checkbox"
                        onClick={(e) => SetsmsStatus(true)}
                      ></Input>{" "}
                      Send Notification To Whatsapp
                    </div>
                  </Row>
                </div>
              </Card>
            </Form>
          </ModalBody>
          <div className="modal-footer">
            <button
              type="button"
              className="btn fw-bold btn-success"
              onClick={() => setformModalStates(!formmodalStates)}
            >
              <i className="ri-add-line align-bottom me-1"></i> Add Field
            </button>
            <button
              ref={submitButtonRef}
              name="close"
              id="close"
              type="submit"
              className="btn btn-primary"
              onClick={() => SubmitData()}
              disabled={loading}
            >
              <i className="ri-save-3-line align-bottom me-1"></i>
              {loading ? "Saving..." : "Save"}
            </button>
            <button
              name="close"
              id="close"
              type="button"
              className="btn btn-danger"
              onClick={() => Close()}
            >
              <i className="ri-close-line me-1 align-middle" />
              Close
            </button>
          </div>
        </span>
      </Modal>

      {statusModal === true ? (
        <PipeLineCreate
          modalStates={statusModal}
          setModalStates={() => {
            setstatusModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {productModal === true ? (
        <CreateProduct
          modalStates={productModal}
          setModalStates={() => {
            setproductModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {referBy === true ? (
        <RefrencesAdd
          modalStates={referBy}
          setModalStates={() => {
            setreferBy(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {customerModal === true ? (
        <CustomerAdd
          modalStates={customerModal}
          setModalStates={() => {
            setcustomerModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {sourceModal === true ? (
        <SourceAdd
          modalStates={sourceModal}
          setModalStates={() => {
            setsourceModal(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {formmodalStates === true ? (
        <FormFiledAdd
          modalStates={formmodalStates}
          setModalStates={() => {
            setformModalStates(false);
          }}
          status={3}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
      {formmodalEdit === true ? (
        <FormFiledEdit
          modalStates={formmodalEdit}
          formmodalEdit={() => {
            setformModalEdit(false);
          }}
          status={3}
          checkchang={handleCallback}
          edit_data={FindData}
        />
      ) : (
        ""
      )}
      <Modal
        isOpen={confirmDeleteModal}
        toggle={() => setConfirmDeleteModal(!confirmDeleteModal)}
        centered
      >
        <ModalBody className="text-center mt-4">
          <h4 className="fw-bold">Are you sure?</h4>
          <h5>Are you sure you want to delete this Tax?</h5>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button color="danger" onClick={confirmDelete}>
            Yes, Delete It!
          </Button>
          <Button
            color="secondary"
            onClick={() => setConfirmDeleteModal(!confirmDeleteModal)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LeadsAdd;
