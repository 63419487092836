import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  Form,
  Button,
  Row,
  Col,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import AuthUser from "../../helpers/Authuser";
import CustomInput from "../Unit/Input";
import CustomerGroupAdd from "../CustomerGroup/CustomerGroupAdd";
import { toast } from "react-toastify";
import CityAdd from "../City/CityAdd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Flatpickr from "react-flatpickr";

const CustomerUpdate = (props) => {
  const { http } = AuthUser();
  const [CustomerGroup, setCustomerGroup] = useState([]);
  const [CustomerCity, setCustomerCity] = useState([]);
  const [modalStatess, setModalStatess] = useState(false);
  const [modalStatess1, setModalStatess1] = useState(false);
  const [manageGroup, setManageGroup] = useState(0);
  const [manageCity, setManageCity] = useState(0);
  const [counts, Setcounts] = useState(1);
  const [filedList, setFiledList] = useState([]);
  const [fieldValues, setFieldValues] = useState({});
  const [filedListName, setFiledListName] = useState([]);
  const [filedListNamevalid, setFiledListNamevalid] = useState([]);
  const [valid, setValid] = useState('');
  useEffect(() => {
    http
      .get("/formdynamic/list")
      .then((res) => {
        const filterData = res.data.filter(
          (temp) => temp.formdynamic_category == 2
        );
        setFiledListName(filterData);
        const filterDatavalid = res.data.filter(
          (temp) => temp.formdynamic_category == 2 && temp.formdynamic_status == 2 || temp.formdynamic_status == 4
        );
        setFiledListNamevalid(filterDatavalid);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);
  useEffect(() => {
    if (props.edit_data.customer_fields && filedListName.length > 0) {
      const parsedFields = JSON.parse(props.edit_data.customer_fields);

      setFieldValues(parsedFields);
      setFiledList(
        Object.keys(parsedFields).map((key, index) => {
          const matchingField = filedListName.find(
            (field) => field.formdynamic_path === key
          );
          return {
            formdynamic_id: key,
            formdynamic_name: matchingField?.formdynamic_name,
            formdynamic_path: matchingField?.formdynamic_path,
            formdynamic_type: matchingField?.formdynamic_type || 1,
            formdynamic_status: matchingField?.formdynamic_status || 1,
          };
        })
      );
    }
  }, [props.edit_data, filedListName]);

  const handleFieldChange = (e, fieldId) => {
    const { value } = e.target;
    setFieldValues((prevState) => ({
      ...prevState,
      [fieldId]: value,
    }));
  }
  useEffect(() => {
    http
      .get("/all_customer_groups")
      .then(function (response) {
        setCustomerGroup(response.data);

        setCustomersData(() => ({
          ...CustomersData,
          customer_group:
            manageGroup != 0
              ? response.data[response.data.length - 1].customer_group_id
              : CustomersData.customer_group_id,
        }));
      })
      .catch(function (error) {
        console.log(error);
      });

    http
      .get("/city/list")
      .then(function (response) {
        setCustomerCity(response.data);
        setCustomersData(() => ({
          ...CustomersData,
          customer_city:
            manageCity != 0
              ? response.data[response.data.length - 1].city_id
              : CustomersData.city_id,
        }));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [counts]);
  const [CustomersData, setCustomersData] = useState(props.edit_data);
  const getCustomerData = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    if (name === "name") {
      updatedValue = value;
    }

    else if (name === "customer_whatsapp_no" || name === "customer_alternative_no") {
      updatedValue = value.replace(/[^0-9]/g, "");
      if (updatedValue.length > 10) {
        updatedValue = updatedValue.slice(0, 10);
      }
    }
    setCustomersData({ ...CustomersData, [name]: updatedValue });
    if (CustomersData.customer_name !== "") {
      setCheckStatus({});
      setMsg("");
    }
  };


  const getSelectedGroupValue = (e) => {
    setCustomersData({ ...CustomersData, customer_group: e.value });
  };
  const getSelectedCityValue = (e) => {
    setCustomersData({ ...CustomersData, customer_city: e.value });
  };
  const [checkNameStatus, setCheckStatus] = useState({});
  const [msg, setMsg] = useState("");
  const OnSubmited = async () => {
    if (!CustomersData.customer_name.trim()) {
      setCheckStatus({
        borderColor: "red",
        borderStyle: "groove",
      });
      setMsg("Customer name cannot be empty!");
      return;
    }
    else {
      let isValidSet = false;
      Object.entries(fieldValues).forEach(([key, value], index) => {
        for (const temp of filedListNamevalid) {
          if (temp.formdynamic_path == key) {
            if (value == null || value === "") {

              if (!isValidSet) {
                setValid(key);
                isValidSet = true;
              }
              return;
            }
          }
        }
      });

      if (!isValidSet) {
        const payload = {
          ...CustomersData,
          customer_fields: JSON.stringify(fieldValues || {}),
        };

        Object.keys(payload).forEach(
          (key) => {
            if (payload[key] === undefined || payload[key] === null) {
              delete payload[key];
            }
          }
        );

        try {
          const response = await http.put("/customer/update", payload);

          if (response.status >= 200 && response.status < 300) {
            props.checkchang(response.data.message, response.data.status);
          } else {
            setMsg("Failed to update customer data.");
          }
        } catch (error) {
          setMsg("An error occurred while updating the customer.");
          console.error("Error:", error);
        }

      }
    }


  };




  const [modal, setModal] = useState(false);

  const Close = () => {
    setModal(false);
    props.setModalStates();
  };

  useEffect(() => {
    setModal(false);
    toggle();
  }, [props.modalStates]);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      props.setModalStates();
    } else {
      setModal(true);
    }
  }, [modal]);

  const handleCallback = (data, status) => {
    Setcounts(counts + 1);
    setManageGroup(1);
    setManageCity(1);
    if (status == 2) {
      toast.success(data);
    } else {
      toast.warn(data);
    }
    setModalStatess(false);
    setModalStatess1(false);
  };



  const submitButtonRef = useRef();
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "Escape") {
        event.preventDefault();
        props.setModalStates(false);
      }
      if (
        (event.altKey && event.key === "s") ||
        (event.altKey && event.key === "S")
      ) {
        event.preventDefault();
        submitButtonRef.current.click();
      }
      if (
        (event.altKey && event.key === "c") ||
        (event.altKey && event.key === "C")
      ) {
        event.preventDefault();
        props.setModalStates(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      <Modal id="showModal" size="xl" isOpen={modal} toggle={toggle} centered>
        <ModalHeader className="bg-light p-3" toggle={toggle}>
          Update Customer
        </ModalHeader>
        <div className="tablelist-form">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              OnSubmited();
              return false;
            }}
            action="#"
          >
            <ModalBody>
              <Card className="border card-border-success  p-3 shadow-lg">
                <div className="mb-3 row">
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      <div>
                        Full Name<span style={{ color: "red" }}> *</span>
                      </div>
                      <div style={{ color: "red" }}>{msg}</div>
                    </Label>
                    <CustomInput
                      checkNameStatus={checkNameStatus}
                      onChange={getCustomerData}
                      value={CustomersData.customer_name || ""}
                      name="customer_name"
                      id="customer_name"
                      className="form-control fw-bold"
                      placeholder="Customer Name"
                      type="text"
                    />
                  </div>

                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold"
                    >
                      <div>WhatsApp Number</div>
                    </Label>
                    <Input
                      onChange={getCustomerData}
                      value={CustomersData.customer_whatsapp_no}
                      name="customer_whatsapp_no"
                      id="customer_whatsapp_no"
                      className="form-control fw-bold"
                      placeholder="Mobile No"
                      type="text"
                    />
                  </div>
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold"
                    >
                      Alternative Mobile Number
                    </Label>
                    <Input
                      onChange={getCustomerData}
                      value={CustomersData.customer_alternative_no}
                      name="customer_alternative_no"
                      id="customer_alternative_no"
                      className="form-control fw-bold"
                      placeholder="customer alternativ Mobile No"
                      type="text"
                    />
                  </div>
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold"
                    >
                      Email.
                    </Label>
                    <Input
                      onChange={getCustomerData}
                      value={CustomersData.customer_email}
                      name="customer_email"
                      id="customer_email"
                      className="form-control fw-bold"
                      placeholder="Email"
                      type="text"
                    />
                  </div>
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      City
                      <a
                        className="btn btn-success btn-sm px-1"
                        style={{ padding: "0px" }}
                        onClick={() => setModalStatess1(!false)}
                      >
                        <i className="ri-add-line align-bottom"></i>
                      </a>
                    </Label>
                    <Select
                      placeholder={
                        manageCity == 0
                          ? CustomersData.city_name
                          : CustomerGroup[CustomerGroup.length - 1].city_name
                      }
                      onChange={getSelectedCityValue}
                      options={CustomerCity.map((item) => ({
                        value: item.city_id,
                        label: item.city_name,
                      }))}
                      name="customer_city"
                      id="customer_city"
                      className="fw-bold"
                    />
                  </div>
                  <div className="col-4">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-2 fw-bold d-flex justify-content-between"
                    >
                      Group Type
                      <a
                        className="btn btn-success btn-sm px-1"
                        style={{ padding: "0px" }}
                        onClick={() => setModalStatess(!false)}
                      >
                        <i className="ri-add-line align-bottom"></i>
                      </a>
                    </Label>
                    <Select
                      placeholder={
                        manageGroup == 0
                          ? CustomersData.customer_group_name
                          : CustomerGroup[CustomerGroup.length - 1]
                            .customer_group_name
                      }
                      onChange={getSelectedGroupValue}
                      options={CustomerGroup.map((item) => ({
                        value: item.customer_group_id,
                        label: item.customer_group_name,
                      }))}
                      name="customer_group_type"
                      id="customer_group_type"
                      className="fw-bold"
                    />
                  </div>

                  <Row>
                    {filedList.filter((temp) => temp.formdynamic_type != 3).map((item) => (

                      <Col lg={4} key={item.formdynamic_id}>
                        <Label className="form-label fw-bold mt-2 d-flex justify-content-between ">
                          <div>
                      
                            {item.formdynamic_name}
                            {(item.formdynamic_status == 2 || item.formdynamic_status == 4) && (
                              <span style={{ color: "red" }}> *</span>
                            )}
                          </div>

                          <div>
                            {valid === item.formdynamic_id && (
                              <span className="text-danger">
                                {item.formdynamic_name} is required
                              </span>
                            )}
                          </div>
                        </Label>

                        {item.formdynamic_type == 1 && (
                          <Input
                            type="text"
                            value={fieldValues[item.formdynamic_id] || ""}
                            onChange={(e) => handleFieldChange(e, item.formdynamic_id)}
                          />
                        )}
                        {item.formdynamic_type == 2 && (
                          <Flatpickr
                            className="form-control fw-bold "
                            value={fieldValues[item.formdynamic_id] || ""}
                            onChange={(date) =>
                              setFieldValues((prevState) => ({
                                ...prevState,
                                [item.formdynamic_id]: date[0],
                              }))
                            }
                          />
                        )}

                      </Col>
                    ))}
                  </Row>
                  <div className="col-12">
                    <Label
                      htmlFor="customername-field"
                      className="form-label mt-3 fw-bold"
                    >
                      Address
                    </Label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={CustomersData.customer_address || ""}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setCustomersData({ ...CustomersData, customer_address: data });
                      }}
                      config={{
                        placeholder: "Enter address",
                        toolbar: ["bold", "italic", "bulletedList", "numberedList", "undo", "redo"],
                      }}
                      className="form-control fw-bold"
                    />
                  </div>
                </div>
                <Row>
                  {filedList.filter((temp) => temp.formdynamic_type == 3).map((item) => (
                    <Col lg={12} key={item.formdynamic_id}>
                      <Label className="form-label fw-bold mt-2 d-flex justify-content-between ">
                        <div>

                          {item.formdynamic_name}
                          {(item.formdynamic_status == 2 || item.formdynamic_status == 4) && (
                            <span style={{ color: "red" }}> *</span>
                          )}
                        </div>
                        <div>
                          {valid === item.formdynamic_id && (
                            <span className="text-danger">
                              {item.formdynamic_name} is required
                            </span>
                          )}
                        </div>
                      </Label>

                      {item.formdynamic_type == 3 && (
                        <CKEditor
                          editor={ClassicEditor}
                          data={fieldValues[item.formdynamic_id] || ""}
                          onChange={(event, editor) =>
                            setFieldValues((prevState) => ({
                              ...prevState,
                              [item.formdynamic_id]: editor.getData(),
                            }))
                          }
                        />
                      )}
                    </Col>
                  ))}
                </Row>
              </Card>
            </ModalBody>
            <div className="modal-footer">
              <div className="hstack gap-2 justify-content-end">

                <button
                  type="sumbit"
                  name="sumbit"
                  id="submit"
                  className="btn btn-primary"
                  ref={submitButtonRef}
                >
                  <i className="ri-save-3-line align-bottom me-1"></i>
                  Update
                </button>
                <button
                  name="close"
                  id="close"
                  type="button"
                  className="btn btn-danger"
                  onClick={() => Close()}
                >
                  <i className="ri-close-line me-1 align-middle" />
                  Close
                </button>
             
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      {modalStatess === true ? (
        <CustomerGroupAdd
          modalStates={modalStatess}
          setModalStates={() => {
            setModalStatess(false);
          }}
          checkchang={handleCallback}
        />
      ) : modalStatess1 === true ? (
        <CityAdd
          modalStates={modalStatess}
          setModalStates={() => {
            setModalStatess(false);
          }}
          checkchang={handleCallback}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default CustomerUpdate;
