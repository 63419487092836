import React, { useEffect, useState, useCallback, useRef } from "react";
import {
    Card,
    Modal,
    ModalHeader,
    ModalBody,
    Label,
    Row,
    Col,
    Button,
    Form,
} from "reactstrap";
import "react-toastify/dist/ReactToastify.css";
import AuthUser from "../../helpers/Authuser";
import CustomInput from "../Unit/Input";
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';
import { toast, ToastContainer } from "react-toastify";
import DeleteModal from "../../Components/Common/DeleteModal";

const PipeLineUpdate = (props) => {
    // console.log("props", props.edit_data);
    const [pipelineData, SetPipelineData] = useState([]);
    const [FindData, SetFind] = useState([]);
    const { https, user } = AuthUser();
    const [modalstage, setModalstage] = useState(false);
    const [modal, setModal] = useState(false);
    const [manage, setmanage] = useState(0);
    const [valid, setvalid] = useState(0);

    const [pipelinestage, Setpipelinestage] = useState([]);
    const [pipeline, Setpipeline] = useState(props.edit_data);
    const [stageName, setStageName] = useState({
        pipeline_stages: "",
        pipeline_master_id: props.edit_data.pipeline_id
    });

    useEffect(() => {
        Setpipeline((prev) => ({
            ...prev,
            children: pipelinestage,
        }));
    }, [pipelinestage]);
    const addStage = () => {

        https
            .post("/pipeline/stage/store", stageName)
            .then((response) => {
                // console.log(response.data);
                setmanage(0);
                setModalstage(false);
                toast.success("Stage Created Successfully");
                fetchData();
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });

    };

    const updateStage = () => {
        // console.log("Bye");
        https
            .put("/pipelin/stage/update", FindData)
            .then((response) => {
                // console.log(response.data);
                setmanage(0);
                setModalstage(false);
                toast.success("Stage update Successfully");
                fetchData();
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });

    };

    const SubmitData = (e) => {
        e.preventDefault();

        https
            .put("/pipeline/update", pipeline)
            .then((response) => {
                props.checkchang(response.data.message, response.data.status);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const Close = () => {
        setModal(false);
        props.setModalStates();
    };

    useEffect(() => {
        setModal(false);
        toggle();
    }, [props.modalStates]);

    const toggle = useCallback(() => {
        setModal(!modal);
    }, [modal]);

    const togglemodalStage = useCallback(() => {
        setmanage(0)
        setModalstage(!modalstage);
    }, [modalstage]);
    const [loading, setLoading] = useState(false);

    const submitButtonRef = useRef();
    useEffect(() => {
        fetchData();
        const handleKeyDown = (event) => {
            if (event.altKey && event.key === "Escape") {
                event.preventDefault();
                props.setModalStates(false);
            }
            if (event.altKey && (event.key === "s" || event.key === "S")) {
                event.preventDefault();
                submitButtonRef.current.click();
            }
            if (event.altKey && (event.key === "c" || event.key === "C")) {
                event.preventDefault();
                props.setModalStates(false);
            }
        };

        window.addEventListener("keydown", handleKeyDown);
        return () => {
            window.removeEventListener("keydown", handleKeyDown);
        };
    }, []);
    const EditUpdate = (e,index) => {
        e.preventDefault();
        let FindArray = pipelineData.filter((_, i) => i == index);
        SetFind(FindArray[0]);
        setModalstage(!modalstage);
    };
    const [deleteModal, setDeleteModal] = useState(false);
    const [ID, SetID] = useState();
    const onClickDelete = (data) => {
        SetID(data);
        setDeleteModal(true);
    };

    const fetchData = () => {
        https
            .get(`/pipeline/stage/list`)
            .then(function (response) {
                const filterData = response.data.filter((temp) => temp.pipeline_master_id === props.edit_data.pipeline_id)
                SetPipelineData(filterData);
            })
            .catch(function (error) {
                console.log(error);
            });
    };
    const handleDeleteOrder = (data) => {
        if (data._reactName == "onClick") {
            https
                .delete(`/pipeline/stage/delete/${ID}`)
                .then(function (response) {
                    if (response.data.status == 0) {
                        fetchData();
                        toast.success(response.data.message);
                    } else {
                        toast.error(response.data.message);
                    }

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        SetPipelineData([]);
        setDeleteModal(false);
    };
    return (
        <div>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <ToastContainer limit={1} />
            <Modal id="showModal" isOpen={modal} toggle={toggle} className="modal-lg" centered>
                <ModalHeader className="bg-light p-3" toggle={toggle}>
                    Create Pipe Line
                </ModalHeader>
                <span className="tablelist-form">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();

                            // return false;
                        }}
                        action="#"
                    >
                        <ModalBody>
                            <Card className="border card-border-success p-3 shadow-lg">
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label
                                                htmlFor="cityname-field"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                    Pipe Line Name<span style={{ color: "red" }}> *</span>
                                                </div>

                                            </Label>
                                            <CustomInput

                                                name="pipeline_name"
                                                id="pipeline-field"
                                                className="form-control fw-bold"
                                                placeholder="Pipe Line Name"
                                                type="text"
                                                value={pipeline.pipeline_name}
                                                onChange={(e) => {
                                                    Setpipeline({
                                                        ...pipeline, pipeline_name: e.target.value
                                                    })
                                                    setvalid(0)
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={12} className="mt-2">
                                        <div
                                            htmlFor="cityname-field"
                                            className="form-label fw-bold d-flex justify-content-between"
                                        >
                                            <div>
                                                Opne Stages
                                            </div>
                                            <Button className="btn  btn-success" onClick={() => {
                                                setModalstage(!modalstage)
                                                setmanage(1)
                                            }}>+</Button>
                                        </div>
                                        <table
                                            role="table"
                                            className="align-middle table-nowrap table table-hover text-center"
                                        >
                                            <thead className="table-light text-muted text-uppercase">
                                                <tr>
                                                    <th>#</th>

                                                    <th
                                                        title="Toggle SortBy"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        Stage Name
                                                    </th>
                                                    <th>Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {pipelineData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index}</td>
                                                        <td>{item.pipeline_stages}</td>

                                                        <td className="text-center">
                                                            <ul className="list-inline">
                                                                <li className="list-inline-item edit">
                                                                    <button
                                                                        className="text-primary d-inline-block edit-item-btn border-0 bg-transparent"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            EditUpdate(e,index);
                                                                        }}
                                                                    >
                                                                        <i className="ri-pencil-fill fs-16" />
                                                                    </button>
                                                                </li>
                                                                <li className="list-inline-item">
                                                                    {item.pipeline_child_id != 1 ? (
                                                                        <button
                                                                            onClick={(e) => {
                                                                                onClickDelete(item.pipeline_child_id)
                                                                                e.stopPropagation();
                                                                            }
                                                                            }
                                                                            className="text-danger d-inline-block remove-item-btn  border-0 bg-transparent"
                                                                        >
                                                                            <i className="ri-delete-bin-5-fill fs-16" />
                                                                        </button>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </li>
                                                            </ul>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </Col>
                                </Row>
                            </Card>
                        </ModalBody>
                        <div className="modal-footer">
                            <button
                                ref={submitButtonRef}
                                name="close"
                                id="close"
                                type="submit"
                                className="btn btn-primary"
                                onClick={(e) => SubmitData(e)}
                            >
                                <i className="ri-save-3-line align-bottom me-1"></i>
                                Save
                            </button>
                            <button
                                name="close"
                                id="close"
                                type="button"
                                className="btn btn-danger"
                                onClick={Close}
                            >
                                <i className="ri-close-line me-1 align-middle" />
                                Close
                            </button>
                        </div>
                    </Form>
                </span>
            </Modal>
            <Modal id="showModal" isOpen={modalstage} toggle={togglemodalStage} centered>
                <ModalHeader className="bg-light p-3" toggle={togglemodalStage}>
                    {manage === 1 ? "Create" : "Update"} Stage
                </ModalHeader>
                <span className="tablelist-form">
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            manage === 1 ? addStage() : updateStage();
                        }}

                        action="#"
                    >
                        <ModalBody>
                            <Card className="border card-border-success p-3 shadow-lg">
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label
                                                htmlFor="stage-name-field"
                                                className="form-label fw-bold d-flex justify-content-between"
                                            >
                                                <div>
                                                    Stage Name<span style={{ color: "red" }}> *</span>
                                                </div>
                                                <div>{valid == 1 && <span className="text-danger">Stage name is required !</span>}</div>
                                            </Label>
                                            {manage == 1 ? <CustomInput
                                                name="stage_name"
                                                id="stage-name-field"
                                                className="form-control fw-bold"
                                                placeholder="Enter Stage Name"
                                                type="text"
                                                onChange={(e) => setStageName({
                                                    ...stageName, pipeline_stages: e.target.value
                                                })}
                                            /> : <CustomInput
                                                name="stage_name"
                                                id="stage-name-field"
                                                className="form-control fw-bold"
                                                placeholder="Enter Stage Name"
                                                type="text"
                                                value={FindData.pipeline_stages}
                                                onChange={(e) => SetFind({
                                                    ...FindData, pipeline_stages: e.target.value
                                                })}
                                            />}

                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </ModalBody>
                        <div className="modal-footer">
                            <Button
                                type="submit"
                                className="btn btn-primary"
                            >
                                Save
                            </Button>
                            <Button
                                type="button"
                                className="btn btn-danger"
                                onClick={togglemodalStage}
                            >
                                Close
                            </Button>
                        </div>
                    </Form>
                </span>
            </Modal>
        </div >
    );
};

export default PipeLineUpdate;
